<template>
  <hr class="horizontal dark mt-0 mb-4" />
  <div class="card-body p-3 pt-0">
    <div class="multisteps-form__content">
      <div class="row mt-3">
        <div class="col-12 col-sm-6">
          <label
            >Sale Representative <span class="required">*</span>
            <span :class="{ 'missing-field': errors['company'], hidden: true }"
              >- Missing Field</span
            >
          </label>
          <div class="form-group">
            <v-select
              :disabled="!editInit"
              @change="$emit('update-info', clientData, 'info')"
              :options="salesReps"
              label="firstName"
              :clearable="false"
              v-model="clientData.salesRep"
              :reduce="
                (s) =>
                  Object.assign({
                    id: s.id,
                    firstName: s.firstName,
                    lastName: s.lastName,
                    email: s.email,
                    repID: s.repID,
                  })
              "
              :loading="!salesReps.length"
            >
              <template v-slot:option="option">
                {{ `${option.firstName} ${option.lastName}` }}
              </template>
            </v-select>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-sm-6">
          <label>First Name </label>
          <div class="form-group">
            <div class="input-group">
              <input
                @change="$emit('update-info', clientData, 'info')"
                id="firstName"
                type="text"
                class="form-control multisteps-form__input"
                name="text"
                :disabled="!editInit"
                v-model="clientData.firstName"
                placeholder="First Name"
                required
              />
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 mt-3 mt-sm-0">
          <label>Last Name </label>
          <div class="form-group">
            <div class="input-group">
              <input
                @change="$emit('update-info', clientData, 'info')"
                id="lastName"
                type="text"
                class="form-control multisteps-form__input"
                name="text"
                :disabled="!editInit"
                v-model="clientData.lastName"
                placeholder="Last Name"
                required
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-sm-6">
          <label>Company </label>
          <div class="form-group">
            <div class="input-group">
              <input
                @change="$emit('update-info', clientData, 'info')"
                id="company"
                type="text"
                class="form-control multisteps-form__input"
                name="text"
                :disabled="!editInit"
                v-model="clientData.company"
                placeholder="Company"
                required
              />
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 mt-3 mt-sm-0">
          <label>Email Address </label>
          <div class="form-group">
            <div class="input-group">
              <input
                @change="$emit('update-info', clientData, 'info')"
                id="email"
                type="text"
                class="form-control multisteps-form__input"
                name="text"
                :disabled="!editInit"
                v-model="clientData.email"
                placeholder="eg: client@email.com"
                required
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-sm-6">
          <label>Phone # </label>
          <div class="form-group">
            <div class="input-group">
              <input
                @change="$emit('update-info', clientData, 'info')"
                id="phone"
                type="tel"
                class="form-control multisteps-form__input"
                name="text"
                :disabled="!editInit"
                v-model="clientData.phone"
                placeholder="eg: +1 (347) 123-1234"
                required
              />
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <label>Reference # </label>
          <div class="form-group">
            <div class="input-group">
              <input
                @change="$emit('update-info', clientData, 'info')"
                id="email"
                type="text"
                class="form-control multisteps-form__input"
                name="text"
                :disabled="!editInit"
                v-model="clientData.refNumber"
                placeholder="eg: 1253658"
                required
              />
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-6 mt-3 mt-sm-0">
          <label
            >Collection <span class="required">*</span>
            <span
              :class="{
                'missing-field': errors['assignedUsers'],
                hidden: true,
              }"
              >- Missing Field</span
            ></label
          >

          <div class="form-group">
            <v-select
              :deselectFromDropdown="true"
              @input="$emit('update-info', clientData, 'collection')"
              :options="collections"
              :reduce="
                (c) => Object.assign({ id: c.id, title: c.title, logo: c.logo })
              "
              label="title"
              :disabled="!editInit"
              :loading="!collections.length"
              v-model="clientData.collection"
            >
            </v-select>
          </div>
        </div>

        <div class="col-12 col-sm-6 mt-3 mt-sm-0">
          <label
            >Markup Percentage <span class="required">*</span>
            <span
              :class="{
                'missing-field': errors['markup'],
                hidden: true,
              }"
              >- Missing Field</span
            ></label
          >
          <div class="form-group">
            <!-- <div class="percentage-icon">%</div> -->
            <input
              id="markup"
              type="number"
               min="200"
              max="500"
              :disabled="!editInit"
              class="form-control multisteps-form__input"
              name="commission"
              @input="$emit('update-info', clientData, 'commission')"
              v-model="clientData.commission"
              isRequired
            />
            <sub>Please select a value that is no less than 200%</sub>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="uploader">
          <Single
            @upload="uploadMedia"
            @remove="removeMedia"
            label="Client's Profile Picture"
            :media="clientData.metadata"
            :path="`client_logo/${clientData.company}`"
            media_type="images/*"
            :useCustomPath="true"
            :showRemoveButton="editInit"
          />
        </div>
      </div>
    </div>
    <div class="button-row">
      <soft-button
        v-if="
          $store.state.authModule.isSuperAdmin || $store.state.authModule.isEmployee
        "
        type="button"
        color="dark"
        variant="gradient"
        class="ms-auto mb-0 js-btn-next"
        @click-btn="editInit = !editInit"
        >{{ editInit ? "Cancel" : "Edit" }}</soft-button
      >
      <soft-button
        v-if="editInit"
        type="button"
        color="primary"
        variant="gradient"
        class="ms-auto mb-0 js-btn-next"
        @click-btn="
          () => {
            $emit('save-changes'), (editInit = false);
          }
        "
        >Save changes</soft-button
      >
    </div>
  </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import Single from "@/components/Upload/Single.vue";
import {
  storage,
  ref,
  deleteObject,
  doc,
  db,
  updateDoc,
} from "@/config/firebaseInit";
import { fetchDocuments } from "@/db/index";

export default {
  name: "display-info",
  components: {
    SoftButton,
    Single,
  },
  props: {
    client: {
      type: Object,
      default() {
        return Object;
      },
    },
  },
  emits: ["update-info", "save-changes"],
  data() {
    return {
      errors: {},
      editInit: false,
      clientData: {},
      collections: [],
      salesReps: [],
    };
  },
  created() {
    this.clientData = this.client;
    this.fetchCollections();
    this.fetchReps();
  },
  methods: {
    async fetchCollections() {
      this.collections = await fetchDocuments("collections");
    },
    async fetchReps() {
      this.salesReps = await fetchDocuments("sales_rep");
    },
    uploadMedia(data) {
      this.updateClient(data, this.clientData.id);
      this.clientData.metadata = data;
    },
    removeMedia() {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Continue",
      }).then((result) => {
        if (result.value) {
          this.updateClient({}, this.clientData.id);
          if (this.clientData.metadata.ref) {
            deleteObject(ref(storage, this.clientData.metadata.ref)).catch(
              (error) => {
                console.log(error);
                this.displayError(error);
              }
            );
            this.clientData.metadata = {};
          }
        }
      });
      // Delete the file
    },
    async updateClient(data, id) {
      await updateDoc(doc(db, "clients", id), {
        metadata: data,
      }).catch((err) => {
        console.error(err);
      });
    },
  },
};
</script>

<style></style>
