<template>
  <div v-if="store.client" class="container-fluid">
    <div class="row">
      <div class="nav-wrapper position-relative end-0">
        <ul class="nav nav-pills nav-fill p-1" role="tablist">
          <li class="nav-item">
            <p
              @click="selectedTab = 'details'"
              class="nav-link mb-0 px-0 py-1"
              :class="{ active: selectedTab == 'details' }"
            >
              Details
            </p>
          </li>
          <li class="nav-item">
            <p
              @click="selectedTab = 'address'"
              class="nav-link mb-0 px-0 py-1"
              :class="{ active: selectedTab == 'address' }"
            >
              Address
            </p>
          </li>
          <li class="nav-item">
            <p
              @click="selectedTab = 'orders'"
              class="nav-link mb-0 px-0 py-1"
              :class="{ active: selectedTab == 'orders' }"
            >
              Orders
            </p>
          </li>
        </ul>
      </div>
      <div class="col-lg-12">
        <div v-if="selectedTab == 'details'" class="card mb-4">
          <display-info
            @update="$emit('save-changes', $event, 'info')"
            :store="store"
          ></display-info>
        </div>
        <div v-if="selectedTab == 'address'" class="card mb-4">
          <display-address
            @update="$emit('save-changes', $event, 'address')"
            :store="store"
          ></display-address>
        </div>
        <div v-if="selectedTab == 'orders'" class="card">
          <div class="card-body p-3">
            <div class="row">
              <!-- <h6 class="mb-3 p-3 pt-0 font-weight-bolder">Track orders</h6>
              <hr class="horizontal dark mt-4 mb-4" /> -->
              <order-table :store="store" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import SoftAvatar from "@/components/SoftAvatar.vue";
import SoftBadge from "@/components/SoftBadge.vue";
import OrderTable from "./OrderTable.vue";
import DisplayAddress from "./components/DisplayAddress.vue";
import DisplayInfo from "./components/DisplayInfo.vue";

export default {
  name: "StoreDetails",
  components: {
    SoftButton,
    SoftAvatar,
    SoftBadge,
    OrderTable,
    DisplayAddress,
    DisplayInfo,
  },
  props: {
    store: {
      type: Object,
      default() {
        return Object;
      },
    },
  },
  data() {
    return {
      selectedTab: "details",
      clients: [],
      users: [],
    };
  },
};
</script>
<style scoped>
.metal-color-box {
  width: 30px;
  /* background-color: red; */
  height: 10px;
}
.nav-item {
  cursor: pointer;
}

.nav-item:last-child {
  cursor: pointer;
  border-right: none;
}

.active {
  background-color: rgba(23, 129, 69, 0.955) !important;
  color: white !important;
}
</style>
