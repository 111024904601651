<template>
  <div class="container-fluid py-6 px-6 card bg-white" data-animation="FadeIn">
    <soft-button
      type="button"
      color="warning"
      variant="gradient"
      class="ms-auto mb-0 js-btn-prev"
      style="left: 70px; position: absolute"
      @click-btn="$router.go(-1)"
    >
      <i class="fas fa-arrow-left"></i>
      Back
    </soft-button>
    <soft-button
      type="button"
      color="success"
      variant="gradient"
      class="ms-auto mb-0 js-btn-next"
      @click-btn="saveCollection"
      >Save
    </soft-button>
    <h5 class="font-weight-bolder">Collection Details</h5>
    <div class="multisteps-form__content">
      <div class="row">
        <div class="mt-1 row">
          <div class="col-12 col-sm-6">
            <label>Descriptions <span class="required">*</span></label>
            <div class="text-editor">
              <textarea
                v-model="collection.descriptions"
                placeholder="Write a description about this collection..."
              ></textarea>
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <div class="row">
              <div class="col-6">
                <label>Title <span class="required">*</span></label>
                <input
                  class="form-control"
                  v-model="collection.title"
                  type="text"
                  name=""
                  id=""
                />
              </div>
              <div class="row">
                <div class="col-3">
                  <label>
                    TTC Commission (%)<span class="required">*</span></label
                  >
                  <input
                    v-model="collection.dis_commission"
                    class="multisteps-form__input form-control"
                    type="number"
                    min="10"
                    placeholder="99.00"
                  />
                </div>
                <!-- <div class="col-3"> -->
                <!-- <label>
                  Factory Commission (%)<span class="required">*</span></label
                >
                <input
                  v-model="collection.factory_commission"
                  class="multisteps-form__input form-control"
                  type="number"
                  min="10"
                  placeholder="99.00"
                />
              </div> -->
                <div class="col-3">
                  <label>Shipping Cost<span class="required">*</span></label>
                  <input
                    v-model="collection.shippingCost"
                    class="multisteps-form__input form-control"
                    type="number"
                    min="0"
                    placeholder="150"
                  />
                </div>
                <div class="col-3">
                  <label>Services Fee<span class="required">*</span></label>
                  <input
                    v-model="collection.servicesFee"
                    class="multisteps-form__input form-control"
                    type="number"
                    min="0"
                    placeholder="15"
                  />
                </div>
              </div>
            </div>
            <div class="col-12">
              <label>Logo <span class="required">*</span></label>
              <Single
                @upload="handleImageUpload($event, collection, 'logo')"
                @remove="
                  handleImageRemoval(
                    collection.logo,
                    collection,
                    'logo',
                    'single'
                  )
                "
                :media="collection.logo"
                media_type="image"
                :useCustomPath="true"
                :path="`collection/${collection.title}/logo`"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <ProductDataTable
      @handle-edit="handleItemEdit"
      @handle-publishing="onSwitchChange"
      @handle-delete="handleDeleteItem"
      @new-item="handleNewItem"
      :settingOptions="jewelry_settings"
      :jewelryTypeOptions="jewelryTypes"
      :shapeOptions="shapeOptions"
      :unfilteredDataTable="collectionProducts"
      :styleOptionsPerType="styles"
    />
  </div>
  <modal
    @close="handleClosedModal"
    :showSaveBtn="false"
    :styleFormat="{ width: '80vw', marginLeft: '-30rem' }"
  >
    <template v-slot:body>
      <div class="col-lg-12 tab-content">
        <div class="card mb-4">
          <div class="item-wrapper">
            <div class="multisteps-form">
              <div class="row">
                <div class="mx-auto mt-4">
                  <div class="multisteps-form__progress">
                    <button
                      class="multisteps-form__progress-btn js-active"
                      type="button"
                      style="color: green"
                      title="Collection Setup"
                      :class="activeStep == 0 ? activeClass : ''"
                      @click="activeStep = 0"
                    >
                      <span style="color: black">1. Setup</span>
                    </button>

                    <button
                      class="multisteps-form__progress-btn"
                      type="button"
                      title="Pricing"
                      :class="activeStep == 1 ? activeClass : ''"
                      @click="activeStep = 1"
                    >
                      <span style="color: black"> 2. Pricing</span>
                    </button>

                    <button
                      class="multisteps-form__progress-btn"
                      type="button"
                      title="Media"
                      :class="activeStep == 2 ? activeClass : ''"
                      @click="activeStep = 2"
                    >
                      <span style="color: black"> 3. Media</span>
                    </button>
                  </div>
                </div>
              </div>
              <!--forms-->
              <div class="row">
                <div class="m-auto col-12 col-lg-11">
                  <div class="mb-8 multisteps-form__form">
                    <!--Collection Setup panel-->
                    <div
                      :class="activeStep === 0 ? activeClass : ''"
                      class="p-3 mt-5 bg-white card border-radius-xl"
                      data-animation="FadeIn"
                      v-if="activeStep === 0"
                    >
                      <div class="multisteps-form__content">
                        <div class="row">
                          <div class="col-sm-6">
                            <label class="mt-2"
                              >Descriptions
                              <span class="required">*</span></label
                            >
                            <div class="text-editor">
                              <textarea
                                v-model="itemAttributes.descriptions"
                                placeholder="Write a descriptions about this Item..."
                              ></textarea>
                            </div>
                          </div>
                          <div class="mt-9 col-sm-6 mt-sm-0">
                            <div class="mt-1 row">
                              <h5 style="text-decoration: underline" for="">
                                Details
                              </h5>
                              <div class="col-5 col-sm-4">
                                <label
                                  >SKU <span class="required">*</span></label
                                >
                                <input
                                  :disabled="initEdit"
                                  v-model="itemAttributes.sku"
                                  class="multisteps-form__input form-control"
                                  type="text"
                                />
                              </div>
                              <div class="col-5 col-sm-4">
                                <label
                                  >Jewelry Type
                                  <span class="required">*</span></label
                                >
                                <v-select
                                  v-model="itemAttributes.jewelryType"
                                  :options="jewelryTypes"
                                  class="form-control"
                                  label="type"
                                  @option:selected="
                                    handleRecentlyUsedItemsOnSelect(
                                      'jewelry',
                                      $event,
                                      'single'
                                    )
                                  "
                                >
                                  <template #list-header
                                    ><sub v-if="recentlyUsed['jewelry'].length"
                                      >Recently Used</sub
                                    >
                                    <div
                                      v-if="recentlyUsed['jewelry'].length"
                                      class="recently-used-list"
                                    >
                                      <div
                                        class="list"
                                        v-for="item in recentlyUsed['jewelry']"
                                        :key="item"
                                        @click="
                                          handleRecentlyUsedItemsOnClick(
                                            'jewelryType',
                                            item
                                          )
                                        "
                                      >
                                        {{ item }}
                                      </div>
                                    </div>
                                  </template></v-select
                                >
                              </div>
                              <div class="col-5 col-sm-4">
                                <label
                                  >Styles <span class="required">*</span></label
                                >
                                <v-select
                                  @option:selected="
                                    handleRecentlyUsedItemsOnSelect(
                                      'styles',
                                      $event,
                                      'single'
                                    )
                                  "
                                  v-model="itemAttributes.style"
                                  :options="styles[itemAttributes.jewelryType]"
                                  class="form-control"
                                  label="name"
                                >
                                  <template #list-header>
                                    <sub v-if="recentlyUsed['styles'].length"
                                      >Recently Used</sub
                                    >
                                    <div
                                      v-if="recentlyUsed['styles'].length"
                                      class="recently-used-list"
                                    >
                                      <div
                                        class="list"
                                        v-for="item in recentlyUsed['styles']"
                                        :key="item"
                                        @click="
                                          handleRecentlyUsedItemsOnClick(
                                            'style',
                                            item
                                          )
                                        "
                                      >
                                        {{ item }}
                                      </div>
                                    </div>
                                  </template></v-select
                                >
                              </div>
                              <div class="col-12 col-sm-6">
                                <label
                                  >Select Settings
                                  <span class="required">*</span>
                                </label>
                                <v-select
                                  @option:selected="
                                    handleRecentlyUsedItemsOnSelect(
                                      'settings',
                                      $event,
                                      'single'
                                    )
                                  "
                                  v-model="itemAttributes.setting"
                                  :options="jewelry_settings"
                                  class="form-control"
                                >
                                  <template #list-header>
                                    <sub v-if="recentlyUsed['settings'].length"
                                      >Recently Used</sub
                                    >
                                    <div
                                      v-if="recentlyUsed['settings'].length"
                                      class="recently-used-list"
                                    >
                                      <div
                                        class="list"
                                        v-for="item in recentlyUsed['settings']"
                                        :key="item"
                                        @click="
                                          handleRecentlyUsedItemsOnClick(
                                            'setting',
                                            item
                                          )
                                        "
                                      >
                                        {{ item }}
                                      </div>
                                    </div>
                                  </template>
                                </v-select>
                              </div>
                              <div class="col-12 col-sm-6">
                                <label
                                  >Diamond Shapes
                                  <span class="required">*</span></label
                                >
                                <v-select
                                  @option:selected="
                                    handleRecentlyUsedItemsOnSelect(
                                      'shapes',
                                      $event,
                                      'single'
                                    )
                                  "
                                  v-model="itemAttributes.shape"
                                  :options="shapeOptions"
                                  class="form-control"
                                >
                                  <template #list-header>
                                    <sub v-if="recentlyUsed['shapes'].length"
                                      >Recently Used</sub
                                    >
                                    <div
                                      v-if="recentlyUsed['shapes'].length"
                                      class="recently-used-list"
                                    >
                                      <div
                                        class="list"
                                        v-for="item in recentlyUsed['shapes']"
                                        :key="item"
                                        @click="
                                          handleRecentlyUsedItemsOnClick(
                                            'shape',
                                            item
                                          )
                                        "
                                      >
                                        {{ item }}
                                      </div>
                                    </div>
                                  </template></v-select
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="button-row mt-4">
                          <soft-button
                            type="button"
                            color="dark"
                            variant="gradient"
                            class="ms-auto mb-0 js-btn-next"
                            @click-btn="nextStep"
                            >Next Step
                          </soft-button>
                        </div>
                      </div>
                    </div>

                    <!-- Pricing Panel -->
                    <div
                      v-if="activeStep === 1"
                      class="p-3 mt-5 bg-white card border-radius-xl"
                      :class="activeStep === 1 ? activeClass : ''"
                    >
                      <div class="multisteps-form__content">
                        <!-- Attributes Inputs -->
                        <div class="row" style="margin-bottom: 50px">
                          <!-- Pricing Attributes -->
                          <div class="mt-1 col-12">
                            <h5 style="text-decoration: underline" for="">
                              Pricing
                            </h5>
                            <sub
                              >Add Multiple Metal/Diamond Weight and Total Price
                              per size</sub
                            >
                            <div class="row">
                              <div class="col-4">
                                <label
                                  >Diamond Origin
                                  <span class="required">*</span></label
                                >
                                <div
                                  class="col-5 col-sm-4"
                                  style="width: 300px"
                                >
                                  Natural
                                  <input
                                    type="radio"
                                    v-model="itemPricingAttributes.origin"
                                    value="Natural"
                                    id=""
                                  />
                                  Lab Grown
                                  <input
                                    type="radio"
                                    v-model="itemPricingAttributes.origin"
                                    value="Lab Grown"
                                    id=""
                                  />
                                </div>
                              </div>
                            </div>

                            <!-- Number Inputs -->
                            <div class="row">
                              <div class="col-2">
                                <label
                                  >Size
                                  <span v-if="sizeRange.init">Range</span>
                                  <span class="required">*</span>
                                </label>
                                <span
                                  style="
                                    margin-left: 5px;
                                    font-size: 0.5rem;
                                    font-weight: 700;
                                    margin-bottom: 0.5rem;
                                    color: #344767;
                                    margin-left: 0.25rem;
                                  "
                                >
                                  <input
                                    v-model="sizeRange.init"
                                    type="checkbox"
                                    :disabled="
                                      itemPricingAttributes.sizeNotIncluded
                                    "
                                  />
                                  Use Size Ranges
                                </span>
                                <span
                                  class="helper-icon"
                                  @mouseover="showHelper.sizeRange = true"
                                  @mouseleave="showHelper.sizeRange = false"
                                  >?</span
                                >

                                <div
                                  v-if="showHelper.sizeRange"
                                  class="helper-msg"
                                >
                                  This option is used when all the sizes have
                                  the same price. Mostly used for Engagement or
                                  Anniversary Rings.
                                </div>
                                <div v-if="!sizeRange.init" class="single-size">
                                  <input
                                    class="form-control"
                                    type="number"
                                    min="4.5"
                                    v-model="itemPricingAttributes.size"
                                    name=""
                                    max="22"
                                    step="0.5"
                                    id=""
                                    ref="sizeInput"
                                    :disabled="
                                      itemPricingAttributes.sizeNotIncluded
                                    "
                                  />

                                  <div class="checkbox-input">
                                    <input
                                      v-model="
                                        itemPricingAttributes.sizeNotIncluded
                                      "
                                      type="checkbox"
                                    />
                                    Don't Add Size
                                    <span
                                      class="helper-icon"
                                      @mouseleave="
                                        showHelper.dontAddSizeHelper = false
                                      "
                                      @mouseover="
                                        showHelper.dontAddSizeHelper = true
                                      "
                                      >?</span
                                    >
                                  </div>
                                  <div
                                    v-if="showHelper.dontAddSizeHelper"
                                    class="helper-msg dont-add-size-option"
                                  >
                                    This option is used when the size isn't
                                    calculated on the price, only metal and
                                    carat weight. Mostly used for Bracelets
                                    Necklaces and Earrings.
                                  </div>
                                </div>

                                <div v-else class="range-inputs">
                                  <div>
                                    <input
                                      class="form-control range-input"
                                      type="number"
                                      min="4.5"
                                      v-model="sizeRange.min"
                                      name=""
                                      max="15"
                                      step="0.25"
                                      id=""
                                    />
                                    <sub>Min</sub>
                                  </div>
                                  <div class="seperator">-</div>
                                  <div>
                                    <input
                                      class="form-control range-input"
                                      type="number"
                                      min="4.5"
                                      v-model="sizeRange.max"
                                      step="0.25"
                                      max="15"
                                      name=""
                                      id=""
                                    />
                                    <sub>Max</sub>
                                  </div>
                                </div>
                              </div>
                              <div class="col-2">
                                <label
                                  >Metal Weight
                                  <span class="required">*</span></label
                                >
                                <input
                                  v-model="
                                    itemPricingAttributes.metalData.weight
                                  "
                                  class="multisteps-form__input form-control"
                                  type="number"
                                  min="0"
                                />
                              </div>

                              <div class="col-2">
                                <label
                                  >Diamond Carat
                                  <span class="required">*</span></label
                                >
                                <input
                                  class="form-control"
                                  type="number"
                                  min="4.5"
                                  v-model="itemPricingAttributes.caratWeight"
                                  name=""
                                  max="15"
                                  step="0.5"
                                  id=""
                                  ref="caratInput"
                                />
                              </div>
                              <div class="col-2">
                                <label
                                  >Total # of Stones
                                  <span class="required">*</span></label
                                >
                                <input
                                  class="form-control"
                                  type="number"
                                  min="0"
                                  v-model="itemPricingAttributes.stoneQty"
                                  ref="stoneInput"
                                />
                              </div>
                            </div>

                            <!-- Dropdowns -->
                            <div class="row">
                              <div class="col-2">
                                <label
                                  >Diamond Colors
                                  <span class="required">*</span>
                                </label>
                                <div class="range-inputs">
                                  <div>
                                    <v-select
                                      v-model="
                                        itemPricingAttributes.diamondQuality
                                          .colors.from
                                      "
                                      :options="colorOptions"
                                      :clearable="false"
                                    >
                                    </v-select>
                                    <sub
                                      >From <span class="required">*</span></sub
                                    >
                                  </div>

                                  -
                                  <div>
                                    <v-select
                                      v-model="
                                        itemPricingAttributes.diamondQuality
                                          .colors.to
                                      "
                                      :options="colorOptions"
                                    >
                                    </v-select>
                                    <sub>To (Optional)</sub>
                                  </div>
                                </div>
                              </div>

                              <div class="col-2">
                                <label
                                  >Diamond Clarity
                                  <span class="required">*</span>
                                </label>
                                <div class="range-inputs">
                                  <div>
                                    <v-select
                                      v-model="
                                        itemPricingAttributes.diamondQuality
                                          .clarity.from
                                      "
                                      :options="clarityOptions"
                                      :clearable="false"
                                    >
                                    </v-select>
                                    <sub
                                      >From <span class="required">*</span></sub
                                    >
                                  </div>

                                  -
                                  <div>
                                    <v-select
                                      v-model="
                                        itemPricingAttributes.diamondQuality
                                          .clarity.to
                                      "
                                      :options="clarityOptions"
                                    >
                                    </v-select>
                                    <sub>To (Optional)</sub>
                                  </div>
                                </div>
                              </div>

                              <div class="col-2">
                                <label
                                  >Metals
                                  <span class="required">*</span>
                                  <!-- <span class="create-wrapper create-btn"
                                    >View Live Market</span
                                  > -->
                                </label>

                                <v-select
                                  v-model="
                                    itemPricingAttributes.metalData.metal
                                  "
                                  :options="metalOptions"
                                  label="name"
                                  :clearable="false"
                                ></v-select>
                              </div>

                              <div
                                v-if="
                                  itemPricingAttributes.metalData.metal ==
                                  'Gold'
                                "
                                class="col-2"
                              >
                                <label
                                  >Select Gram
                                  <span class="required">*</span>
                                </label>
                                <v-select
                                  v-model="itemPricingAttributes.metalData.gram"
                                  :options="gramOptions"
                                  label="gram"
                                >
                                </v-select>
                              </div>
                            </div>

                            <!-- Total Input -->
                            <div class="row">
                              <div class="col-3">
                                <label
                                  >Total Price
                                  <span class="required">*</span></label
                                >
                                <input
                                  @keypress="handleEnterPressed"
                                  v-model="itemPricingAttributes.totalPrice"
                                  class="multisteps-form__input form-control"
                                  type="number"
                                  min="0"
                                  placeholder="$"
                                />
                              </div>
                              <div class="col-3">
                                <div
                                  style="
                                    width: 100%;
                                    position: relative;
                                    height: 44px;
                                    display: flex;
                                    margin-top: 25px;
                                  "
                                >
                                  <soft-button
                                    v-if="!initPriceEdit"
                                    type="button"
                                    color="light"
                                    variant="gradient"
                                    class="js-btn-prev"
                                    @click="handlePricing"
                                  >
                                    <i class="fas fa-solid fa-plus"></i>
                                  </soft-button>
                                  <soft-button
                                    v-else
                                    type="button"
                                    color="light"
                                    variant="gradient"
                                    class="js-btn-prev"
                                    @click="handlePriceEdit"
                                  >
                                    <i class="fas fa-check"></i>
                                  </soft-button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <!-- Pricing Table Filters -->
                        <div class="px-0 pt-0 pb-2 card-body">
                          <div class="filters">
                            <div class="filter">
                              <label for="">Filter By Origin: </label>
                              <v-select
                                v-model="filterBy.origin"
                                :options="['Lab Grown', 'Natural']"
                              >
                              </v-select>
                            </div>
                            <div class="filter">
                              <label for="">Filter By Grams: </label>
                              <v-select
                                v-model="filterBy.grams"
                                :options="['14k', '18k', 'Platinum']"
                              >
                              </v-select>
                            </div>
                            <div class="filter">
                              <label for="">Filter By Quality: </label>
                              <v-select
                                v-model="filterBy.quality"
                                :options="['F-G/VS', 'H-I/SI']"
                              >
                              </v-select>
                            </div>
                            <div class="button-row d-flex mt-4">
                              <soft-button
                                style="margin-right: 20px"
                                type="button"
                                color="light"
                                variant="gradient"
                                @click="filterPriceTable"
                                >Filter</soft-button
                              >
                              <soft-button
                                type="button"
                                color="danger"
                                @click="clearFilterQuery"
                                >Clear</soft-button
                              >
                            </div>
                          </div>
                          <div class="list-values p-0 table-responsive">
                            <table class="table mb-0 align-items-center">
                              <thead>
                                <tr>
                                  <th
                                    class="text-uppercase text-center text-secondary text-xxs font-weight-bolder opacity-7"
                                  >
                                    Price
                                  </th>
                                  <th
                                    class="text-uppercase text-center text-secondary text-xxs font-weight-bolder opacity-7"
                                  >
                                    Origin
                                  </th>
                                  <th
                                    class="text-uppercase text-center text-secondary text-xxs font-weight-bolder opacity-7"
                                  >
                                    Size
                                  </th>
                                  <th
                                    class="text-uppercase text-center text-secondary text-xxs font-weight-bolder opacity-7"
                                  >
                                    Metal
                                  </th>
                                  <th
                                    class="text-uppercase text-center text-secondary text-xxs font-weight-bolder opacity-7"
                                  >
                                    Metal Weight
                                  </th>
                                  <th
                                    class="text-uppercase text-center text-secondary text-xxs font-weight-bolder opacity-7"
                                  >
                                    Quality
                                  </th>
                                  <th
                                    class="text-uppercase text-center text-secondary text-xxs font-weight-bolder opacity-7"
                                  >
                                    Carat Weight
                                  </th>
                                  <th
                                    class="text-uppercase text-center text-secondary text-xxs font-weight-bolder opacity-7"
                                  >
                                    Stone Qty
                                  </th>

                                  <th
                                    class="text-uppercase text-center text-secondary text-xxs font-weight-bolder opacity-7"
                                  >
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(att, index) in priceTableData"
                                  :key="index"
                                >
                                  <td class="text-sm align-middle">
                                    <p
                                      class="text-center mb-0 text-sm font-weight-bold"
                                    >
                                      {{ format_price(att.totalPrice) }}
                                    </p>
                                  </td>
                                  <td class="text-sm align-middle">
                                    <p
                                      class="text-center mb-0 text-sm font-weight-bold"
                                    >
                                      {{ att.origin }}
                                    </p>
                                  </td>
                                  <td class="text-sm align-middle">
                                    <p
                                      class="text-center mb-0 text-sm font-weight-bold"
                                    >
                                      {{ att.size || "N/A" }}
                                    </p>
                                  </td>
                                  <td class="text-sm align-middle">
                                    <p
                                      class="text-center mb-0 text-sm font-weight-bold"
                                    >
                                      {{ att.metalData.gram }}
                                      {{ att.metalData.metal }}
                                    </p>
                                  </td>
                                  <td class="text-sm align-middle">
                                    <p
                                      class="text-center mb-0 text-sm font-weight-bold"
                                    >
                                      {{ att.metalData.weight }}
                                    </p>
                                  </td>
                                  <td class="text-sm align-middle">
                                    <p
                                      class="text-center mb-0 text-sm font-weight-bold"
                                    >
                                      {{
                                        handleDiamondQualityInfo(
                                          att.diamondQuality
                                        )
                                      }}
                                    </p>
                                  </td>
                                  <td class="text-sm align-middle">
                                    <p
                                      class="text-center mb-0 text-sm font-weight-bold"
                                    >
                                      {{ att.caratWeight }}ct.
                                    </p>
                                  </td>
                                  <td class="text-sm align-middle">
                                    <p
                                      class="text-center mb-0 text-sm font-weight-bold"
                                    >
                                      {{ att.stoneQty }}
                                    </p>
                                  </td>

                                  <td
                                    class="align-middle"
                                    style="
                                      display: flex;
                                      flex-direction: row;
                                      justify-content: space-around;
                                    "
                                  >
                                    <div
                                      @click="initPricingEdit(att)"
                                      class="delete text-center mb-0 text-sm font-weight-bold"
                                    >
                                      <i
                                        style="color: green; cursor: pointer"
                                        class="fas fa-edit"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="bottom"
                                        title="Edit Price"
                                      ></i>
                                    </div>
                                    <div
                                      @click="initPricingDuplicate(att)"
                                      class="delete text-center mb-0 text-sm font-weight-bold"
                                    >
                                      <i
                                        style="color: orange; cursor: pointer"
                                        class="fas fa-clone"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="bottom"
                                        title="Duplicate Price"
                                      ></i>
                                    </div>
                                    <div
                                      @click="
                                        handleDeletePricingAttribute(att, index)
                                      "
                                      class="delete text-center mb-0 text-sm font-weight-bold"
                                    >
                                      <i
                                        style="color: red; cursor: pointer"
                                        class="fas fa-minus-circle"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="bottom"
                                        title="Remove Price"
                                      ></i>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <!-- Step Buttons -->
                        <div class="button-row mt-4">
                          <soft-button
                            style="margin-right: 20px"
                            type="button"
                            color="light"
                            variant="gradient"
                            class="js-btn-prev"
                            @click="prevStep"
                            >Prev</soft-button
                          >
                          <soft-button
                            type="button"
                            color="dark"
                            variant="gradient"
                            class="ms-auto mb-0 js-btn-next"
                            @click-btn="nextStep"
                            >Next Step
                          </soft-button>
                        </div>
                      </div>
                    </div>

                    <!-- Media Panel -->
                    <div
                      v-if="activeStep === 2"
                      :class="activeStep === 2 ? activeClass : ''"
                    >
                      <div>
                        <label for=""
                          >Upload Media <span class="required">*</span></label
                        >
                        <CollectionMultipleDrag
                          @handle-draggable="
                            handleMediaArrangement(
                              $event,
                              itemAttributes,
                              'mediaFiles'
                            )
                          "
                          @handle-color-select="
                            handleColorSelect(
                              $event,
                              itemAttributes,
                              'mediaFiles'
                            )
                          "
                          @uploaded-media="
                            handleImageUpload(
                              $event,
                              itemAttributes,
                              'mediaFiles'
                            )
                          "
                          @remove-media="
                            handleImageRemoval(
                              $event,
                              itemAttributes,
                              'mediaFiles',
                              'mult'
                            )
                          "
                          :files="itemAttributes.mediaFiles"
                          :colorOptions="[
                            'White Gold',
                            'Rose Gold',
                            'Yellow Gold',
                            'Platinum',
                          ]"
                          media_type="images"
                          :useCustomPath="true"
                          :path="`collection/${collection.title}/${itemAttributes.sku}`"
                        />
                      </div>
                      <div class="button-row mt-4">
                        <soft-button
                          style="margin-right: 20px"
                          type="button"
                          color="light"
                          variant="gradient"
                          class="js-btn-prev"
                          @click="prevStep"
                          >Prev</soft-button
                        >
                        <soft-button
                          type="button"
                          color="success"
                          variant="gradient"
                          class="ms-auto mb-0 js-btn-next"
                          data-bs-dismiss="modal"
                          @click-btn="addProductToCollection"
                          >Finish
                        </soft-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import Single from "@/components/Upload/Single.vue";
import CollectionMultipleDrag from "@/components/Upload/CollectionMultipleDrag.vue";
import ProductDataTable from "./components/ProductDataTable.vue";
import Modal from "@/components/Modal.vue";
import { doc, db, onSnapshot, collection } from "@/config/firebaseInit.js";

import {
  fetchDocuments,
  fetchDocument,
  removeMediaFromStorage,
  deleteDocument,
  addDocument,
  setDocument,
  updateDocument,
} from "@/db/index";

export default {
  name: "NewCollections",
  components: {
    SoftButton,
    Single,
    CollectionMultipleDrag,
    ProductDataTable,
    Modal,
  },
  data() {
    return {
      activeClass: "js-active position-relative",
      jewelryOptions: [],
      clients: [],
      shapeOptions: [],
      selectedJewelries: [],
      selectedItem: null,
      recentlyUsed: {
        settings: [],
        styles: [],
        shapes: [],
        grams: [],
        jewelry: [],
      },
      showHelper: {
        sizeRange: false,
        dontAddSizeHelper: false,
      },
      initPriceEdit: false,
      metalOptions: ["Gold", "Platinum"],
      metalColors: ["White Gold", "Rose Gold", "Yellow Gold", "Platinum"],
      gramOptions: [],
      selectedTab: null,
      showJewelryDropdown: false,
      completedSteps: {
        setup: false,
        media: false,
        pricing: false,
      },
      collection: {
        title: null,
        dis_commission: 0,
        factory_commission: 0,
        shippingCost: 150,
        servicesFee: 16,
        logo: {},
        products: [],
        skus: [],
        styles: [],
        settings: [],
      },
      styles: {
        Earring: [
          "Studs", // Simple, discreet, and elegant, studs are versatile and appropriate for the widest range of occasions and looks.
          "Hoops", // Traditionally, hoops are circular, but as they have grown in popularity, you will see all sorts of different shapes such as squares, ovals, or triangles[^2^][2].
          "Drops", // A drop earring will connect at the earlobe, but instead of resting against the ear, the drop earring will hang down or drop below the ear[^2^][2].
          "Dangles", // Dangles are similar to drop earrings, but the difference is that drop earrings are typically stationary, whereas dangles are free to swing back and forth[^2^][2].
          "Chandeliers", // Chandelier earrings get their name because they are shaped like a chandelier, but in a lot of cases, they might dangle as well[^2^][2].
          "Barbells", // A barbell earring has a thin, straight metal rod with a bead permanently fixed to one end. The other end is removable to allow the earring to be inserted into a piercing.
          "Ear Cuffs", // Ear cuffs wrap around the outer cartilage of the ear.
          "Threaders", // Threader earrings have a thin chain attached to a metal post, allowing them to be threaded through the ear.
          "Cluster Earrings", // These earrings have clusters of gemstones or other decorative elements[^3^][5].
          "Tassel Earrings", // These earrings feature hanging threads or chains[^3^][5].
          "Long Chain Earrings", // These earrings have long chains that can dangle below the shoulder[^3^][5].
        ],
        Ring: [
          "Wedding Ring",
          "Engagement Ring",
          "Fashion RIng",
          "Eternity Band",
          "Anniversary Band",
        ],
        Bracelet: ["Tennis", "Pearl", "Charm", "Bangle", "Beaded", "Chain"],
        Necklace: [
          "Tennis",
          "Pendant",
          "Chain", // Simple, minimal wardrobe staple.
          "Choker", // Worn around the neck, tends to be a bit wider to make up for its lack of length.
          "Collar", // A broad, often solid piece of jewelry that rides close to the neck.
          "Festoon", // A style of necklace that has multiple chains or strings draping in a swag.
          "Graduated", // A style where the size of the elements increases towards the center.
          "Lariat", // A long necklace that is typically tied or looped around the neck.
          "Lavalier", // A necklace with a pendant that has dangling stones.
          "Locket", // A pendant that opens to reveal a space used for storing a photograph or other small item.
          "Matinee", // A single or multi-strand necklace that hangs just below the bust.
          "Opera", // A long necklace that can be worn as a single or double strand.
          "Pendant", // A jewel, commonly a slide, locket, or charm, that is worn suspended from a chain.
          "Princess", // A necklace length that is longer than a choker but shorter than a matinee.
          "Riviere", // A necklace that is composed of a single strand of gemstones of the same general size and shape.
          "Sautoir", // A long necklace ending in tassels or a pendant.
          "Torque", // A rigid necklace that is worn open ended at the front.
        ],
      },
      products: [],
      itemAttributes: {
        jewelryType: "",
        style: "",
        shape: "",
        setting: "",
        prices: [],
        grams: ["14k", "18k"],
        metals: [{ name: "Gold" }, { name: "Platinum" }],
        descriptions: "",
        sku: "",
        mediaFiles: [],
      },
      jewelryTypes: [
        "Ring",
        "Necklace",
        "Bracelet",
        "Earring",
        "Brooche",
        "Watches",
      ],
      sizeRange: {
        init: false,
        max: 0,
        min: 0,
      },
      errors: {},
      activeStep: 0,
      clarityOptions: [
        "I1",
        "I2",
        "I3",
        "SI",
        "SI1",
        "SI2",
        "SI3",
        "VS",
        "VS2",
        "VS1",
        "VVS2",
        "VVS1",
        "IF",
      ],
      colorOptions: [
        "Z",
        "Y",
        "X",
        "W",
        "V",
        "U",
        "T",
        "S",
        "R",
        "Q",
        "P",
        "O",
        "N",
        "M",
        "L",
        "K",
        "J",
        "I",
        "H",
        "G",
        "F",
        "E",
        "D",
      ],
      selectedShapeMediaTab: {},
      filterBy: { origin: "", grams: "", quality: "" },
      itemPricingAttributes: {
        origin: "Natural",
        caratWeight: null,
        size: null,
        sizeNotIncluded: false,
        stoneQty: 0,
        totalPrice: null,
        quality: null,
        diamondQuality: {
          colors: {
            from: "F",
            to: "G",
          },
          clarity: {
            from: "VS",
            to: "",
          },
        },
        metalData: {
          weight: null,
          gram: null,
          metal: "Gold",
        },
      },
      liveMetalData: {},
      formSteps: 3,
      initEdit: false,
      collectionExists: false,
      collectionProducts: [],
      colName: "collections",
    };
  },
  computed: {
    priceTableData() {
      return this.itemAttributes.prices.filter((p) => {
        const originMatches =
          !this.filterBy.origin || p.origin === this.filterBy.origin;
        const gramsMatches =
          !this.filterBy.grams ||
          (p.metalData.gram &&
            p.metalData.gram.toLowerCase() ===
              this.filterBy.grams.toLowerCase()) ||
          p.metalData.metal == this.filterBy.grams;
        const qualityMatches =
          !this.filterBy.quality ||
          this.handleDiamondQualityInfo(p.diamondQuality) ===
            this.filterBy.quality;

        return originMatches && gramsMatches && qualityMatches;
      });
    },
  },
  watch: {
    selectedJewelries: {
      deep: true,
      handler() {
        this.selectedJewelries.forEach((s) => {
          this.completedSteps.setup = this.validateSetup(s);
          this.completedSteps.media = this.validateMedia(s);
          this.completedSteps.pricing = this.validatePricing(s);
        });
      },
    },
    "$store.state.metalModule.metals": {
      deep: true,
      handler() {
        this.liveMetalData = this.$store.state.metalModule.metals;
      },
    },
  },
  async created() {
    this.formatOptions();
    this.fetchCollection();
  },

  methods: {
    handleDiamondQualityInfo(data) {
      const colors = `${data.colors.from}${
        data.colors.to ? "-" + data.colors.to : ""
      }`;

      const clarity = `${data.clarity.from}${
        data.clarity.to ? "-" + data.clarity.to : ""
      }`;

      return `${colors}/${clarity}`;
    },

    clearFilterQuery() {
      for (const key in this.filterBy) {
        this.filterBy[key] = null;
      }
    },
    handleClosedModal() {
      this.resetItemCreation();
    },
    formatOptions() {
      this.shapeOptions = [
        "Asscher",
        "Baguette",
        "Cushion",
        "Heart",
        "Oval",
        "Pear",
        "Princess",
        "Radiant",
        "Rose",
        "Shield",
        "Taper",
        "Triangular",
        "Round",
        "Round Brilliant",
        "Marquise",
        "Emerald",
        "Old Cut",
        "Triangular Cut",
        "Baguette Cut",
        "Trapeze Cut",
        "Shield Cut",
        "Taper Cut",
        "Half Moon Cut",
        "Rose Cut",
      ];

      this.jewelry_settings = [
        "Bezel",
        "Prongs",
        "3 Prongs",
        "4 Prongs",
        "3 Stones Pavé",
        "6 prong Solitare",
        "Twist Solitare Pavé",
        "Hidden Halo",
        "Twist",
        "Graduated",
        "Pavé",
        "Channel",
        "Cathedral",
        "Tension",
        "Bar",
        "Halo",
        "Tiffany",
        "Flush",
        "Cluster",
        "Three-Stone",
      ];

      this.gramOptions = ["14k", "18k"];
    },
    async fetchClients() {
      const clients = await fetchDocuments("clients");
      this.clients = clients.map((d) =>
        Object.assign({ id: d.id, company: d.company, refNumber: d.refNumber })
      );
    },
    async fetchCollection() {
      if (this.$route.params.id !== "new-collection") {
        const unsub = await onSnapshot(
          doc(db, this.colName, this.$route.params.id),
          (doc) => {
            this.collection = doc.data();
          },
          (err) => {
            console.debug(err);
          }
        );

        const p_unsub = await onSnapshot(
          collection(db, `${this.colName}/${this.$route.params.id}/products`),
          (snapshot) => {
            this.collectionProducts = snapshot.docs.map((d) => d.data());
          },
          (err) => {
            console.debug(err);
          }
        );
        this.collectionExists = true;
      }
    },

    handleRecentlyUsedItemsOnSelect(key, item, type) {
      if (this.recentlyUsed[key].length < 3) {
        if (type == "single") {
          if (!this.recentlyUsed[key].includes(item)) {
            this.recentlyUsed[key].push(item);
          }
        } else {
          this.recentlyUsed[key] = item;
        }
      }
    },
    handleRecentlyUsedItemsOnClick(key, item) {
      this.itemAttributes[key] = item;
    },
    handleEnterPressed(e) {
      if (e.keyCode == 13) {
        this.handlePricing();
      }
    },

    initPricingEdit(att) {
      this.initPriceEdit = true;
      this.itemPricingAttributes = att;
    },
    initPricingDuplicate(att) {
      this.initPriceEdit = false;
      this.itemPricingAttributes = JSON.parse(JSON.stringify(att));
    },
    handlePriceEdit(index) {
      this.itemAttributes.prices[index] = this.itemPricingAttributes;
      this.clearPricingAttributes();
    },
    handleDeletePricingAttribute(att, index) {
      this.itemAttributes.prices = this.itemAttributes.prices.filter(
        (p, ind) => JSON.stringify(p) !== JSON.stringify(att)
      );

      this.$forceUpdate();
      // this.priceTableData = this.itemAttributes.prices;
    },
    handlePricing() {
      if (this.sizeRange.init) {
        for (
          let index = this.sizeRange.min;
          index <= this.sizeRange.max;
          index++
        ) {
          const obj = JSON.parse(JSON.stringify(this.itemPricingAttributes));
          obj.size = index;
          this.itemAttributes.prices.push(obj);
        }
      } else {
        const exisitingInput = this.itemAttributes.prices.find(
          (s) =>
            s.origin == this.itemPricingAttributes.origin &&
            s.totalPrice == this.itemPricingAttributes.totalPrice &&
            s.size == this.itemPricingAttributes.size &&
            s.metalData.metal == this.itemPricingAttributes.metalData.metal &&
            s.metalData.weight == this.itemPricingAttributes.metalData.weight &&
            s.caratWeight == this.itemPricingAttributes.caratWeight
        );
        if (!exisitingInput) {
          this.itemAttributes.prices.push(this.itemPricingAttributes);
        }
      }

      this.itemAttributes.prices = this.itemAttributes.prices.sort(
        (a, b) => a.caratWeight - b.caratWeight
      );
      this.clearPricingAttributes();
    },

    clearPricingAttributes() {
      this.itemPricingAttributes = {
        origin: "Natural",
        caratWeight: null,
        size: null,
        sizeNotIncluded:
          this.itemAttributes.jewelryType !== "Ring" ? true : false,
        stoneQty: 0,
        totalPrice: null,
        quality: null,
        diamondQuality: {
          colors: {
            from: "F",
            to: "G",
          },
          clarity: {
            from: "VS",
            to: "",
          },
        },
        metalData: {
          weight: null,
          gram: null,
          metal: "Gold",
        },
      };

      this.sizeRange = {
        init: false,
        max: 0,
        min: 0,
      };
      this.initPriceEdit = false;
      this.$refs["sizeInput"].focus();
    },

    validateSetup(jewelry) {
      let valid = false;
      for (const key in jewelry) {
        if (itemAttributes.hasOwnProperty.call(jewelry, key)) {
          if (key != "type") {
            const element = jewelry[key];
            if (typeof element != "object") {
              if (element) valid = true;
            } else {
              if (
                !["prices", "size", "carat"].includes(key) &&
                element &&
                element.length
              ) {
                valid = true;
              }
            }
          }
        }
      }

      this.errors[itemAttributes.jewelryType].setup = valid;
      return valid;
    },
    validateMedia() {
      let valid = false;
      itemAttributes.shapes.forEach((s) => {
        if (s.mediaFiles.length) valid = true;
      });

      this.errors[itemAttributes.jewelryType].media = valid;
      return valid;
    },
    validatePricing(jewelry) {
      let valid = false;
      if (Object.keys(itemAttributes.prices).length) valid = true;
      itemAttributes.shapes.forEach((s) => {
        if (s.price) valid = true;
      });

      itemAttributes.metals.forEach((m) => {
        if (m.price) valid = true;
      });

      this.errors[itemAttributes.jewelryType].prices.metal = valid;
      return valid;
    },
    nextStep() {
      if (this.activeStep < this.formSteps) {
        this.activeStep += 1;
      } else {
        this.activeStep -= 1;
      }
    },
    prevStep() {
      if (this.activeStep > 0) {
        this.activeStep -= 1;
      }
    },

    selectedTabHandler(type) {
      this.selectedTab = type;
    },
    jewelrySelected(jewelry) {
      this.activeStep = 0;
      this.selectedShapeMediaTab[itemAttributes.jewelryType] = {};
      this.jewelryOptions = this.jewelryOptions.filter(
        (s) => s.jewelryType !== itemAttributes.jewelryType
      );
      this.selectedJewelries.push(jewelry);
      this.selectedTab = itemAttributes.jewelryType;
      this.showJewelryDropdown = false;
      this.errors[itemAttributes.jewelryType] = {
        media: false,
        prices: { metal: false, shapes: false },
        setup: false,
      };
    },

    selectedOption(key, selectedValue, action) {
      if (key == "metalOptions") {
        if (
          !this.$store.state.metalModule.metals.gold.timestamp &&
          selectedValue[0].name == "Gold"
        ) {
          this.$store.dispatch("metalModule/getLiveGoldData", "gold");
        }

        if (
          !this.$store.state.metalModule.metals.platinum.timestamp &&
          selectedValue[0].name == "Platinum"
        ) {
          this.$store.dispatch("metalModule/getLiveGoldData", "platinum");
        }
      }
    },

    async addProductToCollection() {
      if (!this.collection.skus.includes(this.itemAttributes.sku)) {
        this.collection.skus.push(this.itemAttributes.sku);
      }

      if (!this.collection.styles.includes(this.itemAttributes.style)) {
        this.collection.styles.push(this.itemAttributes.style);
      }

      if (!this.collection.settings.includes(this.itemAttributes.setting)) {
        this.collection.settings.push(this.itemAttributes.setting);
      }

      try {
        this.collection.updatedAt = Date.now();
        await setDocument(
          `${this.colName}/${this.$route.params.id}/products`,
          this.itemAttributes.sku,
          this.itemAttributes
        );
        this.collectionExists
          ? await setDocument(
              this.colName,
              this.$route.params.id,
              this.collection
            )
          : await addDocument(this.colName, this.collection);
        setTimeout(() => {
          this.resetItemCreation();
        }, 1000);
      } catch (error) {
        console.debug(error);
        this.displayError(error);
      }
    },

    resetItemCreation() {
      this.activeStep = 0;
      this.initEdit = false;
      this.itemAttributes = {
        jewelryType: "",
        style: "",
        shape: "",
        setting: "",
        prices: [],
        grams: ["14k", "18k"],
        metals: [{ name: "Gold" }, { name: "Platinum" }],
        descriptions: "",
        sku: "",
        mediaFiles: [],
      };
    },
    handleNewItem() {
      this.resetItemCreation();
    },
    handleItemEdit(item) {
      this.itemAttributes = item;
      this.initEdit = true;
    },
    handleDeleteItem(item) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Continue",
      }).then(async (result) => {
        if (result.value) {
          deleteDocument(
            `${this.colName}/${this.$route.params.id}/products`,
            item.sku
          );

          this.collection.updatedAt = Date.now();
          setDocument(this.colName, this.$route.params.id, this.collection);
          this.$swal("Deleted!", "The data has been deleted.", "success");
        }
      });
    },
    onSwitchChange(data) {
      updateDocument(
        `${this.colName}/${this.$route.params.id}/products`,
        data.id,
        {
          active: data.event.target.checked,
        }
      );

      updateDocument(this.colName, this.$route.params.id, {
        updatedAt: Date.now(),
      });
    },
    async saveCollection() {
      this.collectionExists
        ? await setDocument(
            this.colName,
            this.$route.params.id,
            this.collection
          )
        : await addDocument(this.colName, this.collection);
      this.$swal({
        icon: "success",
        title: "Collection Successfully Saved",
        type: "success-message",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Go Back",
      }).then((val) => {
        if (!val) {
          this.$router.push("/collections");
        }
      });
    },
    handleImageUpload(value, data, key) {
      if (data) {
        if (data[key].length) {
          data[key] = [].concat(...data[key], ...value);
          return;
        }
        data[key] = value;
      } else {
        if (this[key].length) {
          this[key] = [].concat(...this[key], ...value);
          return;
        }
        this[key] = value;
        console.debug(this.uploadedImages);
      }
    },
    handleColorSelect(value, data, key) {
      data[key][value.index].metalColor = value.option;
    },
    handleMediaArrangement(value, data, key) {
      const items = data[key];
      const previousItem = items[value.moved.newIndex];
      const movedItem = items[value.moved.oldIndex];
      items[value.moved.newIndex] = movedItem;
      items[value.moved.oldIndex] = previousItem;
      data[key] = items;
    },
    handleImageRemoval(value, data, key, type) {
      if (type === "mult") {
        removeMediaFromStorage(value.ref);
        if (data) data[key] = data[key].filter((i) => i.ref !== value.ref);
        else this[key] = this[key].filter((i) => i.ref !== value.ref);
      } else {
        if (data) {
          if (data[key].ref) {
            removeMediaFromStorage(value.ref);
            data[key] = {};
          }
        } else {
          if (this[key].ref) {
            removeMediaFromStorage(value.ref);
            this[key] = [];
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.helper-msg {
  position: absolute;
  margin-left: 140px;
  margin-top: -6%;
  width: 250px;
  height: 60px;
  background: #f1f4f8;
  border-radius: 5px;
  overflow: hidden;
  color: black;
  font-size: 11px;
  padding: 3px 5px 0 6px;
  text-align: center;
  border: 1px dashed black;
  z-index: 999;
}

.dont-add-size-option {
  margin-left: 95px !important;
  margin-top: -7% !important;
  height: 75px !important;
  padding: 2px 5px 0 6px !important;
}
.checkbox-input {
  margin-left: 5px;
  font-size: 0.5rem;
  font-weight: 700;
  color: #344767;
  margin-left: 0.25rem;
}
.recently-used-list {
  display: flex;
  justify-content: space-evenly;
  border: 1px dashed;
  width: auto;
  position: relative;
  overflow-x: auto;
}

.list {
  cursor: pointer;
  border-right: 1px solid black;
  width: 50%;
  text-align: center;
}

.list:last-child {
  border-right: none;
}

.list:hover {
  background-color: #5897fb;
  color: white;
}
.values {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.list-values {
  max-height: 300px;
  min-height: 50px;
  width: 100%;
  border: 0.5px dashed black;
  margin-top: 5px;
  overflow: auto;
  padding: 10px;
}
.nav {
  margin-left: 20px;
}
.tabs {
  display: flex;
  align-items: center;
  color: white;
  font-weight: bold;
  /* justify-content: space-around; */
}

.tab {
  width: 120px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 5px 0;
  border-radius: 5px;
  margin-right: 5px;
  color: black;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.nav-item {
  border: 1px solid #356c72;
  border-radius: 7px;
}

/* .tab:hover {
  background-color: #424b3e8b !important;
  color: white;
} */

.tab:not(.active):not(.add-tab):hover {
  background-color: #424b3e8b !important;
  color: white;
}

.item-wrapper {
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%) !important;
  border-radius: 15px;
  height: auto;
}

.jewelry-info {
  width: 98%;
  margin: 0 auto;
}
.text-editor {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  border-radius: 10px;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
}

.text-editor textarea {
  width: 97.5%;
  height: 95%;
  padding: 20px;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  resize: none;
  transition: all 0.3s ease;
  outline: none;
}

.text-editor textarea:focus {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.text-editor textarea::placeholder {
  color: #aaa;
}

.text-editor textarea:hover {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
}

.active {
  background-color: #424b3e;
  color: white;
}
.create-wrapper {
  font-size: 10px;
}

.close-tab:not(.add-tab):hover {
  background-color: crimson !important;
}
.range-inputs {
  width: 100% !important;
  display: flex;
  justify-content: space-evenly;
}

.range-input {
  width: 100% !important;
}

.filters {
  display: flex;
  justify-content: flex-start;
}

.filter {
  width: 200px;
  margin-right: 20px;
}

.seperator {
  width: 10px;
  padding: 3px;
}

.range-wrapper {
  width: 100% !important;
}

.create-btn {
  text-decoration: underline;
  color: grey;
  cursor: pointer;
}

.create-btn:hover {
  color: green;
}

.remove-btn {
  position: absolute;
  right: 5px;
  top: 17px;
  color: whitesmoke;
  cursor: pointer;
  width: 16px;
  height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 100;
  padding-bottom: 2px;
}

.remove-btn:hover {
  background-color: rgba(105, 103, 103, 0.63);
  width: 16px;
  height: 17px;
  border-radius: 5px;
}

.helper-icon {
  font-size: 0.65rem;
  cursor: pointer;
  border: 1px solid white;
  border-radius: 15px;
  background-color: #afafaf52;
  width: 18px;
  display: inline-block;
  text-align: center;
  color: #2a34c5;
  font-weight: 700;
}
</style>
