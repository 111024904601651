<template>
  <div
    class="card multisteps-form__panel p-3 border-radius-xl bg-white"
    data-animation="FadeIn"
  >
    <h5 class="font-weight-bolder mb-0">About User</h5>
    <p class="mb-0 text-sm">Mandatory Information</p>
    <div class="multisteps-form__content">
      <div class="row mt-3">
        <div class="col-12 col-sm-6">
          <label
            >Administration Access <span class="required">*</span>
            <span
              :class="{
                'missing-field': errors['selectedAdminAccess'],
                hidden: true,
              }"
              >- Missing Field</span
            ></label
          >
          <div class="form-group">
            <select
              id="choices-category-edit"
              v-model="selectedAdminAccess"
              class="form-control"
              name="choices-category"
              @change="handleAdminAccess"
            >
              <option value="super">Executive Admin</option>
              <!-- <option value="client">Client Admin</option> -->
              <option value="employee">DIS Employee</option>
              <!-- <option value="store">Store Admin</option> -->
              <option value="factory">Factory Admin</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-sm-6">
          <label
            >First Name <span class="required">*</span>
            <span
              :class="{
                'missing-field': errors['firstName'],
                hidden: true,
              }"
              >- Missing Field</span
            ></label
          >
          <div class="form-group">
            <div class="input-group">
              <input
                id="firstName"
                type="text"
                class="form-control multisteps-form__input"
                name="text"
                v-model="user.firstName"
                placeholder="First Name"
                required
              />
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 mt-3 mt-sm-0">
          <label
            >Last Name <span class="required">*</span>
            <span
              :class="{
                'missing-field': errors['lastName'],
                hidden: true,
              }"
              >- Missing Field</span
            ></label
          >
          <div class="form-group">
            <div class="input-group">
              <input
                id="lastName"
                type="text"
                class="form-control multisteps-form__input"
                name="text"
                v-model="user.lastName"
                placeholder="Last Name"
                required
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-sm-6 mt-3 mt-sm-0">
          <label
            >Email Address <span class="required">*</span>
            <span
              :class="{
                'missing-field': errors['email'],
                hidden: true,
              }"
              >- Missing Field</span
            ></label
          >
          <div class="form-group">
            <div class="input-group">
              <input
                id="email"
                type="text"
                class="form-control multisteps-form__input"
                name="text"
                v-model="user.email"
                placeholder="eg: user@email.com"
                required
              />
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <label>Phone # </label>
          <div class="form-group">
            <div class="input-group">
              <input
                id="phone"
                type="tel"
                class="form-control multisteps-form__input"
                name="text"
                v-model="user.phone"
                placeholder="eg: +1 (347) 123-1234"
                required
              />
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12 col-sm-6">
          <label
            >Password <span class="required">*</span>
            <span :class="{ 'missing-field': errors['current'], hidden: true }"
              >- Missing Field</span
            ></label
          >
          <div class="form-group">
            <div class="input-group">
              <input
                id="password"
                :type="inputPasswordType"
                class="form-control multisteps-form__input"
                name="password"
                v-model="password.current"
                placeholder="******"
                isRequired
              />
              <div
                @click="handlePasswordType"
                class="input-group-text click-event"
              >
                <span class="">
                  <i class="fas fa-eye"></i>
                </span>
              </div>
            </div>
            <span class="required" v-if="feedback.current">{{
              feedback.current
            }}</span>
          </div>
        </div>
        <div class="col-12 col-sm-6 mt-3 mt-sm-0">
          <label
            >Repeat Password <span class="required">*</span>
            <span :class="{ 'missing-field': errors['repeat'], hidden: true }"
              >- Missing Field</span
            ></label
          >
          <div class="form-group">
            <div class="input-group">
              <input
                id="password"
                :type="inputPasswordType"
                class="form-control multisteps-form__input"
                name="password"
                v-model="password.repeat"
                placeholder="******"
                isRequired
              />
              <div
                @click="handlePasswordType"
                class="input-group-text click-event"
              >
                <span class="">
                  <i class="fas fa-eye"></i>
                </span>
              </div>
            </div>
            <span class="required" v-if="feedback.repeat">{{
              feedback.repeat
            }}</span>
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <h5 class="mt-5">Password requirements</h5>
          <p class="text-muted mb-2">
            Please follow this guide for a strong password:
          </p>
          <ul class="text-muted ps-4 mb-0 float-start">
            <li>
              <span class="text-sm">One special characters</span>
            </li>
            <li>
              <span class="text-sm">Min 6 characters</span>
            </li>
            <li>
              <span class="text-sm">One number (2 are recommended)</span>
            </li>
            <li>
              <span class="text-sm">Change it often</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="button-row d-flex mt-4">
        <!-- <soft-button
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto mb-0 js-btn-next"
          @click="this.$parent.nextStep"
          >Next</soft-button
        > -->
        <soft-button
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto mb-0 js-btn-next"
          @click-btn="handleFormValidation"
          >Save</soft-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import SoftInput from "@/components/SoftInput.vue";
import SoftButton from "@/components/SoftButton.vue";

//Firebase
import {
  collection,
  doc,
  db,
  setDoc,
  addDoc,
  functions,
  httpsCallable,
} from "@/config/firebaseInit.js";

export default {
  name: "UserInfo",
  components: {
    SoftInput,
    SoftButton,
  },
  data() {
    return {
      addUser: httpsCallable(functions, "addUser"),
      inputPasswordType: "password",
      selectedAdminAccess: null,
      errors: {
        firstName: false,
        lastName: false,
        email: false,
        current: false,
        repeat: false,
        selectedAdminAccess: false,
      },
      roles: {
        super: "DIS Executive",
        client: "Client Executive",
        store: "Store Employee",
        employee: "DIS Employee",
        factory: "Factory Employee",
      },
      password: {
        current: "",
        repeat: "",
      },
      user: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        role: "",
        admin_access: {
          super: false,
          client: false,
          store: false,
          employee: false,
          factory: false,
        },
        createdAt: Date.now(),
      },
      feedback: {
        repeat: null,
        current: null,
      },
    };
  },
  methods: {
    handleAdminAccess(e) {
      const access = e.target.value;
      this.user.admin_access = {
        super: false,
        client: false,
        store: false,
        employee: false,
        factory: false,
      };
      this.user.admin_access[access] = true;
      this.user.role = this.roles[access];
    },
    async saveUser() {
      this.addUser({
        email: this.user.email,
        password: this.password.current,
        displayName: `${this.user.firstName} ${this.user.lastName}`,
      }).then(async (res) => {
        this.user.id = res.data;
        await setDoc(doc(db, "users", res.data), this.user).then(() => {
          this.$swal({
            icon: "success",
            title: "User Successfully Added",
            text: `User ${this.user.firstName} has been added to the roster`,
            type: "success-message",
          }).then(() => this.$router.push("/users"));
        });
      });
    },
    handlePasswordType() {
      if (this.inputPasswordType.includes("password")) {
        this.inputPasswordType = "text";
      } else {
        this.inputPasswordType = "password";
      }
    },
    handleFormValidation() {
      let valid = true;
      let passwordValid = true;
      this.errors = {
        firstName: false,
        lastName: false,
        email: false,
        current: false,
        repeat: false,
        selectedAdminAccess: this.selectedAdminAccess ? false : true,
      };

      for (const key in this.user) {
        if (key !== "phone" && key !== "admin_access" && key !== "role") {
          if (!this.user[key]) {
            this.errors[key] = true;
            valid = false;
          }
        }
      }
      passwordValid = this.handlePasswordValidation(this.password);
      if (valid && passwordValid) {
        this.saveUser();
      }
    },
    handlePasswordValidation(password) {
      let valid = true;
      this.feedback.current = "";
      this.feedback.repeat = "";

      const regex =
        /^(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=.*[0-9])(?=.{6,})[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/;
      if (!password.current) {
        this.errors.current = true;
        valid = false;
      } else if (
        !regex.test(password.current) &&
        !regex.test(password.repeat)
      ) {
        this.feedback.current = "The password does not meet requirement";
        valid = false;
      } else if (password.current !== password.repeat) {
        this.feedback.repeat = "Passwords don't match";
        valid = false;
      }
      return valid;
    },
  },
};
</script>
