<template>
  <hr class="horizontal dark mt-0 mb-4" />
  <div class="card-body p-3 pt-0">
    <div class="d-sm-flex justify-content-between">
      <div>
        <!-- <soft-button
        class="btn-icon ms-2 export"
        size
        color="success"
        variant="outline"
        @click="$router.push('/clients/new-client')"
      >
        <span class="btn-inner--icon">
          <i class="ni ni-single-02"></i>
        </span>
        <span class="btn-inner--text"> New Client</span>
      </soft-button> -->
      </div>
      <div class="d-flex">
        <!-- <div class="dropdown d-inline">
        <soft-button
          id="navbarDropdownMenuLink2"
          color="dark"
          variant="outline"
          class="dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          >Filters</soft-button
        >
        <ul
          class="dropdown-menu dropdown-menu-lg-start px-2 py-3"
          aria-labelledby="navbarDropdownMenuLink2"
          style
        >
          <li>
            <a class="dropdown-item border-radius-md" href="javascript:;"
              >Status: Paid</a
            >
          </li>
          <li>
            <a class="dropdown-item border-radius-md" href="javascript:;"
              >Status: Refunded</a
            >
          </li>
          <li>
            <a class="dropdown-item border-radius-md" href="javascript:;"
              >Status: Canceled</a
            >
          </li>
          <li>
            <hr class="horizontal dark my-2" />
          </li>
          <li>
            <a
              class="dropdown-item border-radius-md text-danger"
              href="javascript:;"
              >Remove Filter</a
            >
          </li>
        </ul>
      </div> -->
        <soft-button
          class="btn-icon ms-2 export"
          size
          color="dark"
          variant="outline"
          data-type="csv"
        >
          <span class="btn-inner--icon">
            <i class="ni ni-archive-2"></i>
          </span>
          <span class="btn-inner--text">Export CSV</span>
        </soft-button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card mt-4">
          <div class="table-responsive">
            <div class="search-bar">
              <soft-input
                @input="searchForClient"
                placeholder="Search By Company/Client Name"
              >
              </soft-input>
            </div>
            <div class="table-wrapper">
              <table id="client-list" class="table table-hover">
                <thead class="thead-light">
                  <tr>
                    <th>Company</th>
                    <th>Client's Name</th>
                    <th>Assigned Collection</th>
                    <!--  <th>Client Stores</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="client in queriedData"
                    :key="client.id"
                    class="table-row"
                    data-bs-toggle="modal"
                    data-bs-target="#Modal"
                    @click="viewClientDetails(client)"
                  >
                    <td>
                      <div class="align-items-center">
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ client.company }}
                        </p>
                      </div>
                    </td>
                    <td class="text-xs font-weight-bold">
                      <div class="align-items-center">
                        <div class="align-items-center">
                          <span>
                            {{ client.firstName }} {{ client.lastName }}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="align-items-center">
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ client.collection.title }}
                        </p>
                      </div>
                    </td>
                    <!-- <td>
                      <div class="align-items-center">
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ client.stores.join(" | ") }}
                        </p>
                      </div>
                    </td> -->
                    <!-- 
                    <td class="text-xs font-weight-bold">
                      <div class="align-items-center">
                        <div class="align-items-center">
                          {{
                            `${client.address.addressOne}, ${client.address.city}, ${client.address.state}, ${client.address.zipCode}`
                          }}
                        </div>
                      </div>
                    </td> -->
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="pagination-wrapper">
              <pagination
                :value="pagination.currentPage"
                class="pagination-no-border pagination-success"
                :per-page="pagination.perPage"
                :total="total"
                :from="from"
                :to="to"
                @input="handlePagination"
              >
              </pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <modal
        @close="(showModal = false), (selectedClient = {})"
        :styleFormat="{ width: '1000px', marginLeft: '-10.75rem' }"
        :showSaveBtn="false"
      >
        <template v-slot:title>
          <h2>Client Details</h2>
        </template>
        <template v-slot:body>
          <div class="client-details-wrapper">
            <ClientDetails :client="selectedClient" />
          </div>
        </template>
      </modal> -->
</template>
    
  <script>
import SoftButton from "@/components/SoftButton.vue";
import SoftAvatar from "@/components/SoftAvatar.vue";
import SoftCheckbox from "@/components/SoftCheckbox.vue";
import SoftInput from "@/components/SoftInput.vue";
import Modal from "@/components/Modal.vue";
import Pagination from "@/components/Pagination.vue";
import {
  collection,
  doc,
  db,
  orderBy,
  query,
  updateDoc,
  onSnapshot,
  where,
  endAt,
  limit,
  getDocs,
} from "@/config/firebaseInit.js";

export default {
  name: "client-table",
  components: {
    SoftButton,
    SoftAvatar,
    SoftCheckbox,
    SoftInput,
    Modal,
    Pagination,
  },
  props: {
    rep: {
      type: Object,
      default() {
        return Object;
      },
    },
  },
  data() {
    return {
      tableData: [],
      clients: [],
      showModal: false,
      selectedClient: {},
      collections: {
        dsbPE0IQ0I3h6T111sZ3: "Luna Collection",
      },
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [250, 500, 1000],
        total: 0,
      },
      currentSort: "name",
      currentSortOrder: "asc",
      filterBy: {
        rep: "",
        status: "",
        customers: [],
        startDate: "",
        endDate: "",
      },
    };
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data.
     */
    queriedData() {
      let result = this.tableData;

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.tableData.length;
    },
  },
  created() {
    this.fetchAllClients();
  },
  methods: {
    async fetchAllClients() {
      console.debug(this.rep);
      let queryRef = query(
        collection(db, "clients"),
        where("salesRep.id", "==", this.rep.id)
      );

      const clients = await getDocs(queryRef);
      this.clients = clients.docs.map((user) =>
        Object.assign({ id: user.id }, user.data())
      );
      this.tableData = this.clients;
    },
    fetchrepClients() {},
    handlePagination(value) {
      this.pagination.currentPage = value;
    },
    viewClientDetails(client) {
      this.selectedClient = client;
      setTimeout(() => {
        this.showModal = true;
      }, 1000);
    },
    searchForClient(e) {
      if (!e.target.value) {
        this.tableData = this.client;
      }
      let input = e.target.value.toLowerCase();
      this.tableData = this.clients.filter((client) => {
        const name = `${client.firstName} ${client.lastName}`.toLowerCase();
        const company = client.company.toLowerCase();

        return company.includes(input) || name.includes(input);
      });
    },
  },
};
</script>
    <style>
.table-row {
  cursor: pointer;
}

.search-bar {
  position: relative;
  width: 200px;
  top: 10px;
  left: 10px;
}

.pagination-wrapper {
  padding: 20px;
}
</style>
    