<template>
  <div class="container-fluid py-4">
    <div class="d-sm-flex justify-content-between">
      <!-- <div>
          <soft-button color="success" variant="gradient">New order</soft-button>
        </div> -->
      <div class="d-flex">
        <!-- <div class="dropdown d-inline">
          <soft-button
            id="navbarDropdownMenuLink2"
            color="dark"
            variant="outline"
            class="dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            >Filters</soft-button
          >
          <ul
            class="dropdown-menu dropdown-menu-lg-start px-2 py-3"
            aria-labelledby="navbarDropdownMenuLink2"
            style
          >
            <li>
              <a class="dropdown-item border-radius-md" href="javascript:;"
                >Status: Paid</a
              >
            </li>
            <li>
              <a class="dropdown-item border-radius-md" href="javascript:;"
                >Status: Refunded</a
              >
            </li>
            <li>
              <a class="dropdown-item border-radius-md" href="javascript:;"
                >Status: Canceled</a
              >
            </li>
            <li>
              <hr class="horizontal dark my-2" />
            </li>
            <li>
              <a
                class="dropdown-item border-radius-md text-danger"
                href="javascript:;"
                >Remove Filter</a
              >
            </li>
          </ul>
        </div> -->
        <soft-button
          class="btn-icon ms-2 export"
          size
          color="dark"
          variant="outline"
          data-type="csv"
        >
          <span class="btn-inner--icon">
            <i class="ni ni-archive-2"></i>
          </span>
          <span class="btn-inner--text">Export CSV</span>
        </soft-button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card mt-4">
          <div class="table-responsive">
            <div class="search-bar">
              <soft-input
                @input="searchForOrder"
                placeholder="Search By Order #, PO#"
              >
              </soft-input>
            </div>
            <div class="table-wrapper">
              <table id="order-list" class="table table-hover">
                <thead class="thead-light">
                  <tr>
                    <th>Shipped On</th>
                    <th>Order #</th>
                    <th>PO #</th>
                    <th>SKU</th>
                    <th>Store ID</th>
                    <th>Status</th>
                    <th>Consumer</th>
                    <th>Shipped To</th>
                    <th>Processed By</th>
                    <th>Track Shipment</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="order in queriedData"
                    :key="order.id"
                    class="table-row"
                  >
                    <td>
                      <div class="align-items-center">
                        <!-- <soft-checkbox /> -->
                        <p
                          v-if="order.shippingDetails.shippedOn"
                          class="text-xs font-weight-bold ms-2 mb-0"
                        >
                          {{
                            format_date_time(order.shippingDetails.shippedOn)
                          }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="align-items-center">
                        <!-- <soft-checkbox /> -->
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ order.id }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="align-items-center">
                        <!-- <soft-checkbox /> -->
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ order.purchaseOrderNumber }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="align-items-center">
                        <!-- <soft-checkbox /> -->
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ order.sku }}
                        </p>
                      </div>
                    </td>
                    <td class="font-weight-bold">
                      <span class="my-2 text-xs">
                        {{ order.consumer.storeId }}
                      </span>
                    </td>
                    <td class="text-xs font-weight-bold">
                      <div class="align-items-center">
                        <!-- <soft-button
                        color="dark"
                        variant="outline"
                        class="btn-icon-only btn-rounded mb-0 me-2 btn-sm align-items-center justify-content-center"
                      >
                        <i class="fas fa-undo" aria-hidden="true"></i>
                      </soft-button> -->
                        <span>{{ order.status }}</span>
                      </div>
                    </td>
                    <td class="text-xs font-weight-bold">
                      <div class="align-items-center">
                        <span>
                          {{
                            `${order.consumer.firstName} ${order.consumer.lastName}`
                          }}
                        </span>
                      </div>
                    </td>
                    <td class="text-xs font-weight-bold">
                      <div v-if="!order.status.includes('DIS')" class="align-items-center">
                        <span>
                          {{
                            `${order.consumer.shipping.addressOne} ${
                              order.consumer.shipping.addressTwo || ""
                            }, ${order.consumer.shipping.city}, ${
                              order.consumer.shipping.state
                            }, ${order.consumer.shipping.zipCode}`
                          }}
                        </span>
                      </div>
                      <div v-else>
                        Shipped To DIS HQ
                      </div>
                    </td>
                    <td class="text-xs font-weight-bold">
                      <div class="align-items-center">
                        <span>
                          {{ `${order.proccessedBy.fullName}` }}
                        </span>
                      </div>
                    </td>
                    <td :id="order.id">
                      <soft-button
                        class="btn-icon ms-2 export"
                        size
                        color="dark"
                        variant="outline"
                        @click="trackOrder(order)"
                      >
                        <span class="btn-inner--icon">
                          <i
                            data-bs-toggle="tooltip"
                            class="ni ni-delivery-fast"
                            title="Track Order"
                          ></i>
                        </span>
                      </soft-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <nav aria-label="Page navigation">
              <div class="pagination-wrapper">
                <div class="page-dropdown">
                  <label for="orders">Per page: &nbsp; </label>
                  <select v-model="pagination.perPage" name="orders">
                    <option
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>
                </div>

                <pagination
                  :value="pagination.currentPage"
                  class="pagination-no-border pagination-success"
                  :per-page="pagination.perPage"
                  :total="total"
                  :from="from"
                  :to="to"
                  @input="handlePagination"
                >
                </pagination>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
  <modal
    :styleFormat="{ width: '1000px', marginLeft: '-10.75rem' }"
    @close="(showModal = false), (selectedOrder = {})"
  >
    <template v-slot:title>
      <h2>{{ selectedOrder.orderNumber }} Details</h2>
    </template>
    <template v-slot:body>
      <div class="order-details-wrapper">
        <ShippingDetails @handle-input="handleInput" :order="selectedOrder" />
      </div>
    </template>
    <template v-slot:footer>
      <button @click="saveChanges" type="button" class="btn btn-primary">
        Save changes
      </button>
    </template>
  </modal>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import SoftAvatar from "@/components/SoftAvatar.vue";
import SoftCheckbox from "@/components/SoftCheckbox.vue";
import SoftInput from "@/components/SoftInput.vue";
import Modal from "@/components/Modal.vue";
import ShippingDetails from "./ShippingDetails.vue";
import Pagination from "@/components/Pagination.vue";
import {
  collection,
  doc,
  db,
  getDocs,
  getDoc,
  setDoc,
  where,
  orderBy,
  query,
} from "@/config/firebaseInit.js";

export default {
  name: "OrderList",
  components: {
    SoftButton,
    SoftAvatar,
    SoftCheckbox,
    SoftInput,
    Modal,
    ShippingDetails,
    Pagination,
  },
  data() {
    return {
      tableData: [],
      orders: [],
      showModal: false,
      selectedOrder: {},
      currentSort: "name",
      currentSortOrder: "asc",
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [50, 250, 500, 1000],
        total: 0,
      },
    };
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data.
     */
    queriedData() {
      let result = this.tableData;

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.tableData.length;
    },
  },
  created() {
    this.getAllOrders();
  },
  methods: {
    async saveChanges() {
      const selectedOrder = this.selectedOrder;
      await setDoc(doc(db, "orders", selectedOrder.id), selectedOrder).then(
        () => {
          this.$swal({
            icon: "success",
            title: "Successful Update",
            text: `Order # ${selectedOrder.id} has been updated`,
            type: "success",
          });
        }
      );
    },
    handleInput(e, key, subKey) {
      if (subKey) {
        this.selectedOrder[key][subKey] = e.target.value;
      } else {
        this.selectedOrder[key] = e.target.value;
      }
    },
    async trackOrder(order) {
      PKGEExtWidget.trackButton({
        //Required. Your personal widget token
        token: "4g-7fXo6Uu4FDODKCVZXVNO7GBKSeLO0",
        //Required. Tracking number.
        trackNumber: order.shippingDetails.trackingNumber,
        //Required. ID for the element after which the 'iframe' with package tracking results will be added in the DOM structure when hovering/clicking.
        elementId: order.id,
        //Optional. Width of the frame in which package tracking results will be displayed. The default value extends to the container's entire width.
        width: 460,
        //Optional. Height of the frame in which package tracking results will be displayed. The default value is 370.
        height: 370,
        //Optional. User interface language. If not set, it will be determined by the user's browser settings.
        language: "en",
      });
    },
    async getAllOrders() {
      const orderRef = collection(db, "orders");
      const orderQuery = query(
        orderRef,
        where("status", "in", ["Shipped To Consumer", "Shipped To DIS"]),
        orderBy("shippingDetails.shippedOn", "desc")
      );
      const orders = await getDocs(orderQuery);
      this.orders = orders.docs.map((user) =>
        Object.assign({ id: user.id }, user.data())
      );
      this.tableData = this.orders;
    },
    handlePagination(value) {
      this.pagination.currentPage = value;
    },
    viewOrderDetails(order) {
      this.selectedOrder = order;
      this.showModal = true;
    },
    searchForOrder(e) {
      let input = e.target.value;
      if (!input) {
        this.tableData = this.order;
      }
      this.tableData = this.orders.filter((order) => order.id.includes(input));
    },
  },
};
</script>
<style scoped>
.table-row {
  cursor: pointer;
}

.search-bar {
  position: relative;
  width: 200px;
  top: 10px;
  left: 10px;
}

.pkge-frame-close-button {
  position: relative;
  visibility: inherit !important;
}
</style>
