<template>
  <div class="upload-parent">
    <label>{{ label }} <span v-if="required" style="color: red">*</span></label>
    <div class="upload_holder">
      <transition name="fade">
        <div
          v-if="(!media || !media.url) && !load"
          class="upload_click"
          @click="$refs.upload.click()"
        >
          <Upload />
          <p>
            You currently have no media uploaded. Click here to upload media.
          </p>
        </div>
      </transition>

      <transition name="fade">
        <div v-if="!media.url && load" class="loading">
          <i class="fas fa-spinner fa-spin"></i>
          <p>loading...</p>
        </div>
      </transition>

      <transition name="fade">
        <div v-if="media.url && !load" class="success" :class="customClass">
          <img
            class="image-media"
            @click="enlargeImage"
            v-if="media_type != 'video' && media_type != 'docs'"
            :src="media.url"
          />
          <video
            poster=""
            v-if="media_type == 'video'"
            width="320"
            height="240"
            controls
          >
            <source :src="media.url" type="video/mp4" />
            Your browser does not support the video tag.
          </video>

          <iframe
            v-if="media_type == 'docs'"
            class="image-media"
            :src="media.url"
            frameborder="0"
          ></iframe>
          <p v-if="showRemoveButton" @click="$emit('remove')" class="text_btn">
            Remove
          </p>
        </div>
      </transition>
      <input
        ref="upload"
        type="file"
        :accept="media_type + '/*'"
        class="hide_upload"
        @change="uploadMedia"
      />
    </div>
  </div>
  <!-- <image-modal
    :styleFormat="{ width: '1000px', marginLeft: '-10.75rem' }"
    :url="media.url"
  /> -->
</template>

<script>
import {
  storage,
  ref,
  uploadBytes,
  getDownloadURL,
} from "@/config/firebaseInit";
import Upload from "@/components/Icon/Upload.vue";
import ImageModal from "../ImageModal.vue";

export default {
  components: {
    Upload,
    ImageModal,
  },
  props: {
    customClass: {
      type: String,
      default: "",
    },
    showRemoveButton: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: "",
    },
    media: {
      type: Object,
      default: () => Object,
    },
    media_type: {
      type: String,
      default: "",
    },
    path: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    useCustomPath: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["upload", "remove"],
  data() {
    return {
      load: false,
      image: null,
      showImageModule: false,
    };
  },
  methods: {
    closeModal() {
      const modalElement = document.getElementById("image-modal");
      const bootstrapModal = new bootstrap.Modal(modalElement);
      bootstrapModal.hide();
    },
    enlargeImage() {
      const modalElement = document.getElementById("image-modal");
      const bootstrapModal = new bootstrap.Modal(modalElement);
      bootstrapModal.show();
    },
    uploadMedia(e) {
      this.load = true;
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        const refStr = this.useCustomPath
          ? this.path
          : `${this.path}/${file.name}`;
        const fileRef = ref(storage, refStr);
        uploadBytes(fileRef, file)
          .then(() => {
            getDownloadURL(ref(storage, fileRef)).then((downloadURL) => {
              let meta = {
                url: downloadURL,
                name: file.name,
                type: file.type.split("/")[1],
                ref: refStr,
                uploadedAt: Date.now(),
              };
              this.$emit("upload", meta);
              this.load = false;
            });
          })
          .catch((err) => {
            this.load = false;
            console.error(err);
            alert("could not upload logo, please try again.");
          });
      };
    },
  },
};
</script>

<style lang="css" scoped>
.image-media:hover {
  cursor: zoom-in;
}
.upload_holder {
  width: 100%;
  height: 100%;
  padding: 30px;
  background-color: #f7f7f7;
  /* max-width: 400px; */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.hide_upload {
  position: absolute;
  z-index: -2;
}

.upload_click,
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.upload_click .md-icon {
  font-size: 53px !important;
  opacity: 0.5;
}

.upload_click p {
  line-height: 1.3;
  max-width: 300px;
  text-align: center;
  opacity: 0.5;
  margin-top: 30px;
  margin-bottom: 0;
}

.rotate {
  font-size: 40px !important;
  -webkit-animation: loading 1s infinite linear;
}

.success {
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.success p {
  text-align: center;
  margin-bottom: 0;
}

.success img {
  width: 100%;
  max-height: 100%;
}

.text_btn {
  text-decoration: underline;
  color: red;
  width: 61px;
}

@-webkit-keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
