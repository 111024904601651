<template>
  <div class="container-fluid py-4">
    <div class="d-sm-flex justify-content-between">
      <div class="d-flex">
        <soft-button
          class="btn-icon ms-2 export"
          size
          color="dark"
          variant="outline"
          data-type="csv"
          @click="exportDataTable"
        >
          <span class="btn-inner--icon">
            <i class="ni ni-archive-2"></i>
          </span>
          <span class="btn-inner--text">Export CSV</span>
        </soft-button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card mt-4 p-3 pt-0">
          <div class="table-responsive">
            <div class="search-bar">
              <soft-input
                style="width: 100%"
                @input="searchForOrder"
                placeholder="Search By Order #, PO#"
              >
              </soft-input>
              <div
                v-click-outside="() => (toggleColorDropdown = false)"
                class="color-key-container"
              >
                <div class="">
                  <div class="" id="headingOne">
                    <h5 class="mb-0">
                      <a
                        data-bs-toggle="collapse"
                        href="#color-key-dropdown"
                        aria-controls="color-key-dropdown"
                        aria-expanded="false"
                        class="btn btn-link"
                        v-bind="$attrs"
                        type="button"
                        @click="toggleColorDropdown = !toggleColorDropdown"
                      >
                        Color Key
                      </a>
                    </h5>
                  </div>

                  <div
                    id="color-key-dropdown"
                    class="collapse"
                    aria-labelledby="headingOne"
                    data-parent="#accordion"
                  >
                    <div class="color-key-dropdown">
                      <div
                        class="color-key-list"
                        v-for="(color, status) in statusColors"
                        :key="status"
                      >
                        <div
                          @click="filterByStatus(status)"
                          :style="{
                            backgroundColor: color,
                            cursor: 'pointer',
                            color: status.includes('Delayed')
                              ? 'white'
                              : 'black',
                          }"
                          class="color-status"
                        >
                          {{ status }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="light">
              <div class="filters">
                <div class="filter">
                  <p class="filter-label">Clients</p>
                  <v-select
                    :appendToBody="true"
                    :deselectFromDropdown="true"
                    v-model="filterBy.client"
                    :options="clients"
                    label="company"
                    :reduce="(t) => t.id"
                    :closeOnSelect="true"
                  >
                  </v-select>
                </div>

                <div class="filter">
                  <p class="filter-label">Order Status</p>
                  <v-select
                    :appendToBody="true"
                    :deselectFromDropdown="true"
                    v-model="filterBy.status"
                    :options="[
                      'Order Created',
                      'Factory Received Order',
                      'In Process',
                      'Shipped To DIS',
                      'Delayed',
                      'DIS Received Order',
                      'QA Staging',
                      'In Vault',
                      'Shipped to Consumer',
                    ]"
                    :closeOnSelect="true"
                  >
                  </v-select>
                </div>

                <div class="filter">
                  <p class="filter-label">Sales Rep</p>
                  <v-select
                    :appendToBody="true"
                    :deselectFromDropdown="true"
                    v-model="filterBy.rep"
                    :options="salesReps"
                    :reduce="(s) => s.id"
                    :closeOnSelect="true"
                    label="firstName"
                  >
                  </v-select>
                </div>
                <div class="filter customer">
                  <p class="filter-label">Payment Status</p>
                  <v-select
                    :appendToBody="true"
                    :deselectFromDropdown="true"
                    v-model="filterBy.paymentStatus"
                    :options="['Paid', 'Not Paid', 'In Process']"
                    :closeOnSelect="true"
                  >
                  </v-select>
                </div>
                <div class="filter">
                  <p class="filter-label">Start From:</p>
                  <input
                    type="date"
                    @change="updateTimestamp($event, 'startDate')"
                    class="datetime-input"
                    v-model="filterBy.startDate"
                  />
                </div>
                <div class="filter">
                  <p class="filter-label">End At:</p>
                  <input
                    type="date"
                    @change="updateTimestamp($event, 'endDate')"
                    class="datetime-input"
                    v-model="filterBy.endDate"
                  />
                </div>

                <div class="filter filter-btn">
                  <soft-button
                    type="button"
                    color="primary"
                    variant="gradient"
                    class="ms-auto mb-0 js-btn-next"
                    @click="handleFilter"
                  >
                    Filter
                  </soft-button>
                  <soft-button
                    type="button"
                    color="warning"
                    variant="gradient"
                    class="ms-auto mb-0 js-btn-next"
                    @click="clearFilters"
                  >
                    Clear
                  </soft-button>
                </div>
              </div>
            </div>
            <div class="table-wrapper">
              <table id="order-list" class="table table-hover">
                <thead class="thead-light">
                  <tr>
                    <th>Order Created On</th>
                    <th>Order #</th>
                    <th>PO #</th>
                    <th>SKU</th>
                    <th>Origin</th>
                    <th>CTW</th>
                    <th>Size</th>
                    <th>Client</th>
                    <th>Sales Rep</th>
                    <th>Store ID</th>
                    <th>Item Type</th>
                    <th>Quantity</th>
                    <th>Payment Type</th>
                    <th>Order Status</th>
                    <th>Payment Status</th>
                    <th>Price Breakdown</th>
                  </tr>
                </thead>
                <tbody style="vertical-align: middle">
                  <tr
                    v-for="order in queriedData"
                    :key="order.id"
                    class="table-row"
                    data-bs-toggle="modal"
                    data-bs-target="#Modal"
                    @click="viewOrderDetails(order)"
                    :class="{ 'table-active': selectedOrder.id == order.id }"
                    :style="{
                      backgroundColor:
                        statusColors[order.paymentStatus.toUpperCase()],
                    }"
                    style="color: black"
                  >
                    <td class="font-weight-bold">
                      <div class="align-items-center">
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ format_date_time(order.createdAt) }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div>
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ order.orderNumber }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="align-items-center">
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ order.purchaseOrderNumber }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="align-items-center">
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ order.sku }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="align-items-center">
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ order.origin }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="align-items-center">
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ order.caratWeight }}ctw
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="align-items-center">
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ order.size }}
                          <span>{{
                            order.jewelryType !== "Ring" ? "''" : ""
                          }}</span>
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="align-items-center">
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ `${order.client.company}` }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="align-items-center">
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{
                            `${order.salesRep.firstName} ${order.salesRep.lastName}`
                          }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="align-items-center">
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ order.consumer.storeId }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="align-items-center">
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ order.jewelryType }}
                        </p>
                      </div>
                    </td>

                    <td>
                      <div>
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ order.quantity }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div>
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ order.paymentType }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div>
                        <p
                          style="text-transform: uppercase"
                          class="text-xs font-weight-bold ms-2 mb-0"
                        >
                          {{ order.paymentStatus }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div>
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ order.status }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <table>
                        <thead>
                          <tr>
                            <th>Metal Rates/Price</th>
                            <th>Unit Price</th>
                            <th>Trezza's Price 10% (FOB)</th>
                            <th>
                              Luna's Total Cost
                              <div>
                                <sub>6% + $135</sub>
                              </div>
                            </th>
                            <th>Sales Rep Total Cost</th>
                            <th>Store's Original Cost</th>
                            <th>
                              Luna's Transaction Fees
                              <div><sub>3%</sub></div>
                            </th>
                            <th>Store's Original Cost + Fees</th>
                            <th>
                              Braintree/Paypal Fees
                              <div><sub>2.59% + $0.45</sub></div>
                            </th>
                            <th>Luna Payment Received</th>
                            <th>Order Total Cost</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <table>
                              <thead>
                                <tr>
                                  <th>Metal</th>
                                  <th>Price At Time of Purchase</th>
                                  <th>Gram Rate</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <span style="text-transform: uppercase">
                                      {{ order.metal.metal }}</span
                                    >
                                  </td>
                                  <td>
                                    {{
                                      format_price(
                                        order.gold_metal_price_rates.price
                                      )
                                    }}
                                  </td>
                                  <td>
                                    <span v-if="order.metal.gram">
                                      {{
                                        order.gold_metal_price_rates
                                          .metal_rates[
                                          `price_gram_${order.metal.gram.toLowerCase()}`
                                        ]
                                      }}
                                      on
                                      <sub style="text-transform: uppercase">{{
                                        order.metal.gram
                                      }}</sub>
                                    </span>
                                    <span v-else>
                                      {{
                                        order.gold_metal_price_rates
                                          .metal_rates[`price_gram_14k`]
                                      }}
                                      on
                                      <sub style="text-transform: uppercase"
                                        >14K</sub
                                      >
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <td>
                              {{
                                format_price(
                                  calculateCurrentUnitPrice(
                                    calculateCurrentTrezzaPrice(
                                      order.originalPrice,
                                      order.origin
                                    )
                                  )
                                )
                              }}
                            </td>
                            <td>
                              {{
                                format_price(
                                  calculateCurrentTrezzaPrice(
                                    order.originalPrice,
                                    order.origin
                                  )
                                )
                              }}
                            </td>
                            <td>
                              {{
                                format_price(
                                  calculateLunaCost(
                                    calculateCurrentTrezzaPrice(
                                      order.originalPrice,
                                      order.origin
                                    )
                                  )
                                )
                              }}
                            </td>
                            <td>
                              {{
                                format_price(
                                  calculateSalesRepTotal(
                                    calculateCurrentTrezzaPrice(
                                      order.originalPrice,
                                      order.origin
                                    ),
                                    order.origin
                                  )
                                )
                              }}
                            </td>
                            <td>
                              {{ format_price(order.originalPrice) }}
                            </td>
                            <td>
                              {{
                                format_price(retrieveLunasTransactionFee(order))
                              }}
                            </td>
                            <td>
                              {{
                                format_price(
                                  order.priceBreakdown.storeTotalCost
                                )
                              }}
                            </td>
                            <td>
                              {{
                                format_price(
                                  calculateTransactionalFees(
                                    order.priceBreakdown.storeTotalCost,
                                    order.paymentType
                                  )
                                )
                              }}
                            </td>
                            <td>
                              {{
                                format_price(
                                  calculateLunaTotalPaymentReceived(
                                    calculateTransactionalFees(
                                      order.priceBreakdown.storeTotalCost,
                                      order.paymentType
                                    ),
                                    order.priceBreakdown.storeTotalCost
                                  )
                                )
                              }}
                            </td>

                            <td>
                              {{ format_price(order.originalPrice * 2) }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table>
                        <thead>
                          <tr>
                            <th>Sales Rep Commission</th>
                            <th>Luna Commission</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              {{ format_price(order.originalPrice) }}
                              -
                              {{
                                format_price(
                                  calculateSalesRepTotal(
                                    calculateCurrentTrezzaPrice(
                                      order.originalPrice,
                                      order.origin
                                    ),
                                    order.origin
                                  )
                                )
                              }}

                              =

                              <span style="color: red">
                                {{
                                  format_price(
                                    order.originalPrice -
                                      calculateSalesRepTotal(
                                        calculateCurrentTrezzaPrice(
                                          order.originalPrice,
                                          order.origin
                                        ),
                                        order.origin
                                      )
                                  )
                                }}
                              </span>
                            </td>
                            <td>
                              <span style="color: green">
                                {{
                                  format_price(
                                    calculateLunaCommission(
                                      calculateSalesRepTotal(
                                        calculateCurrentTrezzaPrice(
                                          order.originalPrice,
                                          order.origin
                                        ),
                                        order.origin
                                      ),
                                      calculateLunaCost(
                                        calculateCurrentTrezzaPrice(
                                          order.originalPrice,
                                          order.origin
                                        )
                                      )
                                    )
                                  )
                                }}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <!-- <td class="text-xs font-weight-bold">
                      <div class="align-items-center">
                        <p  :style="{
                      color: statusColors[order.paymentStatus.toUpperCase()],
                    }" class="text-xs font-weight-bold ms-2 mb-0">
                          {{ order.status }}
                        </p>
                      </div>
                    </td> -->
                  </tr>
                </tbody>
              </table>
            </div>
            <nav aria-label="Page navigation example">
              <div class="pagination-wrapper">
                <div class="page-dropdown">
                  <label for="orders">Per page: &nbsp; </label>
                  <select v-model="pagination.perPage" name="orders">
                    <option
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>
                </div>

                <pagination
                  :value="pagination.currentPage"
                  class="pagination-no-border pagination-success"
                  :per-page="pagination.perPage"
                  :total="total"
                  :from="from"
                  :to="to"
                  @input="handlePagination"
                >
                </pagination>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
  <modal
    ref="contentToExport"
    :styleFormat="{ width: '1000px', marginLeft: '-10.75rem' }"
    @close="(showModal = false), (selectedOrder = {})"
  >
    <template v-slot:title>
      <h2>{{ selectedOrder.orderNumber }} Details</h2>
    </template>
    <template v-slot:body>
      <div class="order-details-wrapper">
        <OrderDetails @handle-input="handleInput" :order="selectedOrder" />
      </div>
    </template>
  </modal>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import SoftAvatar from "@/components/SoftAvatar.vue";
import SoftCheckbox from "@/components/SoftCheckbox.vue";
import SoftInput from "@/components/SoftInput.vue";
import Modal from "@/components/Modal.vue";
import OrderDetails from "./OrderDetails.vue";
import Pagination from "@/components/Pagination.vue";
import ExcelJS from "exceljs";
import { DateTime } from "luxon";
import {
  setDoc,
  collection,
  doc,
  db,
  orderBy,
  query,
  startAfter,
  onSnapshot,
  where,
  startAt,
  endAt,
  limit,
  getDoc,
} from "@/config/firebaseInit.js";

export default {
  name: "OrderList",
  components: {
    SoftButton,
    SoftAvatar,
    SoftCheckbox,
    SoftInput,
    Modal,
    OrderDetails,
    Pagination,
  },
  data() {
    return {
      toggleColorDropdown: false,
      searchedData: [],
      totalLunaCommissionTally: 0,
      filterBy: {
        status: "",
        paymentStatus: "",
        client: "",
        rep: "",
        startDate: "",
        endDate: "",
      },
      statusColors: {
        PAID: "rgba(11, 247, 91, 0.56)",
        "IN PROCESS": "rgba(255, 239, 0, 0.51)",
        "NOT PAID": "#ff000059",
      },
      metals: {
        wg: "White Gold",
        yg: "Yellow Gold",
        rg: "Rose Gold",
        plt: "Platinum",
      },
      tableData: [],
      orders: [],
      clients: [],
      salesReps: [],
      showModal: false,
      showExportOptions: false,
      selectedOrder: {},
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [50, 250, 500, 1000],
        total: 0,
      },
    };
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data.
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData.slice(this.from, this.to);
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.tableData.length;
    },
  },
  watch: {
    toggleColorDropdown(val) {
      let myCollapse = document.getElementById("color-key-dropdown");
      if (!val) {
        setTimeout(() => {
          myCollapse.classList.remove("show");
          let bsCollapse = new bootstrap.Collapse(myCollapse, {
            toggle: false,
          });
        }, 500);
      } else {
        let bsCollapse = new bootstrap.Collapse(myCollapse, {
          toggle: true,
        });
      }
    },
  },
  created() {
    this.fetchData("start");
    this.getAllSalesReps();
    this.getAllClients();
  },
  methods: {
    async getAllSalesReps() {
      const orderRef = collection(db, "sales_rep");
      const orderQuery = query(orderRef, orderBy("firstName", "asc"));

      const unsub = await onSnapshot(orderQuery, (querySnapshot) => {
        this.salesReps = querySnapshot.docs.map((doc) =>
          Object.assign({ id: doc.id }, doc.data())
        );
      });
    },
    async getAllClients() {
      const orderRef = collection(db, "clients");
      const orderQuery = query(orderRef, orderBy("company", "asc"));

      const unsub = await onSnapshot(orderQuery, (querySnapshot) => {
        this.clients = querySnapshot.docs.map((doc) =>
          Object.assign({ id: doc.id }, doc.data())
        );
      });
    },
    updateTimestamp(event, key) {
      this.filterBy[key] = event.target.value;
    },
    async exportDataTable() {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Sheet 1");

      const header = [
        [
          "Order Placed",
          "Customer Name",
          "Order Number",
          "PO Number",
          "Stock Number",
          "Item Type",
          "Origin",
          "CTW",
          "Payment Method",
          "Customer's Original Cost",
          "Luna Transaction Fee",
          "Customer's Cost + Fees",
          "Braintree/Paypal Fee",
          "Amount Received",
          "Metal",
          "Metal Price",
          "Metal Rate",
          "Trezza's Cost (FOB)",
          "Luna Total Cost",
          "Salesman Cost",
          "Sales Person",
          "Sales Person %",
          "Luna Report",
        ],
      ];

      const dataToExport = this.queriedData.map((order) => {
        return [
          this.format_date(order.createdAt),
          order.client.company,
          order.orderNumber,
          order.purchaseOrderNumber,
          order.sku,
          order.jewelryType,
          order.origin,
          order.caratWeight,
          order.paymentType,
          this.format_price(order.originalPrice),
          this.format_price(this.retrieveLunasTransactionFee(order)),
          this.format_price(order.priceBreakdown.storeTotalCost),
          this.format_price(
            this.calculateTransactionalFees(
              order.priceBreakdown.storeTotalCost,
              order.paymentType
            )
          ),
          this.format_price(
            this.calculateLunaTotalPaymentReceived(
              this.calculateTransactionalFees(
                order.priceBreakdown.storeTotalCost,
                order.paymentType
              ),
              order.priceBreakdown.storeTotalCost
            )
          ),
          order.metal.metal.toUpperCase(),
          this.format_price(order.gold_metal_price_rates.price),
          order.metal.gram
            ? order.gold_metal_price_rates.metal_rates[
                `price_gram_${order.metal.gram.toLowerCase()}`
              ]
            : order.gold_metal_price_rates.metal_rates.price_gram_14k,
          this.format_price(
            this.calculateCurrentTrezzaPrice(order.originalPrice, order.origin)
          ),
          this.format_price(
            this.calculateLunaCost(
              this.calculateCurrentTrezzaPrice(
                order.originalPrice,
                order.origin
              )
            )
          ),
          this.format_price(
            this.calculateSalesRepTotal(
              this.calculateCurrentTrezzaPrice(
                order.originalPrice,
                order.origin
              ),
              order.origin
            )
          ),
          `${order.salesRep.firstName} ${order.salesRep.lastName}`,
          this.format_price(
            order.originalPrice -
              this.calculateSalesRepTotal(
                this.calculateCurrentTrezzaPrice(
                  order.originalPrice,
                  order.origin
                ),
                order.origin
              )
          ),
          this.format_price(
            this.calculateLunaCommission(
              this.calculateSalesRepTotal(
                this.calculateCurrentTrezzaPrice(
                  order.originalPrice,
                  order.origin
                ),
                order.origin
              ),
              this.calculateLunaCost(
                this.calculateCurrentTrezzaPrice(
                  order.originalPrice,
                  order.origin
                )
              )
            )
          ),
        ];
      });

      const data = header.concat(dataToExport);
      // Add data to the worksheet
      data.forEach((row) => {
        const excelRow = worksheet.addRow(row);

        // Apply text wrap and center alignment
        excelRow.eachCell((cell) => {
          cell.alignment = {
            vertical: "justify",
            horizontal: "left",
            wrapText: true,
          };
        });
      });

      worksheet.columns.forEach((column) => {
        column.width = 45; // Adjust the width as needed
      });

      // Set response headers for download
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `Orders Spreadsheet.xlsx`;
      link.click();
    },
    async saveChanges() {
      const selectedOrder = this.selectedOrder;
      if (selectedOrder.status.includes("Shipped")) {
        selectedOrder.shippingDetails.shippedOn = Date.now();
      }

      if (!selectedOrder.proccessedBy)
        selectedOrder.proccessedBy = Object.assign({
          id: this.$store.state.authModule.admin.id,
          fullName: `${this.$store.state.authModule.admin.firstName} ${this.$store.state.authModule.admin.lastName}`,
        });

      await setDoc(doc(db, "orders", selectedOrder.id), selectedOrder).then(
        () => {
          this.$swal({
            icon: "success",
            title: "Successful Update",
            text: `Order # ${selectedOrder.id} has been updated`,
            type: "success",
          });
        }
      );
    },
    handleInput(e, key, subKey) {
      if (subKey) {
        this.selectedOrder[key][subKey] = e.target.value;
      } else {
        this.selectedOrder[key] = e.target.value;
      }
    },
    retrieveLunasTransactionFee(order) {
      if (order.paymentType == "ACH") {
        return order.priceBreakdown.achFee;
      }

      if (order.paymentType == "Credit Card") {
        return order.priceBreakdown.ccFee;
      }

      if (order.paymentType == "Check") {
        return 0;
      }
    },
    calculateTransactionalFees(total, paymentType) {
      if (paymentType == "ACH" || paymentType == "Check") return 0;
      return total * 0.0259 + 0.49;
    },
    calculateLunaTotalPaymentReceived(fees, total) {
      return total - fees;
    },
    handlePagination(value) {
      this.pagination.currentPage = value;
    },
    viewOrderDetails(order) {
      this.selectedOrder = order;
      this.showModal = true;
    },

    addCTTW(sku) {
      const cctw = sku.split("-");
      return `${cctw[cctw.length - 1]} ct`;
    },

    calculateCurrentTrezzaPrice(currentPrice, origin) {
      if (origin === "Lab Grown") {
        return parseFloat(((currentPrice - 135) / 1.2 / 1.3 / 1.06).toFixed(2));
      } else {
        return parseFloat(
          ((currentPrice - 135) / 1.15 / 1.2 / 1.06).toFixed(2)
        );
      }
    },
    calculateCurrentUnitPrice(currentPrice) {
      return currentPrice / 1.1;
    },
    calculateLunaCost(unitPrice) {
      return unitPrice * 0.06 + unitPrice + 135;
    },
    calculateSalesRepTotal(unitPrice, origin) {
      if (origin === "Lab Grown") {
        return parseFloat(
          ((unitPrice * 0.06 + unitPrice) * 1.3 + 135).toFixed(2)
        );
      } else {
        return parseFloat(
          ((unitPrice * 0.06 + unitPrice) * 1.2 + 135).toFixed(2)
        );
      }
    },

    calculateLunaCommission(salesTotalCost, lunaTotalCost) {
      return salesTotalCost - lunaTotalCost;
    },
    //Fetch Order Methods:

    async getAllOrders() {
      const orderRef = collection(db, "orders");
      const orderQuery = query(
        orderRef,
        orderBy("purchaseOrderNumber", "desc")
      );

      const unsub = onSnapshot(
        orderQuery,
        (querySnapshot) => {
          this.orders = querySnapshot.docs.map((user) =>
            Object.assign({ id: user.id }, user.data())
          );
          this.tableData = this.orders;
        },
        (error) => {}
      );
    },

    resetTable() {
      this.pagination.currentPage = 1;
      this.searchedData = [];
      this.fetchData("start");
    },

    async fetchData(action) {
      if (this.searchedData.length) return false;
      if (action == "start") {
        try {
          const orderRef = collection(db, "orders");
          let orderQuery;
          orderQuery = query(
            orderRef,
            orderBy("createdAt", "desc"),
            endAt(0),
            limit(this.pagination.perPage)
          );

          const unsub = await onSnapshot(orderQuery, (querySnapshot) => {
            this.orders = querySnapshot.docs.map((doc) =>
              Object.assign({ id: doc.id }, doc.data())
            );
            this.tableData = this.orders;
          });
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else if (action == "next") {
        this.fetchNextData();
      } else if (action == "prev") {
        this.fetchPreviousData();
      }

      // else if (action == "per-page") {
      //   let firstDoc = this.tableData[0];
      //   firstDoc = await this.productsRef.doc(firstDoc.id).get();
      //   const querySnapshot = await this.productsRef
      //     .orderBy("createdAt", "desc") // Change to your sorting criteria
      //     .startAt(firstDoc)
      //     .limit(this.pagination.perPage)
      //     .get();

      //   this.tableData = querySnapshot.docs.map((doc) =>
      //     Object.assign({ id: doc.id }, doc.data())
      //   );
      // }
    },

    async fetchNextData() {
      const orderRef = collection(db, "orders");
      let lastDoc = this.tableData[this.tableData.length - 1];
      lastDoc = await getDoc(doc(orderRef, lastDoc.id));
      this.tableData = [];
      try {
        const orderRef = collection(db, "orders");
        let orderQuery;
        orderQuery = query(
          orderRef,
          orderBy("createdAt", "desc"),
          startAfter(lastDoc),
          limit(this.pagination.perPage)
        );

        const unsub = await onSnapshot(orderQuery, (querySnapshot) => {
          this.orders = querySnapshot.docs
            .map((doc) => Object.assign({ id: doc.id }, doc.data()))
            .reverse();
          this.tableData = this.orders;
        });
      } catch (error) {
        console.debug("Error fetching data:", error);
      }
    },

    async fetchPreviousData() {
      const orderRef = collection(db, "orders");
      let firstDoc = this.tableData[0];
      firstDoc = await getDoc(doc(orderRef, firstDoc.id));
      this.tableData = [];
      try {
        let orderQuery;
        orderQuery = query(
          orderRef,
          orderBy("createdAt", "asc"),
          startAfter(firstDoc)
        );

        const unsub = await onSnapshot(orderQuery, (querySnapshot) => {
          this.orders = querySnapshot.docs
            .map((doc) => Object.assign({ id: doc.id }, doc.data()))
            .reverse();
          this.tableData = this.orders;
        });
      } catch (error) {
        console.debug("Error fetching data:", error);
      }
    },

    //Filter Methods

    async handleFilter() {
      console.debug(this.filterBy);
      let timeFilter = false;

      let orderQuery = collection(db, "orders");

      if (
        !this.filterBy.client &&
        !this.filterBy.status &&
        !this.filterBy.rep &&
        !this.filterBy.paymentStatus &&
        !this.filterBy.startDate &&
        !this.filterBy.endDate
      ) {
        // this.resetTable();

        return false;
      }

      try {
        if (this.filterBy.startDate && this.filterBy.endDate) {
          timeFilter = true;
          let startDateObj =
            typeof this.filterBy.startDate == "object"
              ? DateTime.fromJSDate(this.filterBy.startDate)
              : DateTime.fromISO(this.filterBy.startDate);

          let endDateObj =
            typeof this.filterBy.endDate == "object"
              ? DateTime.fromJSDate(this.filterBy.endDate)
              : DateTime.fromISO(this.filterBy.endDate);

          let startDate = startDateObj
            .setZone("America/New_York")
            .startOf("day")
            .toMillis();

          let endDate = endDateObj
            .setZone("America/New_York")
            .endOf("day")
            .toMillis();

          orderQuery = query(
            orderQuery,
            orderBy("createdAt", "desc"),
            startAt(endDate),
            endAt(startDate)
          );
        }

        for (const key in this.filterBy) {
          if (Object.hasOwnProperty.call(this.filterBy, key)) {
            if (key != "startDate" && key != "endDate") {
              const element = this.filterBy[key];
              if (element) {
                if (key == "client") {
                  orderQuery = query(
                    orderQuery,
                    where("client.id", "==", element)
                  );
                }

                if (key == "status") {
                  orderQuery = query(
                    orderQuery,
                    where("status", "==", element)
                  );
                }

                if (key == "paymentStatus") {
                  orderQuery = query(
                    orderQuery,
                    where("paymentStatus", "==", element)
                  );
                }

                if (key == "rep") {
                  orderQuery = query(
                    orderQuery,
                    where("salesRep.id", "==", element)
                  );
                }
              }
            }
          }
        }

        if (timeFilter) {
          await onSnapshot(orderQuery, (querySnapshot) => {
            if (!querySnapshot.docs.length) {
              return this.$swal("Empty Search Records");
            }
            this.searchedData = querySnapshot.docs.map((d) =>
              Object.assign({ id: d.id }, d.data())
            );
          });
        } else {
          await onSnapshot(orderQuery, (querySnapshot) => {
            if (!querySnapshot.docs.length) {
              return this.$swal("Empty Search Records");
            }
            this.searchedData = querySnapshot.docs.map((d) =>
              Object.assign({ id: d.id }, d.data())
            );
          });
        }
      } catch (error) {
        console.debug(error);

        return this.$swal({
          icon: "error",
          title: "Internal Issue",
          text: "Oops there seems to be an issue please try again or contact support at info@mylunacollection.com",
          type: "error",
        });
      }
    },
    clearFilters() {
      for (const key in this.filterBy) {
        if (key === "startDate" || key === "endDate") {
          this.filterBy[key] = null;
        } else {
          this.filterBy[key] = "";
        }
      }
      this.searchVal = null;
      this.resetTable();
    },
    searchForOrder(e) {
      if (!e.target.value) {
        this.tableData = this.order;
      }
      let input = e.target.value.toLowerCase();
      const validateOrder = (order) => {
        if (
          order.orderNumber.toLowerCase().includes(input) ||
          order.purchaseOrderNumber.toLowerCase().includes(input)
        ) {
          return true;
        } else return false;
      };
      this.tableData = this.orders.filter((order) => validateOrder(order));
    },
  },
};
</script>
<style scoped>
.table-row {
  cursor: pointer;
}

.table-row:hover {
  background-color: rgba(69, 82, 93, 0.041) !important;
}

.search-bar {
  position: relative;
  width: 535px !important;
  top: 10px;
  left: 10px;
  display: flex;
}

.color-key-container {
  width: 258px;
  position: relative;
}

.color-key-dropdown {
  position: absolute;
  width: 100%;
  top: 30px;
  left: 24px;
  z-index: 999;
  background-color: white;
}

.color-status {
  width: 100%;
  text-align: center;
}

.color-status:hover {
  transform: scale(1.2);
}

.datetime-picker {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.datetime-input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  width: 200px;
  margin-bottom: 10px;
}

.datetime-input:focus {
  border-color: #007bff;
  outline: none;
}
</style>
