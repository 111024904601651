<template>
  <div class="file-slider">
    <img
      @click="enlargeImage(files[currentIndex])"
      v-if="files[currentIndex] && files[currentIndex].type.includes('image')"
      :src="files[currentIndex].url"
      :alt="files[currentIndex].name"
      class="slider-file"
      style="display: flex; justify-content: center; cursor: zoom-in"
    />
    <video
      class="slider-file file-media"
      style="display: flex; justify-content: center; cursor: zoom-in"
      @click="enlargeImage(files[currentIndex])"
      v-if="files[currentIndex] && files[currentIndex].type.includes('video')"
      :src="files[currentIndex].url"
      :alt="files[currentIndex].name"
      autoplay
      loop
    ></video>
    <button @click="prevFile" class="slider-button left-arrow">▶</button>
    <button @click="nextFile" class="slider-button right-arrow">▶</button>
    <!-- <div v-if="!dontShowThumbnails" class="thumbnails">
        <img
          v-for="(file, index) in files"
          :key="index"
          :src="file.url"
          @click="setIndex(index)"
          class="thumbnail"
          :class="{ active: files[currentIndex].url == file.url }"
        />
      </div> -->
  </div>
</template>
<script>
export default {
  name: "slide-show",
  props: {
    files: {
      type: Array,
      default: [],
      required: true,
    },
    dontShowThumbnails: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  emits: ["enlarge-image"],
  data() {
    return {
      currentIndex: 0,
      selectedImage: {},
    };
  },

  methods: {
    enlargeImage(image) {
      this.$emit("enlarge-image", image);
    },
    nextFile() {
      this.currentIndex = (this.currentIndex + 1) % this.files.length;
    },

    prevFile() {
      this.currentIndex =
        (this.currentIndex - 1 + this.files.length) % this.files.length;
    },

    setIndex(index) {
      this.currentIndex = index;
    },
  },
};
</script>

<style scoped>
.left-arrow {
  transform: rotate(180deg) !important;
}
.right-arrow {
  right: 0;
  /* position: absolute; */
}

.right-arrow,
.left-arrow {
  font-size: 14px;
  cursor: pointer;
  color: silver !important;
  width: 0.8rem;
  height: 0.8rem;
}

.right-arrow:hover,
.left-arrow:hover {
  color: black !important;
}
.file-slider {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100px;
}

.slider-file {
  width: 100%;
  height: auto;
  margin-left: 10px;
  margin-right: 10px;
}

.slider-button {
  position: absolute;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.thumbnails {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: -30px;
}

.thumbnail {
  width: 60px;
  height: 60px;
  object-fit: cover;
  cursor: pointer;
}

.thumbnail:hover {
  opacity: 0.7;
}

.thumbnail.active {
  background-color: silver;
  border: 0.5 dashed red;
}
</style>
