<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <soft-button
                class="btn-icon ms-2 export"
                size
                color="success"
                variant="outline"
                data-bs-dismiss="modal"
                @click="$router.push('/collections/new-collection')"
              >
                <span class="btn-inner--icon">
                  <i class="fas fa-table"></i>
                </span>
                <span class="btn-inner--text"> New Collection</span>
              </soft-button>
            </div>
          </div>

          <div class="card mt-4 p-3 pt-0">
            <div class="table-responsive">
              <!-- <div class="search-bar">
                <soft-input
                  id="search-input"
                  @input="searchForInventory"
                  placeholder="Search By SKU or Name"
                >
                </soft-input>
              </div> -->
              <div class="table-wrapper">
                <table id="collections-list" class="table">
                  <thead class="thead-light">
                    <tr>
                      <th>Logo</th>
                      <th>Title</th>
                      <th>SKUs</th>
                      <th>Styles</th>
                      <!-- <th>Settings</th> -->
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="collection in queriedData" :key="collection.id">
                      <td class="image-container">
                        <img
                          class="w-45 ms-3"
                          :src="collection.logo.url"
                          alt="thumbnail"
                        />
                      </td>
                      <td class="">
                        <h6 class="my-auto ms-3">
                          {{ collection.title }}
                        </h6>
                      </td>
                      <!-- <td class="">
                        <h6 class="my-auto ms-3">
                          {{
                            collection.styles
                              .map((s) => s.stockNumber)
                              .join(" ")
                          }}
                        </h6>
                      </td> -->
                      <td class="">
                        <div class="skus">
                          <div v-for="sku in collection.skus" :key="sku">
                            {{ sku }}
                          </div>
                        </div>
                      </td>

                      <td class="">
                        <div class="skus">
                          <div v-for="style in collection.styles" :key="style">
                            {{ style }}
                          </div>
                        </div>
                      </td>
                      <td class="text-sm">
                        <a
                          class="mx-3 pointer"
                          @click="viewCollection(collection.id)"
                          data-bs-toggle="tooltip"
                          data-bs-original-title="Preview collection"
                        >
                          <i class="fas fa-eye text-secondary"></i>
                        </a>

                        <!-- <a
                          class="pointer"
                          @click="archiveCollection(collection.id)"
                          data-bs-toggle="tooltip"
                          data-bs-original-title="Delete collection"
                        >
                          <i class="fas fa-trash text-secondary"></i>
                        </a> -->

                        <a
                          class="pointer"
                          @click="duplicateCollection(collection)"
                          data-bs-toggle="tooltip"
                          data-bs-original-title="Duplicate collection"
                        >
                          <i class="fas fa-clone"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="pagination-wrapper">
                <div class="page-dropdown">
                  <label for="orders">Per page: &nbsp; </label>
                  <select v-model="pagination.perPage" name="orders">
                    <option
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>
                </div>

                <pagination
                  :value="pagination.currentPage"
                  class="pagination-no-border pagination-success"
                  :per-page="pagination.perPage"
                  :total="total"
                  :from="from"
                  :to="to"
                  @input="handlePagination"
                >
                </pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import SoftButton from "@/components/SoftButton.vue";
import SoftInput from "@/components/SoftInput.vue";
import setTooltip from "@/assets/js/tooltip.js";
import { fetchDocuments, deleteDocument, addDocument } from "@/db/index";
import Modal from "@/components/Modal.vue";
import CollectionDetails from "./CollectionDetails.vue";
import Pagination from "@/components/Pagination.vue";

export default {
  name: "CollectionsList",
  components: {
    SoftButton,
    SoftInput,
    Modal,
    CollectionDetails,
    Pagination,
  },
  data() {
    return {
      collections: [],
      selectedCollection: [],
      unfilteredCollections: [],
      tableData: [],
      barcodeValue: "",
      barcode: "",
      interval: 0,
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [50, 250, 500, 1000],
        total: 0,
      },
      currentSort: "name",
      currentSortOrder: "asc",
    };
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data.
     */
    queriedData() {
      let result = this.tableData;

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.tableData.length;
    },
  },
  watch: {
    unfilteredCollections: {
      deep: true,
      handler() {
        this.tableData = this.unfilteredCollections;
      },
    },
  },
  async created() {
    this.getCollections();
  },

  beforeUnmount() {
    window.removeEventListener("keydown", this.handleBarcodeScanned);
  },
  mounted() {
    window.addEventListener("keydown", this.handleBarcodeScanned);
    if (document.getElementById("collections-list")) {
      document.querySelectorAll(".export").forEach(function (el) {
        el.addEventListener("click", function () {
          var type = el.dataset.type;

          var data = {
            type: type,
            filename: "soft-ui-" + type,
          };

          if (type === "csv") {
            data.columnDelimiter = "|";
          }

          dataTableSearch.export(data);
        });
      });
    }
    setTooltip(this.$store.state.bootstrap);
  },

  methods: {
    async getCollections() {
      this.unfilteredCollections = await fetchDocuments("collections");
    },
    closeModal() {
      var modalElement = document.getElementById("Modal");
      var bootstrapModal = new bootstrap.Modal(modalElement);
      bootstrapModal.hide();
      this.barcodeValue = "";
      this.barcode = "";
    },
    handleBarcodeScanned(e) {
      if (e.target.id != "search-input") {
        if (e.code == "Enter") {
          if (this.barcode) {
            this.barcodeValue = this.barcode;
            console.log(this.barcodeValue);
            this.barcode = "";
            const collection = this.unfilteredCollections.filter(
              (p) =>
                p.ClientSKU.includes(this.barcodeValue) ||
                p.StockNumber.includes(this.barcodeValue)
            );
            this.selectedCollection = collection[0];

            return this.openModal();
          }
        }

        if (e.key != "Shift" && e.key != "Enter") {
          this.barcode += e.key;
          // this.interval = setInterval(() => (this.barcode = ""), 20);
        }
      }
    },
    openModal() {
      var modalElement = document.getElementById("Modal");
      var bootstrapModal = new bootstrap.Modal(modalElement);
      bootstrapModal.show();
    },

    searchForInventory(e) {
      if (!e.target.value) {
        this.tableData = this.unfilteredCollections;
      }

      let input = e.target.value.toLowerCase();
      const validateInventory = (collection) => {
        if (
          collection.title.toLowerCase().includes(input) ||
          collection.Client.company.includes(input)
        )
          return true;
        else return false;
      };

      this.tableData = this.unfilteredCollections.filter((collection) =>
        validateInventory(collection)
      );
    },
    viewCollection(id) {
      this.$router.push(`/collections/${id}`);
    },
    archiveCollection(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Continue",
      }).then(async (result) => {
        if (result.value) {
          // await updateD("collections", id);
          this.$swal("Deleted!", "The data has been deleted.", "success");
          this.unfilteredCollections = await fetchDocuments("collections");
        }
      });
    },
    async duplicateCollection(collection) {
      const new_collection = JSON.parse(JSON.stringify(collection));
      delete new_collection.id;
      addDocument("collections", new_collection);
      this.unfilteredCollections = await fetchDocuments("collections");
    },
  },
};
</script>
  
  <style scoped>
.skus {
  width: 165px;
  max-height: 100px;
  overflow: auto;
}
.thead-light {
  text-align: left !important;
}
.image-container {
  width: 150px;
}
</style>
  