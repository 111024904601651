import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  getDocs,
  doc,
  addDoc,
  getDoc,
  deleteDoc,
  updateDoc,
  setDoc,
  onSnapshot,
  orderBy,
  query,
  where,
  endAt,
  startAfter,
  or,
  startAt,
  arrayUnion,
  arrayRemove,
  limit
} from "firebase/firestore";
import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  setPersistence,
  browserLocalPersistence,
  browserSessionPersistence,
  updatePassword,
  sendPasswordResetEmail,
  verifyPasswordResetCode,
  confirmPasswordReset,
} from "firebase/auth";

import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  uploadBytesResumable,
  deleteObject,
} from "firebase/storage";

import { getFunctions, httpsCallable } from "firebase/functions";
import firebaseConfig from "./firebaseConfig";

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage();
const functions = getFunctions(app);

export {
  auth,
  db,
  collection,
  getDocs,
  getDoc,
  setDoc,
  doc,
  addDoc,
  deleteDoc,
  or,
  limit,
  updateDoc,
  startAfter,
  where,
  endAt,
  startAt,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  setPersistence,
  browserLocalPersistence,
  browserSessionPersistence,
  storage,
  ref,
  uploadBytes,
  getDownloadURL,
  uploadBytesResumable,
  onSnapshot,
  orderBy,
  query,
  updatePassword,
  functions,
  httpsCallable,
  sendPasswordResetEmail,
  verifyPasswordResetCode,
  confirmPasswordReset,
  arrayUnion,
  arrayRemove,
  deleteObject,
};
