<template>
  <div class="container-fluid py-4">
    <div class="d-sm-flex justify-content-between">
      <!-- <div>
        <soft-button color="success" variant="gradient">New client</soft-button>
      </div> -->
      <div class="d-flex">
        <soft-button
          class="btn-icon ms-2 export"
          size
          color="success"
          variant="outline"
          @click="$router.push('/users/new-user')"
        >
          <span class="btn-inner--icon">
            <i class="ni ni-single-02"></i>
          </span>
          <span class="btn-inner--text"> New User</span>
        </soft-button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card mt-4">
          <div class="table-responsive">
            <div class="search-bar">
              <soft-input
                @input="searchUser"
                placeholder="Search By Name or Email"
              >
              </soft-input>
            </div>
            <div class="table-wrapper">
              <table class="table align-items-center mb-0">
                <thead>
                  <tr>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Name
                    </th>
                    <th
                      class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Access
                    </th>

                    <th
                      class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Email
                    </th>
                    <th
                      class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Created On
                    </th>
                    <!-- <th
                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    Id
                  </th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="user in queriedData"
                    :key="user.id"
                    class="table-row"
                    data-bs-toggle="modal"
                    data-bs-target="#Modal"
                    @click="viewUserDetails(user)"
                  >
                    <td>
                      <div class="d-flex px-2 py-1">
                        <!-- <soft-avatar
                  :img="img1"
                  class="me-3"
                  alt="avatar image"
                  border-radius="lg"
                /> -->
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="mb-0 text-sm">
                            {{ `${user.firstName} ${user.lastName}` }}
                          </h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <p class="text-sm text-secondary mb-0">
                        {{
                          `${user.client ? user.client.name : ""} ${user.role}`
                        }}
                      </p>
                    </td>

                    <td class="align-middle text-center text-sm">
                      <p class="text-secondary mb-0 text-sm">
                        {{ user.email }}
                      </p>
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-sm">{{
                        format_date_time(user.createdAt)
                      }}</span>
                    </td>
                    <!-- <td class="align-middle text-center">
                    <span class="text-secondary text-sm">{{ user.id }}</span>
                  </td> -->
                  </tr>
                </tbody>
              </table>
            </div>
            <nav aria-label="Page navigation">
              <div class="pagination-wrapper">
                <div class="page-dropdown">
                  <label for="orders">Per page: &nbsp; </label>
                  <select v-model="pagination.perPage" name="orders">
                    <option
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>
                </div>

                <pagination
                  :value="pagination.currentPage"
                  class="pagination-no-border pagination-success"
                  :per-page="pagination.perPage"
                  :total="total"
                  :from="from"
                  :to="to"
                  @input="handlePagination"
                >
                </pagination>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
    <modal
      :styleFormat="{ width: '1000px', marginLeft: '-10.75rem' }"
      @close="selectedUser = {}"
    >
      <template v-slot:title>
        <h2>{{ selectedUser.firstName }} Details</h2>
      </template>
      <template v-slot:body>
        <div class="order-details-wrapper">
          <UserDetails
            @save-changes="saveChanges"
            @handle-input="handleInput"
            :user="selectedUser"
          />
        </div>
      </template>
      <template v-slot:footer>
        <!-- <soft-button
          color="secondary"
          variant="outline"
          class="mb-0 ms-auto"
          type="button"
          name="button"
          @click-btn="deactivateUser"
          >Deactivate</soft-button
        > -->
        <soft-button
          color="danger"
          variant="gradient"
          class="mb-3 ms-2"
          type="button"
          name="button"
          @click-btn="deleteUser"
          >Delete Account</soft-button
        >
      </template>
    </modal>
  </div>
</template>

<script>
import SoftAvatar from "@/components/SoftAvatar.vue";
import SoftButton from "@/components/SoftButton.vue";
import SoftCheckbox from "@/components/SoftCheckbox.vue";
import SoftInput from "@/components/SoftInput.vue";
import Modal from "@/components/Modal.vue";
import Pagination from "@/components/Pagination.vue";
import UserDetails from "./UserDetails.vue";
import {
  collection,
  db,
  getDocs,
  deleteDoc,
  functions,
  httpsCallable,
  doc,
  setDoc,
  query,
  where,
} from "@/config/firebaseInit.js";
import bootstrapMin from "bootstrap/dist/js/bootstrap.min";
import { mapState } from "vuex";
export default {
  name: "Table",
  components: {
    SoftAvatar,
    SoftButton,
    SoftCheckbox,
    SoftInput,
    Modal,
    UserDetails,
    Pagination,
  },
  data() {
    return {
      users: [],
      tableData: [],
      selectedUser: {},
      currentSort: "name",
      currentSortOrder: "asc",
      updateUserAuth: httpsCallable(functions, "updateUser"),
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [50, 250, 500, 1000],
        total: 0,
      },
    };
  },
  computed: {
    ...mapState("authModule", {
      currentUser: (state) => {
        if (state.admin.id) {
          return state.admin;
        } else {
          return JSON.parse(window.localStorage.getItem("admin_data"));
        }
      },
      isSuperAdmin: (state) => {
        return state.isSuperAdmin;
      },
      isClient: (state) => {
        return state.isClient;
      },
      isStore: (state) => {
        return state.isStore;
      },
      isSalesRep: (state) => {
        return state.isSalesRep;
      },

      isEmployee: (state) => {
        return state.isEmployee;
      },
    }),
    /***
     * Returns a page from the searched data or the whole data.
     */
    queriedData() {
      let result = this.tableData;

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.tableData.length;
    },
  },
  created() {
    if (this.isEmployee || this.isSuperAdmin || this.isClient) {
      this.getAllUsers();
    } else {
      this.$router.push("/orders");
    }
  },
  methods: {
    handlePagination(value) {
      this.pagination.currentPage = value;
    },
    deactivateUser() {},
    deleteUser() {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Continue",
      }).then((result) => {
        if (result.value) {
          // this.showModal = false;
          const selectedUser = this.selectedUser;
          deleteDoc(doc(db, "users", selectedUser.id))
            .then(() => {
              this.$swal(
                "Deleted!",
                "The data has been deleted.",
                "success"
              ).then(() => {
                this.getAllUsers();
                const modalEl = document.getElementById("Modal");
                const modal = bootstrapMin.Modal.getInstance(modalEl);
                modal.hide();
                document.querySelector("body").classList.remove("modal-open");
                document.querySelector(".modal-backdrop").remove();
                this.selectedUser = {};
              });
            })
            .catch((err) => {
              console.debug(err);
            });
        }
      });
    },
    async saveChanges() {
      const selectedUser = this.selectedUser;

      this.updateUserAuth({
        uid: selectedUser.id,
        payload: {
          email: selectedUser.email,
          displayName: `${selectedUser.firstName} ${selectedUser.lastName}`,
        },
      })
        .then(() => {
          return setDoc(doc(db, "users", selectedUser.id), selectedUser);
        })
        .then(() => {
          this.$swal({
            icon: "success",
            title: "Successful Update",
            text: `User ${selectedUser.firstName} ${selectedUser.lastName} has been updated`,
            type: "success",
          });
        })
        .catch((err) => {
          console.log(err);
          this.$swal({
            icon: "warning",
            title: "Unsuccessful Update",
            text: `Something Went Wrong Please try again or contact support at info@mylunacollection.com`,
            type: "success",
          });
        });
    },
    handleInput() {},
    viewUserDetails(user) {
      this.selectedUser = user;
      this.showModal = true;
    },
    searchUser(e) {
      let inputVal = e.target.value;
      if (!inputVal) {
        this.tableData = this.users;
        return;
      }
      inputVal = inputVal.toLowerCase();
      this.tableData = this.users.filter(
        (u) =>
          u.firstName.toLowerCase().includes(inputVal) ||
          u.email.toLowerCase().includes(inputVal)
      );
    },
    async getAllUsers() {
      let queryRef = collection(db, "users");
      if (this.$store.state.authModule.isClient) {
        queryRef = query(
          collection(db, "users"),
          where("admin_access.store", "==", true),
          where("client.id", "==", this.$store.state.authModule.client.id)
        );
      }
      if (this.$store.state.authModule.isFactory) {
        queryRef = query(
          collection(db, "users"),
          where("admin_access.factory", "==", true)
        );
      } else if (this.$store.state.authModule.isStore) {
        queryRef = query(
          collection(db, "users"),
          where("admin_access.store", "==", true),
          where(
            "client.id",
            "==",
            this.$store.state.authModule.admin.client.id
          ),
          where("storeId", "==", this.$store.state.authModule.store.id)
        );
      }

      const users = await getDocs(queryRef);
      this.users = users.docs.map((user) =>
        Object.assign({ id: user.id }, user.data())
      );
      this.tableData = this.users;
    },
  },
};
</script>
