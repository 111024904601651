<template>
  <div ref="reportContent" id="reportContent">
    <h1>TTC USA Diamonds</h1>
    <h2>Sales by Company Report</h2>
    <p>Date: {{ reportDate }}</p>
    <div
      v-for="company in companies"
      :key="company.name"
      class="company-section"
    >
      <h2 class="company-name">{{ company.name }}</h2>
      <table>
        <thead>
          <tr>
            <th>Date/Time</th>
            <th>Order #</th>
            <th>PO #</th>
            <th>Sales Rep</th>
            <th>Store ID</th>
            <th>Consumer</th>
            <th>Item Type</th>
            <th>SKU</th>
            <th>Qty</th>
            <th>Unit Cost</th>
            <th>Total Profit %</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in company.items" :key="item.sku">
            <td>{{ format_date_time(item.createdAt) }}</td>
            <td>{{ item.id }}</td>
            <td>{{ item.purchaseOrderNumber }}</td>
            <td>
              {{ `${item.salesRep.firstName} ${item.salesRep.lastName}` }}
            </td>
            <td>{{ item.customer.storeId }}</td>
            <td>
              {{ `${item.customer.firstName} ${item.customer.lastName}` }}
            </td>
            <td>{{ item.jewelryType }}</td>
            <td>{{ item.sku }}</td>
            <td>{{ item.quantity }}</td>
            <td>{{ format_price(item.totalCost) }}</td>
            <td>{{ format_price(item.totalCost) }}</td>
          </tr>
          <tr>
            <td colspan="11" class="subtotal">
              Sub Total: {{ format_price(company.totalProfit) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <p class="footer">
      Luna Collection LLC - Copyright ©2024 TTC USA DIAMONDS LLC
    </p>
  </div>
  <!-- <button @click="printPDF">Print PDF</button> -->
</template>
  
  <script>
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export default {
  props: {
    orders: {
      type: Array,
      default: [],
    },
    reportDate: {
      type: String,
      default: "7/1/2024 - 7/2/2024",
    },
  },
  computed: {
    companies() {
      let clients = {};
      this.orders.forEach((o) => {
        if (o.client.name) {
          if (!clients[o.client.name]) {
            clients[o.client.name] = {
              name: o.client.name,
              items: [],
              totalProfit: 0,
            };
          } else {
            clients[o.client.name].items.push(o);
            clients[o.client.name].totalProfit += o.totalCost;
          }
        }
      });

      console.debug(clients);

      return clients;
    },
  },
  data() {
    return {};
  },
  methods: {
    async exportReport() {
      const reportContent = this.$refs.reportContent;
      const canvas = await html2canvas(reportContent);
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("report.pdf");
    },
  },
};
</script>
  
  <style scoped>
#reportContent {
  width: 100%;
  min-height: 297mm;
  margin: 0 auto;
  padding: 20mm;
  background: white;
  font-size: 12px;
  font-family: Arial, sans-serif;
}

h1,
h2 {
  text-align: center;
  margin: 0;
}

h1 {
  font-size: 24px;
  margin-bottom: 5px;
}

h2 {
  font-size: 18px;
  margin-bottom: 20px;
}

p {
  margin: 0;
  padding: 5px 0;
}

.company-section {
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;
}

table,
th,
td {
  border: 1px solid black;
}

th,
td {
  padding: 8px;
  text-align: left;
}

.company-name {
  font-weight: bold;
  background-color: #f0f0f0;
  text-align: left;
  padding: 8px;
  border: none;
}

.subtotal {
  font-weight: bold;
  text-align: right;
  padding: 8px;
  border: none;
  background-color: #f0f0f0;
}

.footer {
  text-align: center;
  margin-top: 20px;
  font-size: 10px;
}
</style>
  