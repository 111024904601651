<template>
  <hr class="horizontal dark mt-0 mb-4" />
  <div class="card-body p-3 pt-0">
    <div class="row">
      <div class="multisteps-form__form mb-5">
        <div class="multisteps-form__content">
          <h6 class="mb-3 p-3 pt-0 font-weight-bolder">Address Details</h6>
          <div class="multisteps-form__content">
            <div class="row mt-3">
              <div class="col">
                <label>Address 1 </label>
                <autocomplete-input
                  @selected="$emit('update-info', $event, 'address')"
                  :addressOne="address.addressOne"
                  :disabled="!editInit"
                  required
                ></autocomplete-input>
              </div>
              <div class="col-12 col-sm-6 mt-3 mt-sm-0">
                <label>Address 2 </label>

                <div class="form-group">
                  <div class="input-group">
                    <input
                      id="address2"
                      type="text"
                      class="form-control multisteps-form__input"
                      name="text"
                      :disabled="!editInit"
                      v-model="address.addressTwo"
                      placeholder="eg. 1504, suit 2"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-12 col-sm-6">
                <label>City </label>
                <div class="form-group">
                  <div class="input-group">
                    <input
                      id="city"
                      type="text"
                      class="form-control multisteps-form__input"
                      name="text"
                      :disabled="!editInit"
                      v-model="address.city"
                      placeholder="eg. New York"
                      required
                    />
                  </div>
                </div>
              </div>
              <div class="col-6 col-sm-3 mt-3 mt-sm-0">
                <label>State </label>

                <div class="input-group">
                  <input
                    id="state"
                    type="text"
                    :disabled="!editInit"
                    class="form-control multisteps-form__input"
                    name="text"
                    placeholder="State"
                    v-model="address.state"
                    required
                  />
                </div>
              </div>
              <div class="col-6 col-sm-3 mt-3 mt-sm-0">
                <label>Zip </label>

                <div class="form-group">
                  <div class="input-group">
                    <input
                      id="city"
                      type="text"
                      :disabled="!editInit"
                      class="form-control multisteps-form__input"
                      name="text"
                      v-model="address.zipCode"
                      placeholder="7 digits"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="button-row">
        <soft-button
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto mb-0 js-btn-next"
          @click-btn="editInit = !editInit"
          >{{ editInit ? "Cancel" : "Edit" }}</soft-button
        >
        <soft-button
          v-if="editInit"
          type="button"
          color="primary"
          variant="gradient"
          class="ms-auto mb-0 js-btn-next"
          @click-btn="
            () => {
              $emit('save-changes', address), (editInit = false);
            }
          "
          >Save changes</soft-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import AutocompleteInput from "@/components/AutocompleteInput.vue";

export default {
  name: "display-info",
  components: {
    SoftButton,
    AutocompleteInput,
  },
  props: {
    rep: {
      type: Object,
      default() {
        return Object;
      },
    },
  },
  emits: ["update-info", "save-changes"],
  data() {
    return {
      editInit: false,
      address: {},
    };
  },
  created() {
    this.address = this.rep.address;
  },
};
</script>

<style>
</style>