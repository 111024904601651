<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    viewBox="0 0 64 64"
  >
    <title>Warehouse</title>
    <!-- Warehouse Building -->
    <rect x="8" y="16" width="48" height="40" fill="#CCCCCC" />
    <rect x="8" y="16" width="48" height="8" fill="#999999" />
    <rect x="8" y="40" width="48" height="8" fill="#999999" />
    <rect x="10" y="18" width="44" height="36" fill="#E0E0E0" />

    <!-- Roof -->
    <path d="M4 16 L36 4 L60 16" fill="#666666" />

    <!-- Door -->
    <rect x="26" y="40" width="12" height="20" fill="#444444" />
    <rect x="28" y="48" width="8" height="12" fill="#999999" />

    <!-- Windows -->
    <rect x="12" y="22" width="8" height="8" fill="#333333" />
    <rect x="12" y="34" width="8" height="8" fill="#333333" />
    <rect x="44" y="22" width="8" height="8" fill="#333333" />
    <rect x="44" y="34" width="8" height="8" fill="#333333" />
  </svg>
</template>

