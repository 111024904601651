<template>
  <hr class="horizontal dark mt-0 mb-4" />
  <div class="card-body p-3 pt-0">
    <div class="row">
      <div class="multisteps-form__form mb-5">
        <div class="multisteps-form__content">
          <h6 class="mb-3 p-3 pt-0 font-weight-bolder">Address Details</h6>
          <div class="multisteps-form__content">
            <div class="row mt-3">
              <div class="col-12 col-sm-6">
                <label>Address 1 </label>

                <div class="form-group">
                  <div class="input-group">
                    <input
                      id="address1"
                      type="text"
                      class="form-control multisteps-form__input"
                      name="text"
                      :disabled="!editInit"
                      v-model="address.addressOne"
                      placeholder="eg. Street 111"
                      required
                    />
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 mt-3 mt-sm-0">
                <label>Address 2 </label>

                <div class="form-group">
                  <div class="input-group">
                    <input
                      id="address2"
                      type="text"
                      class="form-control multisteps-form__input"
                      name="text"
                      :disabled="!editInit"
                      v-model="address.addressTwo"
                      placeholder="eg. 1504, suit 2"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-12 col-sm-6">
                <label>City </label>
                <div class="form-group">
                  <div class="input-group">
                    <input
                      id="city"
                      type="text"
                      class="form-control multisteps-form__input"
                      name="text"
                      :disabled="!editInit"
                      v-model="address.city"
                      placeholder="eg. New York"
                      required
                    />
                  </div>
                </div>
              </div>
              <div class="col-6 col-sm-3 mt-3 mt-sm-0">
                <label>State </label>
                <select
                  :disabled="!editInit"
                  v-model="address.state"
                  id="choices-state"
                  class="multisteps-form__select form-control"
                  name="choices-state"
                >
                  <option selected="" disabled>...</option>
                  <option v-for="state in states" :key="state">
                    {{ state }}
                  </option>
                </select>
              </div>
              <div class="col-6 col-sm-3 mt-3 mt-sm-0">
                <label>Zip </label>

                <div class="form-group">
                  <div class="input-group">
                    <input
                      id="city"
                      type="text"
                      :disabled="!editInit"
                      class="form-control multisteps-form__input"
                      name="text"
                      v-model="address.zipCode"
                      placeholder="7 digits"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="button-row">
        <soft-button
          v-if="
            $store.state.authModule.isClient ||
            $store.state.authModule.isSuperAdmin ||
            $store.state.authModule.isStore
          "
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto mb-0 js-btn-next"
          @click-btn="editInit = !editInit"
          >{{ editInit ? "Cancel" : "Edit" }}</soft-button
        >
        <soft-button
          v-if="editInit"
          type="button"
          color="primary"
          variant="gradient"
          class="ms-auto mb-0 js-btn-next"
          @click-btn="
            () => {
              $emit('update', address), (editInit = false);
            }
          "
          >Save changes</soft-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
export default {
  name: "display-info",
  components: {
    SoftButton,
  },
  props: {
    store: {
      type: Object,
      default() {
        return Object;
      },
    },
  },
  emits: ["update"],
  data() {
    return {
      editInit: false,
      address: {},
      states: [
        "Alabama",
        "Alaska",
        "Arizona",
        "Arkansas",
        "California",
        "Colorado",
        "Connecticut",
        "Delaware",
        "Florida",
        "Georgia",
        "Hawaii",
        "Idaho",
        "Illinois",
        "Indiana",
        "Iowa",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Maine",
        "Maryland",
        "Massachusetts",
        "Michigan",
        "Minnesota",
        "Mississippi",
        "Missouri",
        "Montana",
        "Nebraska",
        "Nevada",
        "New Hampshire",
        "New Jersey",
        "New Mexico",
        "New York",
        "North Carolina",
        "North Dakota",
        "Ohio",
        "Oklahoma",
        "Oregon",
        "Pennsylvania",
        "Rhode Island",
        "South Carolina",
        "South Dakota",
        "Tennessee",
        "Texas",
        "Utah",
        "Vermont",
        "Virginia",
        "Washington",
        "West Virginia",
        "Wisconsin",
        "Wyoming",
      ],
    };
  },
  created() {
    this.address = this.store.address;
  },
};
</script>

<style>
</style>