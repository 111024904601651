<template>
  <div class="container-fluid py-6 px-6 card bg-white" data-animation="FadeIn">
    <soft-button
      type="button"
      color="success"
      variant="gradient"
      class="ms-auto mb-0 js-btn-next"
      @click-btn="saveCollection"
      >Save
    </soft-button>
    <h5 class="font-weight-bolder">Collection Details</h5>
    <div class="multisteps-form__content">
      <div class="row">
        <div class="mt-1 row">
          <div class="col-12 col-sm-6">
            <label>Descriptions <span class="required">*</span></label>
            <div class="text-editor">
              <textarea
                v-model="collection.descriptions"
                placeholder="Write a description about this collection..."
              ></textarea>
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <div class="row">
              <div class="col-6">
                <label>Title <span class="required">*</span></label>
                <input
                  class="form-control"
                  v-model="collection.title"
                  type="text"
                  name=""
                  id=""
                />
              </div>
              <div class="col-3">
                <label>
                  TTC Commission (%)<span class="required">*</span></label
                >
                <input
                  v-model="collection.dis_commission"
                  class="multisteps-form__input form-control"
                  type="number"
                  min="10"
                  placeholder="99.00"
                />
              </div>
              <div class="col-3">
                <label>
                  Factory Commission (%)<span class="required">*</span></label
                >
                <input
                  v-model="collection.factory_commission"
                  class="multisteps-form__input form-control"
                  type="number"
                  min="10"
                  placeholder="99.00"
                />
              </div>
              <div class="col-3">
                <label> Shipping Cost<span class="required">*</span></label>
                <input
                  v-model="collection.shippingCost"
                  class="multisteps-form__input form-control"
                  type="number"
                  min="0"
                  disabled
                  placeholder="150"
                />
              </div>
            </div>
            <div class="col-12">
              <label>Logo <span class="required">*</span></label>
              <Single
                @upload="handleImageUpload($event, collection, 'logo')"
                @remove="
                  handleImageRemoval(
                    collection.logo,
                    collection,
                    'logo',
                    'single'
                  )
                "
                :media="collection.logo"
                media_type="images"
                :useCustomPath="true"
                :path="`collection/${collection.title}/logo`"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <ProductDataTable
      @handle-edit="handleItemEdit"
      @handle-delete="handleDeleteItem"
      :tableData="collection.products"
    />
  </div>
  <modal
    @close="(showModal = false), (selectedItem = {})"
    :showSaveBtn="false"
    :styleFormat="{ width: '1500px', marginLeft: '-25.75rem' }"
  >
    <template v-slot:body>
      <div class="col-lg-12 tab-content">
        <div class="card mb-4">
          <div class="item-wrapper">
            <div class="multisteps-form">
              <div class="row">
                <div class="mx-auto mt-4">
                  <div class="multisteps-form__progress">
                    <button
                      class="multisteps-form__progress-btn js-active"
                      type="button"
                      style="color: green"
                      title="Collection Setup"
                      :class="activeStep == 0 ? activeClass : ''"
                      @click="activeStep = 0"
                    >
                      <span style="color: black">1. Setup</span>
                    </button>
                    <button
                      class="multisteps-form__progress-btn"
                      type="button"
                      title="Media"
                      :class="activeStep == 1 ? activeClass : ''"
                      @click="activeStep = 1"
                    >
                      <span style="color: black"> 2. Pricing</span>
                    </button>

                    <button
                      class="multisteps-form__progress-btn"
                      type="button"
                      title="Pricing"
                      :class="activeStep == 2 ? activeClass : ''"
                      @click="activeStep = 2"
                    >
                      <span style="color: black"> 3. Media</span>
                    </button>
                  </div>
                </div>
              </div>
              <!--forms-->
              <div class="row">
                <div class="m-auto col-12 col-lg-11">
                  <div class="mb-8 multisteps-form__form">
                    <!--Collection Setup panel-->
                    <div
                      :class="activeStep === 0 ? activeClass : ''"
                      class="p-3 mt-5 bg-white card border-radius-xl"
                      data-animation="FadeIn"
                      v-if="activeStep === 0"
                    >
                      <div class="multisteps-form__content">
                        <div class="row">
                          <div class="col-sm-6">
                            <label class="mt-2"
                              >Descriptions
                              <span class="required">*</span></label
                            >
                            <div class="text-editor">
                              <textarea
                                v-model="itemAttributes.descriptions"
                                placeholder="Write a descriptions about this Item..."
                              ></textarea>
                            </div>
                          </div>
                          <div class="mt-9 col-sm-6 mt-sm-0">
                            <div class="mt-1 row">
                              <div class="col-5 col-sm-4">
                                <label
                                  >Jewelry Type
                                  <span class="required">*</span></label
                                >
                                <v-select
                                  v-model="itemAttributes.type"
                                  :options="jewelryTypes"
                                  :closeOnSelect="false"
                                  class="form-control"
                                  label="type"
                                ></v-select>
                              </div>
                              <div class="col-5 col-sm-4">
                                <label
                                  >Styles <span class="required">*</span></label
                                >
                                <v-select
                                  v-model="itemAttributes.style"
                                  :options="styles[itemAttributes.type]"
                                  :closeOnSelect="false"
                                  class="form-control"
                                  label="name"
                                ></v-select>
                              </div>

                              <div class="col-5 col-sm-4">
                                <label
                                  >SKU <span class="required">*</span></label
                                >
                                <input
                                  v-model="itemAttributes.sku"
                                  class="multisteps-form__input form-control"
                                  type="text"
                                />
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-12 col-sm-6">
                                <label
                                  >Select Settings
                                  <span class="required">*</span>
                                </label>
                                <v-select
                                  v-model="itemAttributes.setting"
                                  :options="jewelry_settings"
                                  :closeOnSelect="false"
                                  class="form-control"
                                  label="name"
                                ></v-select>
                              </div>
                              <div class="col-12 col-sm-6">
                                <label
                                  >Diamond Shapes
                                  <span class="required">*</span></label
                                >
                                <v-select
                                  v-model="itemAttributes.shape"
                                  :options="shapeOptions"
                                  :closeOnSelect="false"
                                  class="form-control"
                                  label="name"
                                ></v-select>
                              </div>

                              <div class="col-12 col-sm-6">
                                <label
                                  >Metals <span class="required">*</span>
                                  <span class="create-wrapper create-btn"
                                    >View Live Market</span
                                  >
                                </label>

                                <v-select
                                  @option:selected="
                                    selectedOption(
                                      'metalOptions',
                                      $event,
                                      'remove'
                                    )
                                  "
                                  @option:deselected="
                                    selectedOption(
                                      'metalOptions',
                                      $event,
                                      'add'
                                    )
                                  "
                                  v-model="itemAttributes.metals"
                                  :options="metalOptions"
                                  multiple
                                  :closeOnSelect="false"
                                  class="form-control"
                                  label="name"
                                ></v-select>
                              </div>

                              <div class="col-12 col-sm-6">
                                <label
                                  >Select Grams
                                  <span class="required">*</span>
                                </label>
                                <v-select
                                  @option:selected="
                                    selectedOption(
                                      'gramOptions',
                                      $event,
                                      'remove'
                                    )
                                  "
                                  @option:deselected="
                                    selectedOption('gramOptions', $event, 'add')
                                  "
                                  v-model="itemAttributes.grams"
                                  :options="gramOptions"
                                  multiple
                                  :closeOnSelect="false"
                                  class="form-control"
                                  label="gram"
                                ></v-select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="button-row d-flex mt-4">
                          <soft-button
                            type="button"
                            color="dark"
                            variant="gradient"
                            class="ms-auto mb-0 js-btn-next"
                            @click-btn="nextStep"
                            >Next Step
                          </soft-button>
                        </div>
                      </div>
                    </div>

                    <!-- Pricing panel -->
                    <div
                      class=""
                      v-if="activeStep === 1"
                      :class="activeStep === 1 ? activeClass : ''"
                    >
                      <div class="mt-3 multisteps-form__content">
                        <div class="row">
                          <!-- <div class="col-3">
                            <label>
                              Starting Price<span class="required"
                                >*</span
                              ></label
                            >
                            <input
                              v-model="itemAttributes.basePrice"
                              class="multisteps-form__input form-control"
                              type="number"
                              min="0"
                              placeholder="99.00"
                            />
                          </div> -->
                          <!-- <div class="col-3">
                            <label>
                              Labor Cost<span class="required">*</span></label
                            >
                            <input
                              v-model="itemAttributes.laborCost"
                              class="multisteps-form__input form-control"
                              type="number"
                              min="0"
                              placeholder="99.00"
                            />
                          </div> -->
                          <!-- <div class="col-3">
                            <label
                              >Shape Price <span class="required">*</span> -
                              {{ itemAttributes.shape.name }}</label
                            >
                            <input
                              v-model="itemAttributes.shape.price"
                              class="multisteps-form__input form-control"
                              type="number"
                              placeholder="$"
                            />
                          </div> -->
                        </div>
                        <div class="row">
                          <div class="col-6">
                            <div
                              v-if="itemAttributes.type == 'Ring'"
                              class="mt-1 row"
                            >
                              <div class="col-12 col-sm-6">
                                <div class="row">
                                  <div class="col-4">
                                    <label
                                      >Size
                                      <span class="required">*</span></label
                                    >
                                    <input
                                      class="form-control"
                                      type="number"
                                      min="4.5"
                                      v-model="measurementValue"
                                      name=""
                                      max="15"
                                      step="0.5"
                                      id=""
                                    />
                                  </div>

                                  <div class="col-5">
                                    <label
                                      >Metal Weight
                                      <span class="required">*</span></label
                                    >
                                    <input
                                      v-model="measurementWeight"
                                      class="multisteps-form__input form-control"
                                      type="number"
                                      min="0"
                                      placeholder="$"
                                    />
                                  </div>

                                  <div
                                    style="
                                      position: relative;
                                      height: 44px;
                                      top: 28px;
                                    "
                                    class="col-3"
                                  >
                                    <soft-button
                                      type="button"
                                      color="light"
                                      variant="gradient"
                                      class="js-btn-prev"
                                      @click="handleMeasurements"
                                    >
                                      <i class="fas fa-solid fa-plus"></i>
                                    </soft-button>
                                  </div>
                                </div>
                                <div class="list-values">
                                  <div
                                    v-for="(
                                      size, index
                                    ) in itemAttributes.sizes"
                                    :key="index"
                                    class="values"
                                  >
                                    <div>Size: {{ size.measurement }}</div>
                                    <div>Weight: {{ size.weight }}</div>
                                    <div
                                      @click="
                                        handleDelete(
                                          'sizes',
                                          size,
                                          'measurement'
                                        )
                                      "
                                      class="delete"
                                    >
                                      <i
                                        style="color: red; cursor: pointer"
                                        class="fas fa-minus-circle"
                                      ></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-12 col-sm-6">
                                <div class="row">
                                  <div class="col-4">
                                    <label
                                      >Diamond Carat
                                      <span class="required">*</span></label
                                    >
                                    <input
                                      class="form-control"
                                      type="number"
                                      min="4.5"
                                      v-model="caratValue"
                                      name=""
                                      max="15"
                                      step="0.5"
                                      id=""
                                    />
                                  </div>

                                  <div class="col-4">
                                    <label
                                      >Diamond Rate
                                      <span class="required">*</span></label
                                    >
                                    <input
                                      v-model="diamondRate"
                                      class="multisteps-form__input form-control"
                                      type="number"
                                      min="0"
                                      placeholder="$"
                                    />
                                  </div>

                                  <div
                                    style="
                                      position: relative;
                                      height: 44px;
                                      top: 28px;
                                    "
                                    class="col-3"
                                  >
                                    <soft-button
                                      type="button"
                                      color="light"
                                      variant="gradient"
                                      class="js-btn-prev"
                                      @click="handleCaratWeight"
                                    >
                                      <i class="fas fa-solid fa-plus"></i>
                                    </soft-button>
                                  </div>
                                </div>
                                <div class="list-values">
                                  <div
                                    v-for="(
                                      carat, index
                                    ) in itemAttributes.caratWeights"
                                    :key="index"
                                    class="values"
                                  >
                                    <div>Carat: {{ carat.weight }}</div>
                                    <div>Rate: ${{ carat.rate_price }}</div>
                                    <div
                                      @click="
                                        handleDelete(
                                          'caratWeights',
                                          carat,
                                          'weight'
                                        )
                                      "
                                      class="delete"
                                    >
                                      <i
                                        style="color: red; cursor: pointer"
                                        class="fas fa-minus-circle"
                                      ></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-6">
                            <div class="row">
                              <h5>Metal Grams Price per size</h5>
                            </div>
                            <div
                              style="
                                height: auto;
                                max-height: 300px;
                                border: 0.5px dashed black;
                                margin-top: 5px;
                                overflow: auto;
                              "
                              class="row"
                            >
                              <div
                                v-for="metal in itemAttributes.grams"
                                :key="metal.gram"
                                class="col-3"
                              >
                                <label>{{ metal.gram }} - Price</label>
                                <!-- <input
                                  :value="handleMetalPricePerWeight"
                                  class="multisteps-form__input form-control"
                                  disabled
                                  placeholder="$"
                                /> -->
                                <div v-if="liveMetalData['gold']">
                                  $
                                  {{
                                    liveMetalData["gold"][
                                      `price_gram_${metal.gram.toLowerCase()}`
                                    ]
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="button-row d-flex mt-4">
                        <soft-button
                          type="button"
                          color="light"
                          variant="gradient"
                          class="js-btn-prev"
                          @click="prevStep"
                          >Prev</soft-button
                        >
                        <soft-button
                          type="button"
                          color="dark"
                          variant="gradient"
                          class="ms-auto mb-0 js-btn-next"
                          @click-btn="nextStep"
                          >Next Step
                        </soft-button>
                      </div>
                    </div>

                    <!-- Media Panel -->
                    <div
                      v-if="activeStep === 2"
                      :class="activeStep === 2 ? activeClass : ''"
                    >
                      <div>
                        <label for=""
                          >Upload Image <span class="required">*</span></label
                        >
                        <CollectionMultipleDrag
                          @uploaded-media="
                            handleImageUpload($event, itemAttributes, 'images')
                          "
                          @remove-media="
                            handleImageRemoval(
                              $event,
                              itemAttributes,
                              'images',
                              'mult'
                            )
                          "
                          :media="itemAttributes.images"
                          media_type="images"
                          :useCustomPath="true"
                          :path="`collection/${collection.title}`"
                        />
                      </div>
                      <div class="button-row d-flex mt-4">
                        <soft-button
                          type="button"
                          color="light"
                          variant="gradient"
                          class="js-btn-prev"
                          @click="prevStep"
                          >Prev</soft-button
                        >
                        <soft-button
                          type="button"
                          color="success"
                          variant="gradient"
                          class="ms-auto mb-0 js-btn-next"
                          data-bs-dismiss="modal"
                          @click-btn="addProductToCollection"
                          >Finish
                        </soft-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import Single from "@/components/Upload/Single.vue";
import CollectionMultipleDrag from "@/components/Upload/CollectionMultipleDrag.vue";
import ProductDataTable from "./components/ProductDataTable.vue";
import Modal from "@/components/Modal.vue";

import {
  fetchDocuments,
  fetchDocument,
  removeMediaFromStorage,
  setDocument,
} from "@/db/index";

export default {
  name: "NewCollections",
  components: {
    SoftButton,
    Single,
    CollectionMultipleDrag,
    ProductDataTable,
    Modal,
  },
  data() {
    return {
      activeClass: "js-active position-relative",
      jewelryOptions: [],
      clients: [],
      shapeOptions: [],
      selectedJewelries: [],
      selectedItem: null,
      certificateFee: 15,
      measurementWeight: 0,
      measurementValue: 0,
      diamondRate: 0,
      caratValue: 0,
      metalOptions: [
        {
          name: "Gold",
        },

        {
          name: "Platinum",
        },
      ],
      //  metalOptions: ["Gold", "Platinum"],
      metalColors: [
        {
          name: "White Gold",
          color: "#f3eac4",
        },
        {
          name: "Yellow Gold",
          color: "#e5d9a5",
        },
        {
          name: "Rose Gold",
          color: "#ffcaca",
        },
        {
          name: "Platinum",
          color: "#eaeaec",
        },
        // {
        //   name: "Silver",
        //   color: "#C0C0C0",
        //   price: 0,
        // },
      ],
      gramOptions: [],
      selectedTab: null,
      showJewelryDropdown: false,
      completedSteps: {
        setup: false,
        media: false,
        pricing: false,
      },
      collection: {
        title: null,
        dis_commission: 0,
        factory_commission: 0,
        shippingCost: 150,
        logo: {},
        products: [],
        skus: [],
        styles: [],
        settings: [],
      },
      styles: {
        Ring: ["Wedding Ring", "Engagement Ring", "Fashion RIng"],
      },
      products: [],
      itemAttributes: {
        style: "",
        shape: "",
        setting: "",
        grams: [],
        metals: [],
        images: [],
        prices: {},
        descriptionss: "",
        sku: "",
        laborCost: 0,
        sizes: [],
        caratWeights: [],
      },
      jewelryTypes: [
        "Ring",
        "Pendant",
        "Necklace",
        "Bracelet",
        "Hoops",
        "Earring",
        "Brooche",
        "Studs",
      ],
      errors: {},
      selectedMetalData: {},
      activeStep: 0,
      selectedShapeMediaTab: {},
      selectedSettingMediaTab: {},
      liveMetalData: {},
      formSteps: 2,
    };
  },

  watch: {
    selectedJewelries: {
      deep: true,
      handler() {
        this.selectedJewelries.forEach((s) => {
          this.completedSteps.setup = this.validateSetup(s);
          this.completedSteps.media = this.validateMedia(s);
          this.completedSteps.pricing = this.validatePricing(s);
        });
      },
    },
    "$store.state.metalModule.metals": {
      deep: true,
      handler() {
        this.liveMetalData = this.$store.state.metalModule.metals;
      },
    },
  },
  async created() {
    this.collection = await fetchDocument("collections", this.$route.params.id);
    const clients = await fetchDocuments("clients");
    this.clients = clients.map((d) =>
      Object.assign({ id: d.id, company: d.company, refNumber: d.refNumber })
    );
    const shapes = [
      "Asscher",
      "Baguette",
      "Cushion",
      "Heart",
      "Oval",
      "Pear",
      "Princess",
      "Radiant",
      "Rose",
      "Shield",
      "Taper",
      "Triangular",
      "Round",
      "Round Brilliant",
      "Marquise",
      "Emerald",
      "Old Cut",
      "Triangular Cut",
      "Baguette Cut",
      "Trapeze Cut",
      "Shield Cut",
      "Taper Cut",
      "Half Moon Cut",
      "Rose Cut",
    ];

    const jewelries = [
      "Ring",
      "Pendant",
      "Necklace",
      "Bracelet",
      "Hoops",
      "Earring",
      "Brooche",
      "Studs",
      // "Watches",
    ];
    this.shapeOptions = shapes.map((s) =>
      Object.assign({ name: s, price: 0, images: [] })
    );
    let jewelry_settings = [
      "Bezel Setting",
      "Prong Setting",
      "Pavé Setting",
      "Channel Setting",
      "Cathedral Setting",
      "Tension Setting",
      "Bar Setting",
      "Halo Setting",
      "Tiffany Setting",
      "Flush Setting",
      "Cluster Setting",
      "Three-Stone Setting",
      "Eternity Band",
      "Anniversary Band",
    ];

    let grams = ["10k", "14k", "16k", "18k"];

    this.gramOptions = grams.map((g) => Object.assign({ gram: g, weight: 0 }));
    this.jewelry_settings = jewelry_settings.map((setting) => ({
      name: setting,
      // shapes: [
      //   {
      //     name: "",
      //     images: [],
      //     price: 0,
      //   },
      // ],
      images: [],
      basePrice: 0,
    }));

    this.jewelryOptions = jewelries.map((s) =>
      Object.assign({
        type: s,
        metals: [],
        shapes: [],
        settings: [],
        grams: [],
        images: [],
        prices: {},
        includeHalf: false,
        descriptions: null,
        title: null,
        stockNumber: null,
        sku: null,
        basePrice: 0,
        sizes: [],
        caratWeights: [],
      })
    );
  },

  methods: {
    handleItemEdit(item) {
      this.itemAttributes = item;
      this.showModal = true;
    },
    handleDeleteItem(item) {
      this.collection.products = this.collection.products.filter(
        (p) => p.sku !== item.sku
      );
    },
    handleDelete(key, item, itemKey) {
      this.itemAttributes[key] = this.itemAttributes[key].filter(
        (i) => i[itemKey] !== item[itemKey]
      );
    },
    handleMetalPricePerWeight(size) {},
    handleMeasurements() {
      const exisitingInput = this.itemAttributes.sizes.find(
        (size) => size.measurement == this.measurementValue
      );
      if (!exisitingInput) {
        this.itemAttributes.sizes.push({
          measurement: this.measurementValue,
          weight: this.measurementWeight,
        });
      }
      this.itemAttributes.sizes = this.itemAttributes.sizes.sort(
        (a, b) => b.measurement - a.measurement
      );
      this.measurementValue = 0;
      this.measurementWeight = 0;
    },
    handleCaratWeight() {
      const exisitingInput = this.itemAttributes.caratWeights.find(
        (carat) => carat.weight == this.caratValue
      );
      if (!exisitingInput) {
        this.itemAttributes.caratWeights.push({
          weight: this.caratValue,
          rate_price: this.diamondRate,
        });
      }

      this.itemAttributes.caratWeights = this.itemAttributes.caratWeights.sort(
        (a, b) => b.weight - a.weight
      );

      this.caratValue = 0;
      this.diamondRate = 0;
    },
    validateSetup(jewelry) {
      let valid = false;
      for (const key in jewelry) {
        if (itemAttributes.hasOwnProperty.call(jewelry, key)) {
          if (key != "type") {
            const element = jewelry[key];
            if (typeof element != "object") {
              if (element) valid = true;
            } else {
              if (
                !["prices", "size", "carat"].includes(key) &&
                element &&
                element.length
              ) {
                valid = true;
              }
            }
          }
        }
      }

      this.errors[itemAttributes.type].setup = valid;
      return valid;
    },
    validateMedia() {
      let valid = false;
      itemAttributes.shapes.forEach((s) => {
        if (s.images.length) valid = true;
      });

      this.errors[itemAttributes.type].media = valid;
      return valid;
    },
    validatePricing() {
      let valid = false;
      if (Object.keys(itemAttributes.prices).length) valid = true;
      itemAttributes.shapes.forEach((s) => {
        if (s.price) valid = true;
      });

      itemAttributes.metals.forEach((m) => {
        if (m.price) valid = true;
      });

      this.errors[itemAttributes.type].prices.metal = valid;
      return valid;
    },
    nextStep() {
      if (this.activeStep < this.formSteps) {
        this.activeStep += 1;
      } else {
        this.activeStep -= 1;
      }
    },
    prevStep() {
      if (this.activeStep > 0) {
        this.activeStep -= 1;
      }
    },

    selectedTabHandler(type) {
      this.selectedTab = type;
    },
    jewelrySelected(jewelry) {
      this.activeStep = 0;
      this.selectedShapeMediaTab[itemAttributes.type] = {};
      this.jewelryOptions = this.jewelryOptions.filter(
        (s) => s.type !== itemAttributes.type
      );
      this.selectedJewelries.push(jewelry);
      this.selectedTab = itemAttributes.type;
      this.showJewelryDropdown = false;
      this.errors[itemAttributes.type] = {
        media: false,
        prices: { metal: false, shapes: false },
        setup: false,
      };
    },

    selectedOption(key, selectedValue, action) {
      if (key == "metalOptions") {
        if (
          !this.$store.state.metalModule.metals.gold.timestamp &&
          selectedValue[0].name == "Gold"
        ) {
          this.$store.dispatch("metalModule/getLiveGoldData", "gold");
        }

        if (
          !this.$store.state.metalModule.metals.platinum.timestamp &&
          selectedValue[0].name == "Platinum"
        ) {
          this.$store.dispatch("metalModule/getLiveGoldData", "platinum");
        }
      }

      if (action == "remove") {
        const value = selectedValue.pop();
        this[key] = this[key].filter(
          (i) => i.name !== value.name || i !== value
        );
      } else {
        this[key].push(selectedValue);
      }
    },
    async addProductToCollection() {
      this.collection.products.push(this.itemAttributes);
      this.itemAttributes = {
        style: "",
        shape: "",
        setting: "",
        grams: [],
        metals: [],
        images: [],
        prices: {},
        descriptionss: "",
        sku: "",
        laborCost: 0,
        sizes: [],
        caratWeights: [],
      };
    },
    async saveCollection() {
      await setDocument("collections", this.$route.params.id, this.collection);
      this.$swal({
        icon: "success",
        title: "Collection Successfully Created",
        type: "success-message",
      }).then(() => this.$router.push("/collections"));
    },
    handleImageUpload(value, data, key) {
      data[key] = value;
    },
    handleImageRemoval(value, data, key, type) {
      if (type === "mult") {
        removeMediaFromStorage(value.ref);
        data[key] = data[key].filter((i) => i.ref !== value.ref);
      } else {
        if (data[key].ref) {
          removeMediaFromStorage(value.ref);
          data[key] = {};
        }
      }
    },
  },
};
</script>

<style scoped>
.values {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.list-values {
  min-height: 50px;
  height: 100px;
  width: 200px;
  border: 0.5px dashed black;
  margin-top: 5px;
  overflow: auto;
}
.nav {
  margin-left: 20px;
}
.tabs {
  display: flex;
  align-items: center;
  color: white;
  font-weight: bold;
  /* justify-content: space-around; */
}

.tab {
  width: 120px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 5px 0;
  border-radius: 5px;
  margin-right: 5px;
  color: black;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.nav-item {
  border: 1px solid #356c72;
  border-radius: 7px;
}

/* .tab:hover {
  background-color: #424b3e8b !important;
  color: white;
} */

.item-wrapper {
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%) !important;
  border-radius: 15px;
  height: auto;
}

.jewelry-info {
  width: 98%;
  margin: 0 auto;
}
.text-editor {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  border-radius: 10px;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
}

.text-editor textarea {
  width: 97.5%;
  height: 95%;
  padding: 20px;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  resize: none;
  transition: all 0.3s ease;
  outline: none;
}

.text-editor textarea:focus {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.text-editor textarea::placeholder {
  color: #aaa;
}

.text-editor textarea:hover {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
}

.active {
  background-color: #424b3e;
  color: white;
}
.create-wrapper {
  font-size: 10px;
}

.close-tab:not(.add-tab):hover {
  background-color: crimson !important;
}
.range-inputs {
  width: 100% !important;
  display: flex;
  justify-content: space-evenly;
}

.range-input {
  width: 45% !important;
}

.seperator {
  width: 10px;
  padding: 3px;
}

.range-wrapper {
  width: 100% !important;
}

.create-btn {
  text-decoration: underline;
  color: grey;
  cursor: pointer;
}

.create-btn:hover {
  color: green;
}

.remove-btn {
  position: absolute;
  right: 5px;
  top: 17px;
  color: whitesmoke;
  cursor: pointer;
  width: 16px;
  height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 100;
  padding-bottom: 2px;
}

.remove-btn:hover {
  background-color: rgba(105, 103, 103, 0.63);
  width: 16px;
  height: 17px;
  border-radius: 5px;
}
</style>
