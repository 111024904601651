<template>
  <div id="profile" class="card card-body mt-4">
    <div class="row justify-content-center align-items-center">
      <div class="col-sm-auto col-4">
        <!-- <soft-avatar
          :img="img"
          alt="bruce"
          size="xl"
          shadow="sm"
          border-radius="lg"
        /> -->
      </div>
      <div class="col-sm-auto col-8 my-auto">
        <div class="h-100">
          <h5 class="mb-1 font-weight-bolder">
            {{ `${currentUser.firstName} ${currentUser.lastName}` }}
          </h5>
          <p class="mb-0 font-weight-bold text-sm center">
            {{ currentUser.role }}
          </p>
        </div>
      </div>
      <!-- <div class="col-sm-auto ms-sm-auto mt-sm-0 mt-3 d-flex">
        <label class="form-check-label mb-0 me-2">
          <small id="profileVisibility">Switch to invisible</small>
        </label>
        <soft-switch id="profile" name="profileVisibility" checked />
      </div> -->
    </div>
  </div>
  <div id="basic-info" class="card mt-4">
    <div class="card-header">
      <h5>Basic Info</h5>
    </div>
    <div class="card-body pt-0">
      <div class="row">
        <div class="col-6">
          <label class="form-label"
            >First Name
            <span
              :class="{ 'missing-field': errors['firstName'], hidden: true }"
              >- Missing Field</span
            ></label
          >
          <div class="form-group">
            <div class="input-group">
              <input
                id="first-name"
                type="text"
                class="form-control multisteps-form__input"
                name="text"
                v-model="userData.firstName"
                :placeholder="currentUser.firstName"
                required
              />
            </div>
          </div>
        </div>
        <div class="col-6">
          <label class="form-label"
            >Last Name
            <span :class="{ 'missing-field': errors['lastName'], hidden: true }"
              >- Missing Field</span
            ></label
          >
          <div class="form-group">
            <div class="input-group">
              <input
                id="last-name"
                type="text"
                class="form-control multisteps-form__input"
                name="text"
                v-model="userData.lastName"
                :placeholder="currentUser.lastName"
                required
              />
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-sm-4 col-6">
          <label class="form-label mt-2">I'm</label>
          <select
            id="choices-gender"
            class="form-control"
            name="choices-gender"
          >
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
        </div>
        <div class="col-sm-8">
          <div class="row">
            <div class="col-sm-5 col-5">
              <label class="form-label mt-2">Birth Date</label>
              <select
                id="choices-month"
                class="form-control"
                name="choices-month"
              ></select>
            </div>
            <div class="col-sm-4 col-3">
              <label class="form-label mt-2">&nbsp;</label>
              <select
                id="choices-day"
                class="form-control"
                name="choices-day"
              ></select>
            </div>
            <div class="col-sm-3 col-4">
              <label class="form-label mt-2">&nbsp;</label>
              <select
                id="choices-year"
                class="form-control"
                name="choices-year"
              ></select>
            </div>
          </div>
        </div>
      </div> -->
      <div class="row">
        <div class="col-6">
          <label class="form-label mt-2">
            Email
            <span :class="{ 'missing-field': errors['email'], hidden: true }"
              >- Missing Field</span
            ></label
          >
          <div class="input-group">
            <input
              id="email"
              type="email"
              class="form-control multisteps-form__input"
              name="text"
              v-model="userData.email"
              :placeholder="currentUser.email"
              required
            />
          </div>
        </div>
        <div class="col-6">
          <label class="form-label mt-2">Phone Number</label>
          <div class="input-group">
            <input
              id="phone"
              type="text"
              class="form-control multisteps-form__input"
              name="text"
              v-model="userData.phone"
              :placeholder="currentUser.phone"
              required
            />
          </div>
        </div>
      </div>

      <!-- <div class="row">
        <div class="col-md-6 align-self-center">
          <label class="form-label mt-2">Language</label>
          <select
            id="choices-language"
            class="form-control"
            name="choices-language"
          >
            <option value="English">English</option>
            <option value="French">French</option>
            <option value="Spanish">Spanish</option>
          </select>
        </div>
      </div> -->
      <soft-button
        class="float-end mt-6 mb-0"
        color="dark"
        variant="gradient"
        size="sm"
        :disabled="loading"
        @click-btn="handleFormValidation"
      >
        <span v-if="!loading">Update Profile</span>
        <i v-else class="fas fa-spinner fa-spin fa-lg"></i>
      </soft-button>
    </div>
  </div>
  <div id="password" class="card mt-4">
    <div class="card-header">
      <h5>Change Password</h5>
    </div>
    <div class="card-body pt-0">
      <label class="form-label">Current password</label>
      <div class="input-group">
        <input
          id="phone"
          type="password"
          class="form-control multisteps-form__input"
          name="text"
          v-model="password.current"
          placeholder="Current Password"
          required
        />
      </div>
      <label class="form-label"
        >New password
        <span :class="{ 'missing-field': errors['current'], hidden: true }"
          >- Missing Field</span
        ></label
      >
      <div class="input-group">
        <input
          id="phone"
          :type="inputPasswordType"
          class="form-control multisteps-form__input"
          name="text"
          v-model="password.new"
          placeholder="New Password"
          required
        />
        <div @click="handlePasswordType" class="input-group-text click-event">
          <span class="">
            <i
              v-if="inputPasswordType.includes('password')"
              class="fas fa-eye"
            ></i>
            <i v-else class="fas fa-eye-slash"></i>
          </span>
        </div>
      </div>
      <span class="required" v-if="feedback.current">{{
        feedback.current
      }}</span>
      <br />
      <label class="form-label"
        >Confirm new password
        <span :class="{ 'missing-field': errors['repeat'], hidden: true }"
          >- Missing Field</span
        ></label
      >
      <div class="input-group">
        <input
          id="confirm"
          :type="inputPasswordType"
          class="form-control multisteps-form__input"
          name="text"
          v-model="password.repeat"
          placeholder="Confirm New Password"
          required
        />
        <div @click="handlePasswordType" class="input-group-text click-event">
          <span class="">
            <i
              v-if="inputPasswordType.includes('password')"
              class="fas fa-eye"
            ></i>
            <i v-else class="fas fa-eye-slash"></i>
          </span>
        </div>
      </div>
      <span class="required" v-if="feedback.repeat">{{ feedback.repeat }}</span>
      <h5 class="mt-5">Password requirements</h5>
      <p class="text-muted mb-2">
        Please follow this guide for a strong password:
      </p>
      <ul class="text-muted ps-4 mb-0 float-start">
        <li>
          <span class="text-sm">One special characters</span>
        </li>
        <li>
          <span class="text-sm">Min 6 characters</span>
        </li>
        <li>
          <span class="text-sm">One number (2 are recommended)</span>
        </li>
        <li>
          <span class="text-sm">Change it often</span>
        </li>
      </ul>
      <soft-button
        class="float-end mt-6 mb-0"
        color="dark"
        variant="gradient"
        size="sm"
        :disabled="loading"
        @click-btn="updateUserProfile('password')"
      >
        <span v-if="!loading">Update password</span>
        <i v-else class="fas fa-spinner fa-spin fa-lg"></i>
      </soft-button>
    </div>
  </div>
  <!-- <div id="2fa" class="card mt-4">
    <div class="card-header d-flex">
      <h5 class="mb-0">Two-factor authentication</h5>
      <soft-badge color="success" class="ms-auto">Enabled</soft-badge>
    </div>
    <div class="card-body">
      <div class="d-flex">
        <p class="my-auto">Security keys</p>
        <p class="text-secondary text-sm ms-auto my-auto me-3">
          No Security Keys
        </p>
        <soft-button
          color="dark"
          variant="outline"
          size="sm"
          class="mb-0"
          type="button"
          >Add</soft-button
        >
      </div>
      <hr class="horizontal dark" />
      <div class="d-flex">
        <p class="my-auto">SMS number</p>
        <p class="text-secondary text-sm ms-auto my-auto me-3">+4012374423</p>
        <soft-button
          color="dark"
          variant="outline"
          size="sm"
          class="mb-0"
          type="button"
          >Edit</soft-button
        >
      </div>
      <hr class="horizontal dark" />
      <div class="d-flex">
        <p class="my-auto">Authenticator app</p>
        <p class="text-secondary text-sm ms-auto my-auto me-3">
          Not Configured
        </p>
        <soft-button
          color="dark"
          variant="outline"
          size="sm"
          class="mb-0"
          type="button"
          >Set up</soft-button
        >
      </div>
    </div>
  </div> -->

  <!-- <div id="notifications" class="card mt-4">
    <div class="card-header">
      <h5>Notifications</h5>
      <p class="text-sm">
        Choose how you receive notifications. These notification settings apply
        to the things you’re watching.
      </p>
    </div>
    <div class="card-body pt-0">
      <div class="table-responsive">
        <table class="table mb-0">
          <thead>
            <tr>
              <th class="ps-1" colspan="4">
                <p class="mb-0">Activity</p>
              </th>
              <th class="text-center">
                <p class="mb-0">Email</p>
              </th>
              <th class="text-center">
                <p class="mb-0">Push</p>
              </th>
              <th class="text-center">
                <p class="mb-0">SMS</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="ps-1" colspan="4">
                <div class="my-auto">
                  <span class="text-dark d-block text-sm">Mentions</span>
                  <span class="text-xs font-weight-normal"
                    >Notify when another user mentions you in a comment</span
                  >
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <soft-switch
                    id="mentionsEmail"
                    name="mentionsEmail"
                    checked
                  />
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <soft-switch id="mentionsPush" name="mentionsPush" />
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <soft-switch id="mentionsSms" name="mentionsSms" />
                </div>
              </td>
            </tr>
            <tr>
              <td class="ps-1" colspan="4">
                <div class="my-auto">
                  <span class="text-dark d-block text-sm">Comments</span>
                  <span class="text-xs font-weight-normal"
                    >Notify when another user comments your item.</span
                  >
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <soft-switch
                    id="commentsEmail"
                    name="commentsEmail"
                    checked
                  />
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <soft-switch id="commentsPush" name="commentsPush" checked />
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <soft-switch id="commentsSms" name="commentsSms" />
                </div>
              </td>
            </tr>
            <tr>
              <td class="ps-1" colspan="4">
                <div class="my-auto">
                  <span class="text-dark d-block text-sm">Follows</span>
                  <span class="text-xs font-weight-normal"
                    >Notify when another user follows you.</span
                  >
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <soft-switch id="followsEmail" name="followsEmail" checked />
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <soft-switch id="followsEmail" name="followsPush" checked />
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <soft-switch id="followsEmail" name="followsSms" checked />
                </div>
              </td>
            </tr>
            <tr>
              <td class="ps-1" colspan="4">
                <div class="my-auto">
                  <p class="text-sm mb-0">Log in from a new device</p>
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <soft-switch id="deviceEmail" name="deviceEmail" checked />
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <soft-switch id="devicePush" name="devicePush" checked />
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <soft-switch id="deviceSms" name="deviceSms" checked />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div> -->
  <!-- <div id="sessions" class="card mt-4">
    <div class="card-header pb-3">
      <h5>Sessions</h5>
      <p class="text-sm">
        This is a list of devices that have logged into your account. Remove
        those that you do not recognize.
      </p>
    </div>
    <div class="card-body pt-0">
      <div class="d-flex align-items-center">
        <div class="text-center w-5">
          <i class="fas fa-desktop text-lg opacity-6"></i>
        </div>
        <div class="my-auto ms-3">
          <div class="h-100">
            <p class="text-sm mb-1">Bucharest 68.133.163.201</p>
            <p class="mb-0 text-xs">Your current session</p>
          </div>
        </div>
        <soft-badge color="success" size="sm" class="my-auto ms-auto me-3"
          >Active</soft-badge
        >
        <p class="text-secondary text-sm my-auto me-3">EU</p>
        <a
          href="javascript:;"
          class="text-primary text-sm icon-move-right my-auto"
        >
          See more
          <i class="fas fa-arrow-right text-xs ms-1" aria-hidden="true"></i>
        </a>
      </div>
      <hr class="horizontal dark" />
      <div class="d-flex align-items-center">
        <div class="text-center w-5">
          <i class="fas fa-desktop text-lg opacity-6"></i>
        </div>
        <p class="my-auto ms-3">Chrome on macOS</p>
        <p class="text-secondary text-sm ms-auto my-auto me-3">US</p>
        <a
          href="javascript:;"
          class="text-primary text-sm icon-move-right my-auto"
        >
          See more
          <i class="fas fa-arrow-right text-xs ms-1" aria-hidden="true"></i>
        </a>
      </div>
      <hr class="horizontal dark" />
      <div class="d-flex align-items-center">
        <div class="text-center w-5">
          <i class="fas fa-mobile text-lg opacity-6"></i>
        </div>
        <p class="my-auto ms-3">Safari on iPhone</p>
        <p class="text-secondary text-sm ms-auto my-auto me-3">US</p>
        <a
          href="javascript:;"
          class="text-primary text-sm icon-move-right my-auto"
        >
          See more
          <i class="fas fa-arrow-right text-xs ms-1" aria-hidden="true"></i>
        </a>
      </div>
    </div>
  </div> -->
  <!-- <div id="delete" class="card mt-4">
    <div class="card-header">
      <h5>Delete Account</h5>
      <p class="text-sm mb-0">
        Once you delete your account, there is no going back. Please be certain.
      </p>
    </div>
    <div class="card-body d-sm-flex pt-0">
      <div class="d-flex align-items-center mb-sm-0 mb-4">
        <div>
          <soft-switch id="deleteAccount" name="deleteAccount" />
        </div>
        <div class="ms-2">
          <span class="text-dark font-weight-bold d-block text-sm"
            >Confirm</span
          >
          <span class="text-xs d-block">I want to delete my account.</span>
        </div>
      </div>
      <soft-button
        color="secondary"
        variant="outline"
        class="mb-0 ms-auto"
        type="button"
        name="button"
        >Deactivate</soft-button
      >
      <soft-button
        color="danger"
        variant="gradient"
        class="mb-0 ms-2"
        type="button"
        name="button"
        >Delete Account</soft-button
      >
    </div>
  </div> -->
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import SoftSwitch from "@/components/SoftSwitch.vue";
import SoftBadge from "@/components/SoftBadge.vue";
import SoftAvatar from "@/components/SoftAvatar.vue";
import SoftInput from "@/components/SoftInput.vue";

import {
  functions,
  httpsCallable,
  auth,
  doc,
  updateDoc,
  db,
} from "@/config/firebaseInit.js";

export default {
  name: "SideNavItem",
  components: {
    SoftButton,
    SoftSwitch,
    SoftBadge,
    SoftAvatar,
    SoftInput,
  },
  data() {
    return {
      password: {
        current: "",
        new: "",
        repeat: "",
      },
      userData: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        company: "",
        role: "",
      },
      currentUser: {},
      loading: false,
      inputPasswordType: "password",
      updateUserAuth: httpsCallable(functions, "updateUser"),
      errors: {
        current: false,
        repeat: false,
        firstName: false,
        lastName: false,
        email: false,
      },
      feedback: {
        current: null,
        repeat: null,
      },
    };
  },

  created() {
    const admin = this.$store.state.authModule.admin;
    if (admin.id) {
      this.currentUser = admin;
    } else {
      this.currentUser = JSON.parse(window.localStorage.getItem("admin_data"));
    }
    this.userData = this.currentUser;
  },
  methods: {
    handlePasswordType() {
      if (this.inputPasswordType.includes("password")) {
        this.inputPasswordType = "text";
      } else {
        this.inputPasswordType = "password";
      }
    },
    updateUserProfile(type) {
      const uid = auth.currentUser.uid;
      switch (type) {
        case "password":
          {
            const validation = this.handlePasswordValidation(
              {
                current: this.password.new,
                repeat: this.password.repeat,
              },
              this.errors
            );
            this.loading = true;
            this.feedback = validation.feedback;
            this.errors = validation.errors;
            if (validation.valid) {
              this.updateUserAuth({
                uid: uid,
                payload: {
                  password: this.password.new,
                },
              }).then(() => {
                
              })
              .then(() => {
                this.loading = false;
                this.$swal({
                  icon: "success",
                  title: "Successful Update",
                  text: `Password has been updated`,
                  type: "success",
                });
              });
            }
          }
          break;
        case "info":
          this.loading = true;
          this.updateUserAuth({
            uid: uid,
            payload: {
              email: this.userData.email,
              displayName: `${this.userData.firstName} ${this.userData.lastName}`,
            },
          })
            .then(() => {
              return updateDoc(doc(db, "users", uid), {
                firstName: this.userData.firstName,
                lastName: this.userData.lastName,
                email: this.userData.email,
                phone: this.userData.phone,
              });
            })
            .then(() => {
              this.loading = false;
              this.$swal({
                icon: "success",
                title: "Successful Update",
                text: `Profile Updated`,
                type: "success",
              });
            });
          break;
        default:
          break;
      }
    },
    handleFormValidation() {
      let valid = true;
      this.errors = {
        firstName: false,
        lastName: false,
        email: false,
      };
      for (const key in this.userData) {
        if (key == "firstName" || key == "lastName" || key == "email") {
          if (!this.userData[key]) {
            this.errors[key] = true;
            valid = false;
          }
        }
      }

      if (valid) {
        this.updateUserProfile("info");
      }
    },
  },
};
</script>

<style>
.center {
  text-align: center;
}

.end {
  text-align: end;
}
</style>
