<template>
  <div
    class="card multisteps-form__panel p-3 border-radius-xl bg-white"
    data-animation="FadeIn"
  >
    <h5 class="font-weight-bolder">Address</h5>
    <div class="multisteps-form__content">
      <div class="row mt-3">
        <div class="col">
          <label
            >Address 1 <span class="required">*</span>
            <span
              :class="{ 'missing-field': errors['addressOne'], hidden: true }"
              >- Missing Field</span
            ></label
          >
          <autocomplete-input
            @selected="$emit('autocomplete-handler', $event)"
            :addressOne="address.addressOne"
          ></autocomplete-input>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <label>Address 2 </label>
          <soft-input
            @input="$emit('address-handler', $event, 'addressTwo')"
            id="address2"
            class="multisteps-form__input"
            type="text"
            placeholder="eg. Street 221"
            :value="address.addressTwo"
          />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-sm-6">
          <label
            >City <span class="required">* </span>
            <span :class="{ 'missing-field': errors['city'], hidden: true }"
              >- Missing Field</span
            ></label
          >
          <soft-input
            @input="$emit('address-handler', $event, 'city')"
            id="city"
            class="multisteps-form__input"
            type="text"
            placeholder="eg. New York"
            :value="address.city"
          />
        </div>
        <div class="col-6 col-sm-3 mt-3 mt-sm-0">
          <div class="form-group">
            <label
              >State <span class="required">*</span>
              <span :class="{ 'missing-field': errors['state'], hidden: true }"
                >- Missing Field</span
              ></label
            >
            <div class="input-group">
              <input
                id="state"
                type="text"
                class="form-control multisteps-form__input"
                name="text"
                @input="$emit('address-handler', $event, 'state')"
                placeholder="State"
                :value="address.state"
                required
              />
            </div>
          </div>
        </div>
        <div class="col-6 col-sm-3 mt-3 mt-sm-0">
          <label
            >Zip <span class="required">*</span>
            <span :class="{ 'missing-field': errors['zipCode'], hidden: true }"
              >- Missing Field</span
            ></label
          >
          <soft-input
            @input="$emit('address-handler', $event, 'zipCode')"
            id="zipCode"
            class="multisteps-form__input"
            :value="address.zipCode"
            type="text"
            placeholder="7 letters"
          />
        </div>
      </div>
      <div class="button-row d-flex mt-4">
        <soft-button
          type="button"
          color="light"
          variant="gradient"
          class="js-btn-prev"
          @click="this.$parent.prevStep"
          >Prev</soft-button
        >
        <!-- <soft-button
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto js-btn-next"
          @click="this.$parent.nextStep"
          >Next</soft-button
        > -->
        <soft-button
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto js-btn-next"
          @click="$emit('save')"
        >
          <i v-if="isLoading" class="fas fa-spinner fa-spin"></i>
          <span v-else>Save</span></soft-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import SoftInput from "@/components/SoftInput.vue";
import SoftButton from "@/components/SoftButton.vue";

import AutocompleteInput from "@/components/AutocompleteInput.vue";

export default {
  name: "Address",
  components: {
    SoftInput,
    SoftButton,
    AutocompleteInput,
  },
  props: {
    errors: {
      type: Object,
      default: {},
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    address: {
      type: Object,
      default: {},
    },
  },
  emits: ["address-handler", "autocomplete-handler"],
};
</script>

<style></style>
