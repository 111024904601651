<template>
  <div class="container-fluid py-6 px-6 card">
    <div class="" data-animation="FadeIn">
      <h5 class="font-weight-bolder mb-0">About Store</h5>
      <p class="mb-0 text-sm">Mandatory Information</p>
      <div class="multisteps-form__form mb-5">
        <div class="multisteps-form__content">
          <div class="row mt-3">
            <div
              v-if="$store.state.authModule.isSuperAdmin"
              class="col-12 col-sm-6"
            >
              <label
                >Company / Client Rep <span class="required">*</span>
                <span
                  :class="{
                    'missing-field': errors['addressOne'],
                    hidden: true,
                  }"
                  >- Missing Field</span
                ></label
              >

              <div class="form-group">
                <v-select
                  :appendToBody="true"
                  v-model="store.client"
                  @option:selected="handleStoreIDOnSelect($event)"
                  :options="clients"
                  label="company"
                  placeholder="Select Company"
                  :closeOnSelect="true"
                  :loading="!clients.length"
                >
                </v-select>
                <a
                  style="text-decoration: underline; cursor: pointer"
                  @click="handleFieldWithClientInfo"
                  >Use Client Info To Fill Certain Fields?</a
                >
              </div>
            </div>
            <div class="col-12 col-sm-6 mt-3 mt-sm-0">
              <label
                >Store/Company Name <span class="required">*</span>
                <span
                  :class="{
                    'missing-field': errors['storeName'],
                    hidden: true,
                  }"
                  >- Missing Field</span
                >
              </label>
              <div class="form-group">
                <div class="input-group">
                  <input
                    id="email"
                    type="text"
                    class="form-control multisteps-form__input"
                    name="text"
                    v-model="store.storeName"
                    placeholder="Store/Company Name"
                    required
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12 col-sm-6">
              <label
                >Phone # <span class="required">*</span>
                <span
                  :class="{ 'missing-field': errors['phone'], hidden: true }"
                  >- Missing Field</span
                >
              </label>
              <div class="form-group">
                <div class="input-group">
                  <input
                    type="tel"
                    v-model="store.phone"
                    @input="formatPhoneNumber"
                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                    name="floating_phone"
                    id="floating_phone"
                    class="form-control multisteps-form__input"
                    placeholder="eg: 347-123-1234"
                    required
                  />
                </div>
              </div>
            </div>

            <div class="col-12 col-sm-6 mt-3 mt-sm-0">
              <label
                >Store ID <span class="required">*</span>
                <span
                  :class="{
                    'missing-field': errors['storeId'],
                    hidden: true,
                  }"
                  >- Missing Field</span
                ></label
              >
              <div class="form-group">
                <div class="input-group">
                  <input
                    disabled
                    id="storeNum"
                    type="text"
                    class="form-control multisteps-form__input"
                    name="text"
                    v-model="store.storeId"
                    placeholder="eg: 1253658"
                    required
                  />
                </div>
              </div>
            </div>
            <!-- <div class="col-12 col-sm-6">
              <label
                >Assign User Access <span class="required">*</span>

                <span
                  :class="{
                    'missing-field': errors['usersEmail'],
                    hidden: true,
                  }"
                  >- Missing Field</span
                >
              </label>

              <div class="form-group">
                <v-select
                  :appendToBody="true"
                  :closeOnSelect="false"
                  v-model="usersEmail"
                  taggable
                  multiple
                  class="form-control multisteps-form__input"
                >
                  <template #no-options="{}">
                    Type an email to create authentification account.
                  </template></v-select
                >
                <sub>Add Email Address to give access</sub>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <div class="" data-animation="FadeIn">
      <h5 class="font-weight-bolder">Address</h5>
      <div class="multisteps-form__content">
        <div class="row mt-3">
          <div class="col-12 col-sm-6">
            <label
              >Address 1 <span class="required">*</span>
              <span
                :class="{
                  'missing-field': errors['addressOne'],
                  hidden: true,
                }"
                >- Missing Field</span
              ></label
            >

            <autocomplete-input
              :addressOne="store.address.addressOne"
              @selected="handleAutocomplete"
            ></autocomplete-input>
          </div>
          <div class="col-12 col-sm-6 mt-3 mt-sm-0">
            <label>Address 2 </label>

            <div class="form-group">
              <div class="input-group">
                <input
                  id="address2"
                  type="text"
                  class="form-control multisteps-form__input"
                  name="text"
                  v-model="store.address.addressTwo"
                  placeholder="eg. 1504, suit 2"
                  required
                />
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-12 col-sm-6">
            <label
              >City <span class="required">* </span>
              <span :class="{ 'missing-field': errors['city'], hidden: true }"
                >- Missing Field</span
              ></label
            >
            <div class="form-group">
              <div class="input-group">
                <input
                  id="city"
                  type="text"
                  class="form-control multisteps-form__input"
                  name="text"
                  v-model="store.address.city"
                  placeholder="eg. New York"
                  required
                />
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-3 mt-3 mt-sm-0">
            <div class="form-group">
              <label
                >State <span class="required">*</span>
                <span
                  :class="{ 'missing-field': errors['state'], hidden: true }"
                  >- Missing Field</span
                ></label
              >
              <div class="input-group">
                <input
                  id="state"
                  type="text"
                  class="form-control multisteps-form__input"
                  name="text"
                  v-model="store.address.state"
                  placeholder="State"
                  required
                />
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-3 mt-3 mt-sm-0">
            <label
              >Zip <span class="required">*</span>
              <span
                :class="{ 'missing-field': errors['zipCode'], hidden: true }"
                >- Missing Field</span
              ></label
            >

            <div class="form-group">
              <div class="input-group">
                <input
                  id="city"
                  type="text"
                  class="form-control multisteps-form__input"
                  name="text"
                  v-model="store.address.zipCode"
                  placeholder="7 digits"
                  required
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-12">
        <div class="uploader">
          <Single
            @upload="uploadMedia"
            @remove="removeMedia"
            label="Upload Store's Logo (Optional)"
            :media="store.metadata"
            :path="`store_logo/${store.storeName}`"
            media_type="images/*"
            :useCustomPath="true"
            :showRemoveButton="editInit"
          />
        </div>
      </div>
    </div> -->
    <div class="button-row d-flex mt-4">
      <soft-button
        type="button"
        color="dark"
        variant="gradient"
        @click-btn="handleFormValidation"
        >Save</soft-button
      >
    </div>
  </div>
</template>

<script>
import SoftInput from "@/components/SoftInput.vue";
import SoftButton from "@/components/SoftButton.vue";
import AutocompleteInput from "@/components/AutocompleteInput.vue";
import Single from "@/components/Upload/Single.vue";
//Firebase
import {
  collection,
  doc,
  db,
  setDoc,
  getDocs,
  updateDoc,
  query,
  where,
  storage,
  arrayUnion,
  deleteObject,
  ref,
  httpsCallable,
  functions,
} from "@/config/firebaseInit.js";

export default {
  name: "NewStore",
  components: {
    SoftInput,
    SoftButton,
    AutocompleteInput,
    Single,
  },
  data() {
    return {
      sendEmails: httpsCallable(functions, "sendEmails"),
      store: {
        storeName: "",
        phone: "",
        storeId: "",
        // assignedUsers: [],
        client: null,
        address: {
          addressOne: "",
          addressTwo: "",
          city: "",
          state: "",
          zipCode: "",
        },
        createdAt: Date.now(),
      },
      errors: {
        storeName: false,
        phone: false,
        storeId: false,
        // assignedUsers: false,
        client: false,
        addressOne: false,
        city: false,
        state: false,
        zipCode: false,
      },
      users: [],
      clients: [],
      usersEmail: [],
      autocomplete: null,
      predictions: [],
    };
  },
  computed: {
    formattedPhoneNumber() {
      const phoneNumberValue = this.store.phone.replace(/\D/g, "");
      const phoneNumberLength = phoneNumberValue.length;

      if (phoneNumberLength <= 3) {
        return phoneNumberValue;
      }

      if (phoneNumberLength <= 7) {
        return `${phoneNumberValue.slice(0, 3)}-${phoneNumberValue.slice(3)}`;
      }

      return `${phoneNumberValue.slice(0, 3)}-${phoneNumberValue.slice(
        3,
        6
      )}-${phoneNumberValue.slice(6, 10)}`;
    },
  },
  async created() {
    // await this.getAllUsers();
    await this.getAllClients();
  },

  methods: {
    handleStoreIDOnSelect(item) {
      this.store.storeId = item.refNumber;
    },
    handleFieldWithClientInfo() {
      if (!this.store.client) {
        this.$swal("Please select a client first");
      }
      const client = this.store.client;
      this.store = Object.assign(this.store, {
        phone: client.phone,
        // assignedUsers: [client.email],
        address: client.address,
        storeName: client.company,
        storeId: client.refNumber,
      });
    },
    formatPhoneNumber() {
      this.store.phone = this.formattedPhoneNumber;
    },
    uploadMedia(data) {
      this.store.metadata = data;
    },
    removeMedia() {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Continue",
      }).then((result) => {
        if (result.value) {
          if (this.store.metadata.ref) {
            deleteObject(ref(storage, this.store.metadata.ref)).catch(
              (error) => {
                console.log(error);
                this.displayError(error);
              }
            );
            this.store.metadata = {};
          }
        }
      });
      // Delete the file
    },
    handleAutocomplete(data) {
      this.store.address = data;
    },
    handleComission(e) {
      if (e.target.value > 100) {
        this.store.commission = 100;
      }
      if (e.target.value < 1) {
        this.store.commission = 1;
      }
    },
    async saveStore() {
      const client = this.store.client;

      this.store.client = Object.assign({
        id: client.id,
        company: client.company,
        fullName: `${client.firstName} ${client.lastName}`,
        rep: client.salesRep,
        refNumber: client.refNumber,
        collection: {
          id: client.collection.id,
          title: client.collection.title,
        },
      });

      await setDoc(doc(db, "stores", this.store.storeId), this.store)
        .then(
          async () =>
            await this.updateClientInfo(
              this.store.client.id,
              this.store.storeId
            )
        )
        // .then(async () => await this.sendNewUserAcctEmail(this.store.client))
        .then(() => {
          this.$swal({
            icon: "success",
            title: "Store Successfully Added",
            type: "success-message",
          }).then(() => this.$router.push("/stores"));
        });
    },
    handleFormValidation() {
      let valid = true;
      this.errors = {
        storeName: false,
        phone: false,
        storeId: false,
        usersEmail: this.usersEmail.length ? false : true,
        client: false,
        addressOne: false,
        city: false,
        state: false,
        zipCode: false,
      };

      for (const key in this.store) {
        if (key == "address") {
          for (const key2 in this.store.address) {
            if (key2 !== "addressTwo" && !this.store.address[key2]) {
              this.errors[key2] = true;
              valid = false;
            }
          }
        }

        // else if (key !== "assignedUsers") {
        //   if (!this.store[key]) {
        //     this.errors[key] = true;
        //     valid = false;
        //   }
        // }
      }

      if (valid) {
        this.saveStore();
      }
    },
    async sendNewUserAcctEmail(client) {
      const usersEmailPass = this.usersEmail.map((e) =>
        Object.assign({
          email: e,
          password: this.generatePassword(8),
          tempId: this.generateID(5),
        })
      );

      const contents = usersEmailPass.map((user) => {
        const html = `
        <!DOCTYPE html>
            <html>
            <head>
                <style>
                    body {
                        font-family: Arial, sans-serif;
                        margin: 0;
                        padding: 0;
                        background-color: #f6f6f6;
                    }
                    .email-container {
                        width: 100%;
                        max-width: 600px;
                        margin: 0 auto;
                        padding: 20px;
                        background-color: #ffffff;
                        box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.1);
                    }
                    .email-header {
                        text-align: center;
                        padding-bottom: 20px;
                    }
                    .email-body {
                        line-height: 1.5;
                        color: #333333;
                    }
                    .email-footer {
                        text-align: center;
                        padding-top: 20px;
                    }
                    .button {
                        display: inline-block;
                        color: #ffffff;
                        background-color: #4CAF50;
                        padding: 10px 20px;
                        text-decoration: none;
                        border-radius: 3px;
                    }
                </style>
            </head>
            <body>
                <div class="email-container">
                    <div class="email-header">
                        <h2>Welcome to Luna Collection!</h2>
                        <img src="${client.collection.logo.url}" alt="${client.collection.title} Logo" style="max-width: 100%; height: auto;">
                    </div>
                    <div class="email-body">
                        <p>Dear User, ${user.email}</p>
                        <p>We are pleased to inform you that you have been selected by ${client.company} in associates with Luna Collection to have exclusive access to our ${client.collection.title} Collection. To activate your account and start exploring, please click the button below:</p>
                        <p style="text-align: center;"><a href="#" class="button">Activate Your Account</a></p>
                        <p>If you did not request this, please ignore this email.</p>
                    </div>
                    <div class="email-footer">
                        <p>Best regards,</p>
                        <p>Luna Collection Inc</p>
                    </div>
                </div>
            </body>
            </html>
        `;
        return Object.assign({
          csv: false,
          html,
          to: user.email,
          filename: false,
          subject: `New User Acct Activation For ${client.collection.title} Collection`,
        });
      });

      // this.sendEmails({ contents });
    },
    generatePassword(length) {
      const charset =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@#$";
      let password = "";
      for (let i = 0; i < length; i++) {
        password += charset.charAt(Math.floor(Math.random() * charset.length));
      }
      return password;
    },
    generateID(length) {
      const charset =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let id = "";
      for (let i = 0; i < length; i++) {
        id += charset.charAt(Math.floor(Math.random() * charset.length));
      }
      return id;
    },
    async getAllUsers() {
      let queryRef = this.$store.state.authModule.isClient
        ? query(
            collection(db, "users"),
            where("admin_access.store", "==", true),
            where("client.id", "==", this.$store.state.authModule.client.id)
          )
        : collection(db, "users");
      const users = await getDocs(queryRef);

      this.users = users.docs.map((user) =>
        Object.assign({
          id: user.id,
          name: `${user.data().firstName} ${user.data().lastName}`,
        })
      );
    },
    async getAllClients() {
      const query = collection(db, "clients");
      const clients = await getDocs(query);
      this.clients = clients.docs.map((client) =>
        Object.assign(
          {
            id: client.id,
          },
          client.data()
        )
      );
    },
    updateUsersInfo(users, storeId) {
      return users.map(async (id) => {
        const userRef = doc(db, "users", id);
        await updateDoc(userRef, {
          assignedStore: storeId,
        });
        return id;
      });
    },
    async updateClientInfo(clientId, StoreId) {
      const clientRef = doc(db, "clients", clientId);
      return await updateDoc(clientRef, {
        stores: arrayUnion(StoreId),
      });
    },
  },
};
</script>
