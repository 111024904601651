<template>
  <div class="container-fluid py-4 card mt-4 p-7 pt-5">
    <div class="contract">
      <p>
        This Wholesale Jewelry Agreement (the “Agreement”), dated as of
        {{ client.createdOn }} (“Effective Date”), by and between [{{
          `${salesPerson.firstName} ${salesPerson.lastName}`
        }}], with an address at 3029 NE 188TH STREET STE#710, AVENTURA FL, 33180
        (the “Company”) and [{{ client.company }}] with an address at
        {{
          `${client.address.addressOne}, ${client.address.city}, ${client.address.state},${client.address.zipCode}`
        }}
        (the “Retailer”).
      </p>
      <h4>INTRODUCTION</h4>

      <p>
        The Company is engaged in the sale of finished jewelry products under
        the trade name Luna Collection® including bracelets, earrings, rings and
        necklaces (the “Products”) designed and manufactured by TTC USA, Ltd.
        (the “Manufacturer”); and
      </p>
      <p>
        The Retailer desires to desires to purchase the Products under the terms
        of this Agreement.
      </p>
      <p>
        <strong>NOW, THEREFORE</strong> , the parties hereto hereby agree as
        follows:
      </p>
      <ol>
        <li>
          <h5 class="underline">The Retailer.</h5>
          <ul>
            <li>
              1.1 The Retailer agrees to market and solicit orders for the sale
              of the Products (“the “Service”) to its customers (each a
              “Customer”). The Retailer: (a) shall use its reasonable best
              efforts to perform the Services; (b) has the requisite, knowledge,
              contacts, experience and expertise to conduct the Services, and
              (c) will conduct the Services in a professional and workmanlike
              manner consistent with the terms of this Agreement and all
              applicable Federal, state and local laws, rules, statutes, orders
              and regulations.
            </li>
            <li>
              1.2. Except as set forth on the Manufacturer’s website under
              Section 2.2 below, the Retailer shall not have authority to accept
              orders on behalf of the Company or Manufacturer or to bind or
              commit the Company or Manufacturer to deliver the Product or
              assume or perform any other obligation in respect of any Customer
              or prospective customer. The Retailer shall not make any
              representation or warranty on behalf of the Manufacturer other
              than as authorized in writing by the Manufacturer.
            </li>
            <li>
              1.3. All Products, Product designs, the Luna Tray and copyrights
              on designs and intellectual property related thereto created for
              the Products and Luna Tray, are owned and registered exclusively
              by the Manufacturer.
            </li>
          </ul>
        </li>

        <li>
          <h5 class="underline">
            Prices and Terms of Order; Confirmation and Acceptance of Orders.
          </h5>
          <ul>
            <li>
              2.1. The Retailer shall quote only the prices, delivery schedules
              and other terms and conditions set forth on the Manufacturer’s
              website and no deviations shall be made therefrom.
            </li>

            <li>
              2.2. All orders solicited and taken by the Retailer shall be
              submitted solely through the Manufacturer’s website, with full
              payment in advance, and are subject to (a) acceptance and
              confirmation in writing by the Manufacturer, and (b) the terms and
              conditions of this Agreement, and (c) the Manufacturer’s website,
              including with respect to privacy matters, warranties, product
              availability, pricing, delivery, operation or disruption of the
              Manufacturer’s website, and the like. All quotations for the
              Product made by the Retailer to Customers or prospective customers
              and all orders delivered to the Customer must be made expressly
              subject to the approval and confirmation of the Manufacturer and
              are not final until an authorized officer gives such approval in
              writing.
            </li>

            <li>
              2.3. The Manufacturer reserves the right, in its sole discretion,
              to decline to accept any order and to change or discontinue the
              marketing of any of the Product, without prior notice to the
              Retailer. The Manufacturer may, in its sole discretion, cancel any
              order, either in whole or in part, at any time after acceptance by
              the Manufacturer and the Manufacturer shall be relieved of all of
              its obligations with respect to such order.
            </li>
          </ul>
        </li>
        <li>
          <h5 class="underline">Expenses.</h5>
          The Retailer shall be solely responsible for all expenses relating to
          the performance of the Services.
        </li>
        <li>
          <h5 class="underline">Furnishing Sales Materials to the Retailer.</h5>
          <ul>
            <li>
              4.1. The Company may from time to time during the term of this
              Agreement furnish the Retailer with such sales catalogs,
              brochures, and the Luna Tray (as defined in Schedule A) and other
              sales materials (collectively the “Sales Materials”) as the
              Company deems necessary to enable the Retailer to solicit orders
              for the Services. The Luna Tray may not be moved or transferred
              from the Retailer’s location to another person, entity or location
              without the Company’s and Manufacturer’s prior written approval.
              All Sales Materials shall remain the exclusive property of the
              Manufacturer. The Retailer shall use such Sales Materials solely
              for the purpose of soliciting orders for the Services and will
              take all action necessary and appropriate to ensure that no Sales
              Materials are lost, stolen, destroyed or damaged. If any Sales
              Material is lost, stolen, destroyed or damaged, the Retailer shall
              be liable to the Company and Manufacturer for the cost of
              replacement of such Sales Materials.
            </li>
            <li>
              4.2. Upon demand by and in accordance with the instructions of the
              Company and/or Manufacturer, the Retailer will, at the Retailer’s
              expense, return to the Manufacturer all Sales Materials specified
              in such demand within 10 days after the date of such demand. The
              Retailer further agrees that, in the event that this Agreement is
              terminated for any reason (with or without cause), the Retailer
              shall immediately return, at the Retailer’s own expense, all such
              Sales Materials to the Company or Manufacturer at its main office
              or as otherwise directed by the Company or Manufacturer, and if
              not so returned, the Retailer shall be responsible for the cost of
              its replacement.
            </li>
          </ul>
        </li>
        <li>
          <h5 class="underline">Termination.</h5>
          Either party may terminate this Agreement (a) for a breach by the
          other party that remains uncured for ten (10) business days following
          written notice thereof, and (b) without cause upon thirty (30) days'
          notice to the other party. Termination of this Agreement shall not
          affect the rights and obligations of the parties accrued prior to the
          effective date of the termination. The rights and duties under
          Sections 1.3, 4.2, and 5 through 21 shall survive termination or
          expiration of this Agreement.
        </li>
        <li>
          <h5 class="underline">Indemnification.</h5>
          The Retailer shall defend, indemnify and hold harmless the Company and
          Manufacturer, its officers, directors, members, employees and agents
          from and against any loss, liability, claim or damage (including
          reasonable attorneys’ fees) relating to or arising from (i) any claim
          or allegation in connection with the Services performed by the
          Retailer, and (ii) a breach of the representations, warranties, or
          covenants under the terms of this Agreement.
        </li>
        <li>
          <h5 class="underline">Non-Disclosure.</h5>
          The Retailer shall not, during the Term of this Agreement, except in
          the performance of obligations hereunder, or at any time after the
          termination of this Agreement, communicate or disclose, or use for
          Retailer’s account, without the prior written consent of the Company
          and Manufacturer, any business information, observations, data,
          written material, records or documents relating to the business and
          affairs of the Company, Manufacturer or any of their affiliates,
          including, without limitation, any trade secrets, financial
          information, and any profit and cost information.
        </li>
        <li>
          <h5 class="underline">
            Covenant to Report; Ownership of Trade Secrets.
          </h5>
          All data, work in progress, results, observations, conclusions,
          discoveries, inventions, ideas, know-how, procedures, advancements,
          and the like (collectively, "Inventions") disclosed and/or learned
          from the provision of the Services shall promptly be provided to
          Manufacturer in writing and, if copyrightable, be deemed "work for
          hire." All Inventions shall belong solely and exclusively to, and
          inure to the benefit of, Manufacturer. Upon the Termination of this
          Agreement or upon the earlier request of the Company or Manufacturer
          during the Term, the Retailer shall promptly deliver the same to the
          Manufacturer.
        </li>
        <li>
          <h5 class="underline">Independent Contractor.</h5>
          The Services provided by the Retailer shall be as an independent
          contractor and not as an employee, agent, subsidiary or corporate
          affiliate of the Company or Manufacturer. Retailer’s appointment as an
          independent contractor is non-exclusive, and Company expressly retains
          the right to retain the services of other retailers, independent
          contractors, representatives, employees and/or agents to render
          services that are the same or similar to services rendered by
          Retailer.
        </li>
        <li>
          <h5 class="underline">Force Majeure.</h5>
          Neither party or Manufacturer shall be responsible to the other for
          any failure or delay in performing any of its obligations under this
          Agreement or for other nonperformance hereunder, except for payment of
          funds, if such delay or nonperformance caused by strike, fire, flood,
          earthquake, accident, war, act of terrorism, epidemics, pandemics is,
          quarantines, internet disruption, act of God or of the government of
          any country or of any local government, or by any other cause
          unavoidable or beyond the control of any party hereto. In such event,
          such affected party shall use commercially reasonable e\efforts to
          resume performance of its obligations and will keep the other party
          informed of actions related thereto.
        </li>
        <li>
          <h5 class="underline">No Conflicts with Other Agreements.</h5>
          The Retailer represents and warrants that his obligations under this
          Agreement do not breach, violate, conflict with or contravene any oral
          or written understanding or agreement binding on the Retailer,
          including, without limitation, any covenant not to compete.
        </li>
        <li>
          <h5 class="underline">Assignment.</h5>
          This Agreement is personal to the Retailer and may not be assigned or
          transferred.
        </li>
        <li>
          <h5 class="underline">Third Party Beneficiary.</h5>
          The Manufacturer shall be deemed a third-party beneficiary under the
          terms of this Agreement, with the right to enforce the terms hereof,
          and is entitled to rely on the representations, warranties, covenants
          and remedies set forth herein as if an original party to this
          Agreement. For the avoidance of doubt, the Agreement shall not be
          terminated, cancelled, amended, modified, supplemented or changed, or
          any provision, default, breach or performance waived, or any
          assignment made in any manner without written consent of the
          Manufacturer.
        </li>
        <li>
          <h5 class="underline">Changes; Alterations.</h5>
          No change, alteration, modification or addition to this Agreement
          shall be effective unless in writing and properly executed by the
          parties hereto.
        </li>
        <li>
          <h5 class="underline">Governing Law; Jurisdiction.</h5>
          The validity and effect of this Agreement shall be governed by and
          construed and enforced in accordance with the laws of the State of New
          York, without regard to any conflict-of-law rule or principle that
          would give effect to the laws of another jurisdiction. Any dispute,
          controversy or question of interpretation arising under, out of, in
          connection with, or in relation to this Agreement or any amendments
          hereof, or any breach or default hereunder, shall be submitted to, and
          determined and settled in the state or federal courts in New York
          County, New York.
        </li>
        <li>
          <h5 class="underline">Notices.</h5>
          Notices pursuant to this Agreement will be written and (i) personally
          delivered, (ii) by certified mail, return receipt requested, postage
          pre-paid, (iii) sent via a major recognized express courier service,
          postage prepaid, to the parties at the applicable addressee at the
          addresses set forth above, or (iv) by confirmed email at the addresses
          set forth below, attention: President. Notices shall be effective:
          upon delivery, if personally delivered; the next business day
          following dispatch, if sent on a business day by overnight mail or
          courier, e-mail; or four (4) business days after dispatch, if sent by
          certified mail. Retailer’s email address is
          {{ client.email }}. Company’s email address is
          {{ salesPerson.email }}.
        </li>
        <li>
          <h5 class="underline">Entire Agreement.</h5>
          This Agreement, together with Schedule A attached hereto, embodies the
          entire agreement and understanding between the parties hereto with
          respect to the subject matter of this Agreement and supersedes all
          prior agreements, commitments, arrangements; negotiations or
          understandings, whether oral or written, between the parties with
          respect thereto. There are no agreements, covenants, undertakings,
          representations or warranties with respect to the subject matter of
          this Agreement other than those expressly set forth or referred to
          herein.
        </li>
        <li>
          <h5 class="underline">Severability.</h5>
          If any provisions of the Agreement shall be declared to be invalid or
          unenforceable, in whole or in part, such invalidity or
          unenforceability shall not affect the remaining provisions hereof,
          which shall remain in full force and effect.
        </li>
        <li>
          <h5 class="underline">Headings.</h5>
          The headings contained in this Agreement are for convenience of
          reference only and shall not constitute a part hereof or define, limit
          or otherwise affect the meaning of any of the terms or provisions
          hereof.
        </li>
        <li>
          <h5 class="underline">Construction.</h5>
          The parties acknowledge that each party and its counsel have reviewed
          and revised this Agreement and that consequently any rule of
          construction to the effect that any ambiguities are to be resolved
          against the drafting party is not applicable in the interpretation of
          this Agreement or any amendments or exhibits hereto.
        </li>
        <li>
          <h5 class="underline">Counterparts.</h5>
          This Agreement may be executed in one or more counterparts, each of
          which shall be deemed an original, but all of which taken together
          shall constitute one and the same instrument.
        </li>
      </ol>
      <p>
        <strong>IN WITNESS WHEREOF,</strong> the parties hereto have caused this
        Agreement to be duly executed as of the day and year first above
        written.
      </p>
    </div>

    <div class="signature_container">
      <div class="salesrep">
        <div class="input-field"><p>Company: Luna Collection</p></div>
        <div class="input-field">
          <p>Name:</p>
          {{ salesPerson.firstName }} {{ salesPerson.lastName }}
        </div>
        <div class="input-field">
          <p>Title:</p>
          SalesPerson
        </div>

        <div class="input-field">
          <SignatureContainer :label="'Salesman Signature'" />
        </div>
      </div>

      <div class="client">
        <div class="input-field">
          <p>Retailer:</p>
          {{ client.company }}
        </div>

        <div class="input-field">
          <p>Name:</p>
          {{ client.firstName }} {{ client.lastName }}
        </div>
        <div class="input-field">
          <p>Title:</p>
          <input v-model="client.title" type="text" name="" id="" />
        </div>

        <div class="input-field">
          <SignatureContainer
            v-model="client.contract.signature"
            :label="'Client Signature'"
          />
        </div>
      </div>
    </div>

     
    <div style="margin: 0 auto">
      <h5 style="margin-left: 15px">SCHEDULE A</h5>
      <p>Luna Collection Tray</p>
    </div>
    <p>
      The Luna Tray is a tray designed for displaying the Products in a retail
      store, where Customers can view and choose pieces to order.
    </p>
    <div>
      <h5>Arrangement and Functionality:</h5>
      <ul>
        <li>
          Models of the Products are arranged neatly within the Luna Tray.
        </li>
        <li>
          The layout is designed to be easily accessible, allowing Customers to
          view each item clearly.
        </li>
      </ul>
    </div>
    <div>
      <h5>Usage in the Retail Setting:</h5>
      <ul>
        <li>
          In a retail environment, the Luna Trays will be presented on counters
          or in secure display cases where Customers can view the items with the
          assistance of a salesperson.
        </li>
      </ul>
    </div>
    <div>
      <h5>Ordering Process:</h5>
      <ul>
        <li>
          Once a Customer selects a model from the tray, the Retailer will place
          an order for the piece on the Company’s website in accordance with the
          Company’s procedure and policies.
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import SignatureContainer from "@/components/SignatureCotainer.vue";
import { doc, db, getDoc } from "@/config/firebaseInit.js";
import { mapMutations } from "vuex";

export default {
  components: {
    SignatureContainer,
  },
  data() {
    return {
      client: {},
      salesPerson: {},
    };
  },

  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    this.fetchClient(this.$route.params.clientID);
    this.fetchSalesPerson(this.$route.params.repID);
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },

  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    async fetchClient(id) {
      const clientRef = doc(db, "clients", id);
      const clientDoc = await getDoc(clientRef);
      if (!clientDoc.exists) this.$router.push({ name: "Error404" });
      this.client = Object.assign(
        { contract: { signed: false, signature: null } },
        clientDoc.data()
      );
    },
    async fetchSalesPerson(id) {
      const salesPersonRef = doc(db, "sales_rep", id);
      const repDoc = await getDoc(salesPersonRef);
      if (!repDoc.exists) this.$router.push({ name: "Error404" });
      this.salesPerson = repDoc.data();
    },
  },
};
</script>

<style scoped>
.underline {
  text-decoration: underline;
}

.signature_container {
  display: flex;
  justify-content: space-between;
  width: 65%;
  margin-bottom: 50px;
}

.input-field {
  display: flex;
  gap: 5px;
}

.contract {
  text-align: justify;
}
</style>
