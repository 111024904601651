import {
  ref,
  doc,
  db,
  setDoc,
  updateDoc,
  addDoc,
  getDocs,
  collection,
  storage,
  functions,
  httpsCallable,
  getDoc,
  deleteDoc,
  deleteObject,
} from "@/config/firebaseInit.js";

import Swal from "sweetalert2";

async function fetchDocuments(colName) {
  const documents = await getDocs(collection(db, colName));
  return documents.docs.map((doc) => Object.assign(doc.data(), { id: doc.id }));
}

async function fetchDocument(colName, docId) {
  const document = await getDoc(doc(db, colName, docId));
  return Object.assign(document.data(), { id: document.id });
}

function deleteDocument(colName, docId) {
  return deleteDoc(doc(db, colName, docId));
}

function displayError(error) {
  var errorCode = error.code;
  var errorMessage = error.message;
  console.log({ errorCode, errorMessage });
  return Swal.fire(
    "Error!",
    `${errorMessage} - code: ${errorCode}. Please try again or contact support.`,
    "error"
  );
}

function removeMediaFromStorage(mediaRef) {
  return deleteObject(ref(storage, mediaRef)).catch((error) => {
    console.log(error);
    displayError(error);
  });
}

function setDocument(colName, docId, document) {
  return setDoc(doc(db, colName, docId), document, { merge: true }).catch(
    (error) => {
      console.log(error);
      displayError(error);
    }
  );
}


function updateDocument(colName, docId, updatedData) {
  return updateDoc(doc(db, colName, docId), updatedData).catch(
    (error) => {
      console.log(error);
      displayError(error);
    }
  );
}

function addDocument(colName, document) {
  return addDoc(collection(db, colName), document).catch((error) => {
    console.log(error);
    displayError(error);
  });
}

export {
  fetchDocuments,
  removeMediaFromStorage,
  setDocument,
  deleteDocument,
  fetchDocument,
  addDocument,
  updateDocument
};
