import axios from "axios";

const getDefaultState = () => {
  return {
    metals: {
      gold: {},
      platinum: {},
    },
  };
};
const authModule = {
  namespaced: true,
  state: {
    metals: {
      gold: {},
      platinum: {},
    },
  },
  mutations: {
    saveMetalData(state, data) {
      state.metals[data.metal] = data.res;
    },
  },
  actions: {
    async getLiveGoldData(context, id) {
      const metals = {
        gold: "XAU",
        platinum: "XPT",
      };
      await axios
        .get(`https://www.goldapi.io/api/${metals[id]}/USD`, {
          headers: {
            "x-access-token": "goldapi-4k460rlqwll2io-io",
          },
        })
        .then((res) => {
          context.commit("saveMetalData", {
            metal: id,
            res: res.data,
          });
        })
        .catch((err) => {
          console.debug(err);
        });
    },
  },
  modules: {},
};

export default authModule;
