<template>
  <hr class="horizontal dark mt-0 mb-4" />
  <div class="card-body p-3 pt-0">
    <div class="multisteps-form__content">
      <div class="row mt-3">
        <div class="col-12 col-sm-6">
          <label>Company / Client Rep </label>
          <div class="form-group">
            <div class="input-group">
              <input
                id="client"
                disabled
                type="text"
                class="form-control multisteps-form__input"
                name="text"
                :value="storeData.client.company"
                placeholder="Store Name"
                required
              />
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 mt-3 mt-sm-0">
          <label>Store's Name </label>
          <div class="form-group">
            <div class="input-group">
              <input
                id="email"
                disabled
                type="text"
                class="form-control multisteps-form__input"
                name="text"
                v-model="storeData.storeName"
                placeholder="Store Name"
                required
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-sm-6">
          <label>Phone # </label>
          <div class="form-group">
            <div class="input-group">
              <input
                id="phone"
                :disabled="!editInit"
                type="tel"
                class="form-control multisteps-form__input"
                name="text"
                v-model="storeData.phone"
                placeholder="eg: +1 (347) 123-1234"
                required
              />
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-6 mt-3 mt-sm-0">
          <label>Store # </label>
          <div class="form-group">
            <div class="input-group">
              <input
                id="storeNum"
                type="text"
                :disabled="!editInit"
                class="form-control multisteps-form__input"
                name="text"
                v-model="storeData.storeId"
                placeholder="eg: 1253658"
                required
              />
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 mt-3 mt-sm-0">
          <div class="form-group">
            <label>Commission Percentage % </label>
            <!-- <div class="percentage-icon">%</div> -->
            <input
              id="storeNum"
              type="text"
              :disabled="!editInit"
              class="form-control multisteps-form__input"
              name="text"
              v-model="storeData.commission"
              placeholder="eg: 35%"
              required
            />
            <sub>Please select a value that is no more than 50%</sub>
          </div>
        </div>
        <div class="col-12 col-sm-6 mt-3 mt-sm-0">
          <div class="form-group">
            <label>Collection </label>
            <!-- <div class="percentage-icon">%</div> -->
            <input
              id="storeNum"
              type="text"
              disabled
              class="form-control multisteps-form__input"
              name="text"
              v-model="storeData.client.collection.title"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="button-row">
      <soft-button
        v-if="
          $store.state.authModule.isClient ||
          $store.state.authModule.isSuperAdmin
        "
        type="button"
        color="dark"
        variant="gradient"
        class="ms-auto mb-0 js-btn-next"
        @click-btn="editInit = !editInit"
        >{{ editInit ? "Cancel" : "Edit" }}</soft-button
      >
      <soft-button
        v-if="editInit"
        type="button"
        color="primary"
        variant="gradient"
        class="ms-auto mb-0 js-btn-next"
        @click-btn="
          () => {
            $emit('update', clientData), (editInit = false);
          }
        "
        >Save changes</soft-button
      >
    </div>
  </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
export default {
  name: "display-info",
  components: {
    SoftButton,
  },
  props: {
    store: {
      type: Object,
      default() {
        return Object;
      },
    },
  },
  emits: ["update"],
  data() {
    return {
      editInit: false,
      storeData: {},
    };
  },
  created() {
    this.storeData = this.store;
  },
};
</script>

<style>
</style>