<template>
  <div class="container-fluid py-4">
    <div class="d-sm-flex justify-content-between">
      <!-- <div>
          <soft-button color="success" variant="gradient">New client</soft-button>
        </div> -->
      <div class="d-flex">
        <soft-button
          class="btn-icon ms-2 export"
          size
          color="dark"
          variant="outline"
          data-bs-toggle="modal"
          data-bs-target="#Modal"
          @click="$emit('new-item')"
        >
          <span class="btn-inner--icon">
            <i class="fas fa-gem"></i>
          </span>
          <span class="btn-inner--text"> New Item</span>
        </soft-button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card mt-4">
          <div style="height: 800px" class="table-responsive">
            <div class="filters">
              <div class="filter">
                <label for="">Search</label>
                <soft-input
                  style="margin-bottom: 0 !important"
                  @input="(event) => (filterBy.searching = event.target.value)"
                  placeholder="Search By SKU, Type, Style or Shape"
                >
                </soft-input>
              </div>
              <div class="filter">
                <label for="">Jewelry Type</label>
                <v-select
                  :options="jewelryTypeOptions"
                  v-model="filterBy.jewelryType"
                  class="dropdown"
                >
                </v-select>
              </div>
              <div class="filter">
                <label for="">Style</label>
                <v-select
                  :options="styleOptionsPerType[filterBy.jewelryType]"
                  v-model="filterBy.style"
                  class="dropdown"
                >
                </v-select>
              </div>
              <div class="filter">
                <label for="">Shape</label>
                <v-select
                  :options="shapeOptions"
                  v-model="filterBy.shape"
                  class="dropdown"
                >
                </v-select>
              </div>
              <div class="filter">
                <label for="">Setting</label>
                <v-select
                  :options="settingOptions"
                  v-model="filterBy.setting"
                  class="dropdown"
                >
                </v-select>
              </div>
            </div>
            <!-- <div class="action-btns">
                <soft-button color="danger" @click="handleClearFilter"
                  >Clear</soft-button
                >
              </div> -->
            <table class="table align-items-center mb-0">
              <thead>
                <tr>
                  <th
                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    Thumbnail
                  </th>
                  <th
                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    SKU
                  </th>
                  <th
                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                  >
                    Jewelry Type
                  </th>
                  <th
                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                  >
                    Style
                  </th>
                  <th
                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    Settings
                  </th>
                  <th
                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    Shape
                  </th>
                  <th
                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    Controls
                  </th>
                  <th
                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    Published
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in queriedData"
                  :key="item.id"
                  class="table-row"
                >
                  <td>
                    <div class="text-sm text-secondary mb-0">
                      <slide-show
                        @enlarge-image="enlargeImage"
                        :files="item.mediaFiles"
                      >
                      </slide-show>
                      <!-- <soft-avatar
                        v-if="item.mediaFiles[0]"
                        :img="item.mediaFiles[0].url"
                        alt="avatar image"
                        border-radius="lg"
                      /> -->
                    </div>
                  </td>
                  <td>
                    <p class="text-sm text-secondary mb-0">
                      {{ item.sku }}
                    </p>
                  </td>

                  <td class="align-middle text-center text-sm">
                    <p class="text-secondary mb-0 text-sm">
                      {{ item.jewelryType }}
                    </p>
                  </td>
                  <td class="align-middle text-center text-sm">
                    <p class="text-secondary mb-0 text-sm">
                      {{ item.style }}
                    </p>
                  </td>
                  <td class="align-middle text-center">
                    {{ item.setting }}
                  </td>
                  <td class="align-middle text-center">
                    {{ item.shape }}
                  </td>
                  <td>
                    <soft-button
                      style="margin-right: 10px"
                      data-bs-toggle="modal"
                      data-bs-target="#Modal"
                      @click="$emit('handle-edit', item)"
                      ><i class="fas fa-eye"></i
                    ></soft-button>
                    <soft-button
                      color="danger"
                      @click="$emit('handle-delete', item)"
                      ><i class="fas fa-trash"></i
                    ></soft-button>
                  </td>
                  <td>
                    <soft-switch
                      :checked="item.active"
                      @change="
                        $emit('handle-publishing', {
                          event: $event,
                          id: item.sku,
                        })
                      "
                    >
                      {{ item.active ? "Published" : "Unpublished" }}
                    </soft-switch>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <image-modal
    :styleFormat="{ width: '100%' }"
    :url="selectedImage.url"
    :type="selectedImage.type"
  />
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import SoftCheckbox from "@/components/SoftCheckbox.vue";
import SoftInput from "@/components/SoftInput.vue";
import SoftAvatar from "@/components/SoftAvatar.vue";
import SoftSwitch from "@/components/SoftSwitch.vue";
import ImageModal from "@/components/ImageModal.vue";
import SlideShow from "@/components/SlideShow.vue";

export default {
  name: "Table",
  components: {
    SoftButton,
    SoftCheckbox,
    SoftInput,
    SoftAvatar,
    ImageModal,
    SoftSwitch,
    SlideShow,
  },
  props: {
    unfilteredDataTable: {
      type: Array,
      default: [],
    },
    shapeOptions: {
      type: Array,
      default: [],
    },
    jewelryTypeOptions: {
      type: Array,
      default: [],
    },
    settingOptions: {
      type: Array,
      default: [],
    },
    styleOptionsPerType: {
      type: Array,
      default: {},
    },
  },
  emits: ["handle-publishing", "handle-edit", "handle-delete", "new-item"],
  data() {
    return {
      tableData: [],
      selectedImage: {},
      filterBy: {
        shape: null,
        setting: null,
        jewelryType: null,
        style: null,
        searching: null,
      },
    };
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data.
     */
    queriedData() {
      let results = this.unfilteredDataTable;

      if (
        this.filterBy.shape ||
        this.filterBy.setting ||
        this.filterBy.jewelryType ||
        this.filterBy.style
      ) {
        results = this.unfilteredDataTable.filter((i) => {
          const shapeMatches =
            !this.filterBy.shape ||
            i.shape.toLowerCase() === this.filterBy.shape.toLowerCase();
          const settingMatches =
            !this.filterBy.setting ||
            i.setting.toLowerCase() === this.filterBy.setting.toLowerCase();
          const typeMatches =
            !this.filterBy.jewelryType ||
            i.jewelryType.toLowerCase() ===
              this.filterBy.jewelryType.toLowerCase();
          const styleMatches =
            !this.filterBy.style ||
            i.style.toLowerCase() === this.filterBy.style.toLowerCase();

          return shapeMatches && settingMatches && typeMatches && styleMatches;
        });
      } else if (this.filterBy.searching) {
        results = this.searchItem(this.filterBy.searching.toLowerCase());
      }
      return results;
      // return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.tableData.length;
    },
  },
  watch: {
    unfilteredDataTable: {
      handler() {
        this.tableData = this.unfilteredDataTable.sort((a, b) => {
          if (a.jewelryType < b.jewelryType) {
            return -1;
          }
          if (a.jewelryType > b.jewelryType) {
            return 1;
          }
          return 0;
        });
      },
    },
  },

  methods: {
    enlargeImage(image) {
      this.selectedImage = image;
      const modalElement = document.getElementById("image-modal");
      const bootstrapModal = new bootstrap.Modal(modalElement);
      bootstrapModal.show();
    },
    handleClearFilter() {
      this.tableData = this.unfilteredDataTable;
      this.filterBy = {
        shape: null,
        setting: null,
        jewelryType: null,
        style: null,
      };
    },
    searchItem(searchVal) {
      return this.unfilteredDataTable.filter(
        (i) =>
          i.sku.toLowerCase().includes(searchVal) ||
          i.jewelryType.toLowerCase().includes(searchVal) ||
          i.style.toLowerCase().includes(searchVal) ||
          i.setting.toLowerCase().includes(searchVal) ||
          i.shape.toLowerCase().includes(searchVal)
      );
    },
  },
};
</script>

<style scoped>
.action-btns {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 17px;
}
.filters {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px;
}

.filter {
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}

.dropdown {
  width: 150px;
  border-radius: 25px !important;
}
</style>
