<template>
  <hr class="horizontal dark mt-0 mb-4" />

  <div v-if="initNewDoc" class="card-body p-3 pt-0">
    <div class="form">
      <div class="form-group">
        <h5 for="">Select Document Type</h5>
        <select
          id="choices-category-edit"
          v-model="newDoc.docType"
          class="form-control"
          name="choices-category"
        >
          <option value="Contract">Contract</option>
          <option value="Other">Other</option>
        </select>

        <div v-if="newDoc.docType == 'Other'" class="form-group">
          <label>Other: <span class="required">*</span> </label>
          <div class="input-group">
            <input
              id="title"
              type="text"
              class="form-control multisteps-form__input"
              name="text"
              placeholder="State"
              v-model="newDoc.docType"
              required
            />
          </div>
        </div>
      </div>

      <div class="form-group">
        <label>Title <span class="required">*</span> </label>
        <div class="input-group">
          <input
            id="title"
            type="text"
            class="form-control multisteps-form__input"
            name="text"
            placeholder="Title"
            v-model="newDoc.title"
            required
          />
        </div>
      </div>
    </div>
    <div class="uploader">
      <Single
        @upload="uploadMedia"
        @remove="removeMedia"
        label="Uploaded Document"
        :media="newDoc.metadata"
        :path="`client/${client.company}/documents/${newDoc.title}`"
        media_type="docs"
        :useCustomPath="true"
        :showRemoveButton="editInit"
      />
    </div>

    <div class="buttons">
      <soft-button color="success" @click="saveClientDocument"
        >Save</soft-button
      >
      <soft-button color="primary" @click="closeModal">Cancel</soft-button>
    </div>
  </div>
  <div v-else class="card-body p-3 pt-0">
    <div class="row">
      <div class="col-12">
        <section>
          <table>
            <thead>
              <tr>
                <th>
                  <div>Thumbnail</div>
                  <sub style="font-size: 10px; color: green"
                    >Click the icon to view document</sub
                  >
                </th>
                <th>Title</th>
                <th>Doc Type</th>
                <th>Created On</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="document in documents" :key="document.id">
                <td>
                  <div
                    @click="openDocument(document.metadata.url)"
                    class="file_icon"
                  >
                    <i style="cursor: pointer" class="far fa-file"></i>
                  </div>
                </td>
                <td>{{ document.title }}</td>
                <td>{{ document.docType }}</td>
                <td>{{ format_date(document.createdAt) }}</td>
              </tr>
            </tbody>
          </table>
        </section>
      </div>
    </div>
    <div class="button-row">
      <soft-button
        v-if="
          $store.state.authModule.isSuperAdmin ||
          $store.state.authModule.isEmployee
        "
        type="button"
        color="primary"
        variant="gradient"
        class="ms-auto mb-0 js-btn-next"
        @click-btn="openModal"
        >Upload A Doc</soft-button
      >
    </div>
  </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import Single from "@/components/Upload/Single.vue";
import Modal from "@/components/Modal.vue";
import axios from "axios";

import {
  storage,
  ref,
  deleteObject,
  collection,
  addDoc,
  db,
  doc,
  orderBy,
  onSnapshot,
  query,
  updateDoc,
} from "@/config/firebaseInit";

export default {
  name: "display-info",
  components: {
    SoftButton,
    Single,
    Modal,
  },
  props: {
    client: {
      type: Object,
      default() {
        return Object;
      },
    },
  },

  data() {
    return {
      newDoc: {
        type: null,
        metadata: {},
        title: null,
        createdAt: Date.now(),
      },
      documents: [],
      initNewDoc: false,
    };
  },
  created() {
    this.fetchClientDocuments();
  },
  methods: {
    openDocument(url) {
      window.open(url, "");
    },
    async fetchClientDocuments() {
      const docRef = collection(db, `clients/${this.client.id}/documents`);
      const docQuery = query(docRef, orderBy("createdAt", "desc"));

      const unsub = await onSnapshot(docQuery, (querySnapshot) => {
        this.documents = querySnapshot.docs.map((doc) =>
          Object.assign({ id: doc.id }, doc.data())
        );
      });
    },
    openModal() {
      this.initNewDoc = true;
    },
    closeModal() {
      this.initNewDoc = false;
      this.newDoc = {
        type: null,
        metadata: {},
        title: null,
        createdAt: Date.now(),
      };
    },
    uploadMedia(data) {
      this.newDoc.metadata = data;
    },
    removeMedia() {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Continue",
      }).then((result) => {
        if (result.value) {
          this.saveClientDocument({}, this.client.id);
          if (this.client.metadata.ref) {
            deleteObject(ref(storage, this.client.metadata.ref)).catch(
              (error) => {
                console.log(error);
                this.displayError(error);
              }
            );
            this.client.metadata = {};
          }
        }
      });
      // Delete the file
    },
    async saveClientDocument() {
      if (this.newDoc.docType == "Contract" && !this.client.hasSignedContract) {
        await updateDoc(doc(db, `clients`, this.client.id), {
          hasSignedContract: true,
        }).then(() => this.sendConfirmationEmail(this.client));
      }
      await addDoc(
        collection(db, `clients/${this.client.id}/documents`),
        this.newDoc
      );

      this.closeModal();
    },
    async sendConfirmationEmail(client) {
      try {
        return await axios.post(
          "https://us-central1-luna-collection-ttc.cloudfunctions.net/LunaCollectionServer/send_email",
          {
            data: {
              contents: [
                {
                  template_id: 3,
                  csv: false,
                  subject: "Welcome To Luna Collection",
                  filename: null,
                  to: [client.email],
                  bcc: [
                    "kelvin@diamondservicesusa.com",
                    "sam@ttcusadiamond.com",
                    "tal@diamondservicesusa.com",
                  ],
                  cc: client.salesRep.email
                    ? [client.salesRep.email]
                    : ["rkonfino@yahoo.com"],
                  params: {
                    clientName: `${client.firstName} ${client.lastName}`,
                    clientPassword: client.password,
                    clientStoreID: client.refNumber,
                  },
                },
              ],
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      } catch (err) {
        console.error(err);
        throw new Error(err);
      }
    },
  },
};
</script>

<style scoped>
.file_icon {
  font-size: 30px;
}

.file_icon > i:hover {
  color: green;
}
table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

th {
  background-color: #f2f2f2;
  text-align: center;
}

td {
  font-size: 14px;
}
</style>
