<template>
  <div
    class="card multisteps-form__panel p-3 border-radius-xl bg-white"
    data-animation="FadeIn"
  >
    <h5 class="font-weight-bolder mb-0">About Client</h5>
    <p class="mb-0 text-sm">Mandatory Information</p>
    <div class="multisteps-form__content">
      <div class="row mt-3">
        <div class="col-12 col-sm-6">
          <label
            >Sale Representative <span class="required">*</span>
            <span :class="{ 'missing-field': errors['company'], hidden: true }"
              >- Missing Field</span
            >
          </label>
          <div class="form-group">
            <v-select
              :appendToBody="true"
              @option:selected="
                $emit('dropdown-detail-handler', $event, 'salesRep')
              "
              :options="salesReps"
              :value="client.salesReps"
              label="firstName"
              :clearable="false"
              :loading="!salesReps.length"
            >
              <template v-slot:option="option">
                {{ `${option.firstName} ${option.lastName}` }}
              </template>
            </v-select>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-sm-6">
          <label
            >First Name <span class="required">*</span>
            <span
              :class="{ 'missing-field': errors['firstName'], hidden: true }"
              >- Missing Field</span
            >
          </label>
          <div class="form-group">
            <div class="input-group">
              <input
                id="firstName"
                type="text"
                class="form-control multisteps-form__input"
                name="text"
                @input="$emit('detail-handler', $event, 'firstName')"
                placeholder="First Name"
                required
              />
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 mt-3 mt-sm-0">
          <label
            >Last Name <span class="required">*</span>
            <span
              class="required"
              :class="{ 'missing-field': errors['lastName'], hidden: true }"
              >- Missing Field</span
            >
          </label>
          <div class="form-group">
            <div class="input-group">
              <input
                id="lastName"
                type="text"
                class="form-control multisteps-form__input"
                name="text"
                @input="$emit('detail-handler', $event, 'lastName')"
                placeholder="Last Name"
                required
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-sm-6">
          <label
            >Company <span class="required">*</span>
            <span :class="{ 'missing-field': errors['company'], hidden: true }"
              >- Missing Field</span
            >
          </label>
          <div class="form-group">
            <div class="input-group">
              <input
                id="company"
                type="text"
                class="form-control multisteps-form__input"
                name="text"
                @input="$emit('detail-handler', $event, 'company')"
                placeholder="Company"
                required
              />
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 mt-3 mt-sm-0">
          <label
            >Email Address <span class="required">*</span>
            <span :class="{ 'missing-field': errors['email'], hidden: true }"
              >- Missing Field</span
            >
          </label>
          <div class="form-group">
            <div class="input-group">
              <input
                id="email"
                type="text"
                class="form-control multisteps-form__input"
                name="text"
                @input="$emit('detail-handler', $event, 'email')"
                placeholder="eg: client@email.com"
                required
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-sm-6">
          <label
            >Phone # <span class="required">*</span>
            <span :class="{ 'missing-field': errors['phone'], hidden: true }"
              >- Missing Field</span
            >
          </label>
          <div class="form-group">
            <div class="input-group">
              <input
                type="tel"
                @input="$emit('detail-handler', $event, 'phone')"
                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                name="floating_phone"
                id="floating_phone"
                class="form-control multisteps-form__input"
                placeholder="eg: 347-123-1234"
                required
                :value="client.phone"
              />
            </div>
          </div>
        </div>
        <!-- <div class="col-12 col-sm-6 mt-3 mt-sm-0">
          <label
            >Reference # <span class="required">*</span>
            <span
              :class="{ 'missing-field': errors['refNumber'], hidden: true }"
              >- Missing Field</span
            ></label
          >
          <div class="form-group">
            <div class="input-group">
              <input
                disabled
                :value="client.refNumber"
                id="refNum"
                type="text"
                class="form-control multisteps-form__input"
                name="text"
                placeholder="eg: 1253658"
                required
              />
            </div>
          </div>
        </div> -->
        <!-- <div class="col-12 col-sm-6">
          <label>Administration Access</label>
          <div class="form-group">
            <select
              id="choices-category-edit"
              @input="$emit('detail-handler', $event, '')"
              class="form-control"
              name="choices-category"
              @change="handleAdminAccess"
            >
              <option value="super">Super Admin</option>
              <option value="client">Client Admin</option>
              <option value="employee">DIS Admin</option>
              <option value="store">Store Admin</option>
              <option value="factory">Factory Admin</option>
            </select>
          </div>
        </div> -->
      </div>

      <div class="row mt-3">
        <div class="col-12 col-sm-6">
          <label
            >Collection <span class="required">*</span>
            <span
              :class="{
                'missing-field': errors['assignedUsers'],
                hidden: true,
              }"
              >- Missing Field</span
            ></label
          >

          <div class="form-group">
            <v-select
              :appendToBody="true"
              :deselectFromDropdown="true"
              @option:selected="
                $emit('dropdown-detail-handler', $event, 'collection')
              "
              :options="collections"
              :clearable="false"
              label="title"
              :loading="!collections.length"
            >
            </v-select>
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label
              >Markup Percentage <span class="required">*</span>
              <span
                :class="{
                  'missing-field': errors['markup'],
                  hidden: true,
                }"
                >- Missing Field</span
              ></label
            >
            <!-- <div class="percentage-icon">%</div> -->
            <input
              id="markup"
              type="number"
              min="200"
              max="500"
              class="form-control multisteps-form__input"
              name="markup"
              @input="$emit('detail-handler', $event, 'commission')"
              isRequired
              placeholder="200"
            />
            <sub>Please select a value that is no less than 200%</sub>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-sm-6">
          <label
            >Password <span class="required">*</span>
            <span :class="{ 'missing-field': errors['current'], hidden: true }"
              >- Missing Field</span
            ></label
          >
          <div class="form-group">
            <div class="input-group">
              <input
                id="password"
                :type="inputPasswordType"
                class="form-control multisteps-form__input"
                name="password"
                @input="$emit('detail-handler', $event, 'password', 'current')"
                placeholder="******"
                isRequired
              />
              <div
                @click="handlePasswordType"
                class="input-group-text click-event"
              >
                <span class="">
                  <i class="fas fa-eye"></i>
                </span>
              </div>
            </div>
            <span class="required" v-if="feedback.current">{{
              feedback.current
            }}</span>
          </div>
        </div>
        <div class="col-12 col-sm-6 mt-3 mt-sm-0">
          <label
            >Repeat Password <span class="required">*</span>
            <span :class="{ 'missing-field': errors['repeated'], hidden: true }"
              >- Missing Field</span
            ></label
          >
          <div class="form-group">
            <div class="input-group">
              <input
                id="password"
                :type="inputPasswordType"
                class="form-control multisteps-form__input"
                name="password"
                @input="$emit('detail-handler', $event, 'password', 'repeat')"
                placeholder="******"
                isRequired
              />
              <div
                @click="handlePasswordType"
                class="input-group-text click-event"
              >
                <span class="">
                  <i class="fas fa-eye"></i>
                </span>
              </div>
            </div>
            <span class="required" v-if="feedback.repeat">{{
              feedback.repeat
            }}</span>
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <h5 class="mt-5">Password requirements</h5>
          <p class="text-muted mb-2">
            Please follow this guide for a strong password:
          </p>
          <ul class="text-muted ps-4 mb-0 float-start">
            <li>
              <span class="text-sm">One special characters</span>
            </li>
            <li>
              <span class="text-sm">Min 6 characters</span>
            </li>
            <li>
              <span class="text-sm">One number (2 are recommended)</span>
            </li>
            <li>
              <span class="text-sm">Change it often</span>
            </li>
          </ul>
        </div>
      </div>

      <div class="button-row d-flex mt-4">
        <soft-button
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto mb-0 js-btn-next"
          @click="$emit('next')"
          >Next
        </soft-button>
        <!-- <soft-button
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto mb-0 js-btn-next"
          @click-btn="saveUser"
          >Save</soft-button
        > -->
      </div>
    </div>
  </div>
</template>

<script>
import SoftInput from "@/components/SoftInput.vue";
import SoftButton from "@/components/SoftButton.vue";
import Single from "../../../components/Upload/Single.vue";

export default {
  name: "Info",
  components: {
    SoftInput,
    SoftButton,
    Single,
  },
  props: {
    errors: {
      type: Object,
      default: {},
    },
    client: {
      type: Object,
      default: {},
    },
    salesReps: {
      type: Array,
      default: [],
    },
    collections: {
      type: Array,
      default: [],
    },
    feedback: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      inputPasswordType: "password",
    };
  },
  methods: {
    handlePasswordType() {
      if (this.inputPasswordType.includes("password")) {
        this.inputPasswordType = "text";
      } else {
        this.inputPasswordType = "password";
      }
    },
  },
};
</script>
