<template>
  <div class="container-fluid card">
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form mb-5">
          <div class="row">
            <div class="col-12 col-lg-8 mx-auto my-5">
              <div class="multisteps-form__progress">
                <button
                  class="multisteps-form__progress-btn js-active"
                  type="button"
                  title="Client Info"
                  :class="activeStep >= 0 ? activeClass : ''"
                >
                  <span>Client Info</span>
                </button>
                <button
                  class="multisteps-form__progress-btn"
                  type="button"
                  title="Profile Picture"
                  :class="activeStep >= 1 ? activeClass : ''"
                >
                  Profile Picture
                </button>
                <button
                  class="multisteps-form__progress-btn"
                  type="button"
                  title="Address"
                  :class="activeStep >= 2 ? activeClass : ''"
                >
                  Address
                </button>
              </div>
            </div>
          </div>
          <!--form panels-->
          <div class="row">
            <div class="col-12 col-lg-8 m-auto">
              <form class="multisteps-form__form mb-5">
                <!--single form panel-->
                <info
                  @detail-handler="handleClientDetails"
                  @dropdown-detail-handler="handleDropDownDetails"
                  @next="handleFormValidation('info')"
                  :errors="errors"
                  :client="client"
                  :salesReps="salesReps"
                  :collections="collections"
                  :feedback="passwordFeedback"
                  :class="activeStep === 0 ? activeClass : ''"
                />
                <!--single form panel-->
                <profile
                  :clientName="client.company"
                  :metadata="client.metadata"
                  :class="activeStep === 1 ? activeClass : ''"
                  @next="nextStep"
                  @uploaded-media="uploadMedia"
                  @remove-media="removeMedia"
                />
                <!--single form panel-->
                <Address
                  @address-handler="handleClientAddress"
                  @autocomplete-handler="handleAutocomplete"
                  @save="handleFormValidation('address')"
                  :address="client.address"
                  :class="activeStep === 2 ? activeClass : ''"
                  :errors="errors.address"
                  :isLoading="isLoading"
                />

                <!--single form panel-->
                <!-- <socials :class="activeStep === 2 ? activeClass : ''" /> -->
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Info from "./components/Info.vue";
import Address from "./components/Address.vue";
import Socials from "./components/Socials.vue";
import Profile from "./components/Profile.vue";
//Firebase
import {
  ref,
  doc,
  db,
  setDoc,
  storage,
  functions,
  httpsCallable,
  deleteObject,
} from "@/config/firebaseInit.js";

import { fetchDocuments } from "@/db/index";

export default {
  name: "NewClient",
  components: {
    Info,
    Address,
    Socials,
    Profile,
  },
  data() {
    return {
      addUser: httpsCallable(functions, "addUser"),
      showMenu: false,
      collections: [],
      salesReps: [],
      activeClass: "js-active position-relative",
      activeStep: 0,
      formSteps: 2,
      client: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        company: "",
        commission: 10,
        address: {
          addressOne: "",
          addressTwo: "",
          city: "",
          state: "",
          zipCode: "",
        },
        stores: [],
        collection: {},
        refNumber: "",
        metadata: {},
        createdAt: Date.now(),
      },
      errors: {
        firstName: false,
        lastName: false,
        email: false,
        phone: false,
        company: false,
        role: false,
        salesRep: null,
        address: {
          addressOne: false,
          addressTwo: false,
          city: false,
          state: false,
          zipCode: false,
        },
        refNumber: false,
        current: "",
        repeat: "",
      },
      password: {
        current: "",
        repeat: "",
      },
      passwordFeedback: {
        repeat: null,
        current: null,
      },
      isLoading: false,
    };
  },

  computed: {
    formattedPhoneNumber() {
      const phoneNumberValue = this.client.phone.replace(/\D/g, "");
      const phoneNumberLength = phoneNumberValue.length;

      if (phoneNumberLength <= 3) {
        return phoneNumberValue;
      }

      if (phoneNumberLength <= 7) {
        return `${phoneNumberValue.slice(0, 3)}-${phoneNumberValue.slice(3)}`;
      }

      return `${phoneNumberValue.slice(0, 3)}-${phoneNumberValue.slice(
        3,
        6
      )}-${phoneNumberValue.slice(6, 10)}`;
    },
  },
  created() {
    this.fetchCollections();
    this.fetchReps();
  },
  methods: {
    async fetchCollections() {
      this.collections = await fetchDocuments("collections");
    },
    async fetchReps() {
      this.salesReps = await fetchDocuments("sales_rep");
    },
    formatPhoneNumber() {
      this.client.phone = this.formattedPhoneNumber;
    },
    handleClientDetails(event, key, subKey) {
      if (subKey) {
        this[key][subKey] = event.target.value;
      }
      this.client[key] = event.target.value;
      if (key === "phone") this.formatPhoneNumber();
    },
    handleDropDownDetails(data, key) {
      if (key === "salesRep") {
        this.client[key] = Object.assign({
          id: data.id,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          repID: data.repId,
        });
      }

      if (key === "collection") {
        this.client[key] = Object.assign({
          id: data.id,
          title: data.title,
          logo: data.logo,
        });
      }
    },
    handleClientAddress(event, key) {
      this.client.address[key] = event.target.value;
    },
    nextStep() {
      if (this.activeStep < this.formSteps) {
        this.activeStep += 1;
      } else {
        this.activeStep -= 1;
      }
    },
    prevStep() {
      if (this.activeStep > 0) {
        this.activeStep -= 1;
      }
    },
    async saveClient() {
      this.isLoading = true;
      this.addUser({
        email: this.client.email,
        password: this.password.current,
        displayName: `${this.client.firstName} ${this.client.lastName}`,
      })
        .then(async (res) => {
          const uid = res.data;
          await setDoc(doc(db, "clients", uid), this.client)
            .then(() => {
              let client = this.client;
              client.admin_access = {
                client: true,
              };
              client.role = "Client Executive";
              delete client.stores;
              delete client.refNumber;
              return this.saveAdmin(client, uid);
            })
            .then(() => {
              this.isLoading = true;
              this.$swal({
                icon: "success",
                title: "Client Successfully Added",
                text: `${this.client.firstName} ${this.client.lastName}  has been added to the roster`,
                type: "success-message",
              }).then(() => {
                this.$router.push("/clients");
              });
            })
            .catch((err) => {
              this.isLoading = false;
              this.displayError(err);
            });
        })
        .catch((err) => {
          this.isLoading = false;
          this.displayError(err);
        });
    },
    async saveAdmin(client, id) {
      return await setDoc(doc(db, "users", id), client);
    },
    handleFormValidation(step) {
      let valid = true;
      let passwordValid = true;
      this.errors = {
        firstName: false,
        lastName: false,
        email: false,
        phone: false,
        company: false,
        role: false,
        address: {
          addressOne: false,
          addressTwo: false,
          city: false,
          state: false,
          zipCode: false,
        },
      };
      switch (step) {
        case "info":
          for (let key in this.client) {
            if (
              key !== "address" &&
              key !== "stores" &&
              key !== "role" &&
              key !== "refNumber"
            ) {
              if (!this.client[key]) {
                this.errors[key] = true;
                valid = false;
              }
            }
          }
          passwordValid = this.handlePasswordValidation(this.password);
          if (valid && passwordValid) {
            this.nextStep();
          }
          break;
        case "address":
          for (let key in this.client.address) {
            if (key !== "addressTwo") {
              if (!this.client.address[key]) {
                this.errors.address[key] = true;
                valid = false;
              }
            }
          }
          if (valid) {
            this.saveClient();
          }
          break;
        default:
          break;
      }
    },
    handlePasswordValidation(password) {
      let valid = true;
      this.passwordFeedback.current = "";
      this.passwordFeedback.repeat = "";

      const regex =
        /^(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=.*[0-9])(?=.{6,})[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/;
      if (!password.current) {
        this.errors.current = true;
        valid = false;
      } else if (
        !regex.test(password.current) &&
        !regex.test(password.repeat)
      ) {
        this.passwordFeedback.current =
          "The password does not meet requirement";
        valid = false;
      } else if (password.current !== password.repeat) {
        this.passwordFeedback.repeat = "Passwords don't match";
        valid = false;
      }
      return valid;
    },
    uploadMedia(data) {
      this.client.metadata = data;
    },
    removeMedia() {
      if (!this.client.metadata.ref) {
        this.client.metadata = {};
        return;
      }

      // Delete the file
      deleteObject(ref(storage, this.client.metadata.ref))
        .then(() => {
          this.client.metadata = {};
        })
        .catch((error) => {
          console.debug(error);
          this.displayError(error);
        });
    },
    handleAutocomplete(data) {
      this.client.address = data;
    },
  },
};
</script>
