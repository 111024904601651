<template>
  <div class="price-breakdown">
    <h2 class="title">Cost Breakdown</h2>
    <div class="breakdown-items">
      <!-- Use v-for to iterate through order details -->
      <div class="item" v-for="(value, key) in orderDetails" :key="key">
        <i :class="getIconClass(key)" class="icon"></i>
        <span class="description">{{ formatLabel(key) }}:</span>
        <span class="price">{{ currencyFormat(value) }}</span>
        <span class="percentage">{{ percentageFormat(value) }}</span>
      </div>
      <div class="total">
        <strong>Total:</strong>
        <strong class="total-price">{{ currencyFormat(total) }}</strong>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PriceBreakdown",
  props: {
    order: {
      type: Object,
      default: {},
    },
  },
  computed: {
    orderDetails() {
      // Extract and compute details from the order prop here
      // The dummy data structure for this example

      return {
        totalJewelryPrice: this.order.totalCost,
        providerCommission: 30,
        clientStoreFee: 100,
        shippingFee: 15,
        taxes: 6,
      };
    },
    total() {
      return Object.values(this.orderDetails).reduce(
        (acc, value) => acc + value,
        0
      );
    },
  },
  methods: {
    currencyFormat(amount) {
      return `$${amount.toFixed(2)}`;
    },
    percentageFormat(amount) {
      const percentage = (amount / this.total) * 100;
      return `(${percentage.toFixed(2)}%)`;
    },
    formatLabel(key) {
      // Replace underscores with spaces and capitalize each word
      return key
        .replace(/_/g, " ")
        .replace(/\b\w/g, (char) => char.toUpperCase());
    },
    getIconClass(key) {
      // Map keys to specific FontAwesome icon classes
      const icons = {
        totalJewelryPrice: "fas fa-gem",
        providerCommission: "fas fa-hand-holding-usd",
        clientStoreFee: "fas fa-store",
        shippingFee: "fas fa-shipping-fast",
        taxes: "fas fa-file-invoice-dollar",
      };
      return icons[key] || "fas fa-money-bill";
    },
  },
};
</script>
<style scoped>
.price-breakdown {
  background: #ffffff;
  border-radius: 16px;
  padding: 20px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.05);
  max-width: 450px;
  width: 100%;
  margin: auto;
}

.title {
  text-align: center;
  color: #333;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.breakdown-items {
  background: #f8f9fa;
  border-radius: 12px;
  padding: 15px;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.item:last-child {
  margin-bottom: 0;
}

.description {
  flex: 1;
  font-size: 16px;
  color: #333;
}

.price,
.percentage {
  margin-left: 8px;
  font-size: 16px;
  color: #333;
}

.price {
  font-weight: bold;
}

.percentage {
  font-weight: normal;
  color: #666;
}

.icon {
  font-size: 22px;
  color: #4a90e2;
  margin-right: 10px;
}

.total {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding-top: 10px;
  font-size: 18px;
  color: #333;
  font-weight: bold;
  border-top: 2px solid #e1e4e8;
}

.total-price {
  color: #4a90e2;
}
</style>
