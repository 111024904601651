<template>
  <aside
    id="sidenav-main"
    class="my-3 overflow-auto border-0 sidenav navbar navbar-vertical navbar-expand-xs border-radius-xl"
    :class="isRTL ? 'me-3 rotate-caret' : 'ms-3'"
  >
    <div class="sidenav-header">
      <i
        id="iconSidenav"
        class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
        aria-hidden="true"
      ></i>
      <router-link class="m-0 navbar-brand" to="/">
        <img :src="logo" class="navbar-brand-img h-100" alt="main_logo" />
      </router-link>
      <div class="current-user-header">
        {{ currentUser.displayName }}
      </div>
    </div>
    <hr class="mt-0 horizontal dark" />
    <sidenav-list :card-bg="customClass" />
  </aside>
</template>
<script>
import SidenavList from "./SidenavList.vue";
import logo from "@/assets/img/Luna-Collection.png";
import { mapState } from "vuex";
import { auth } from "@/config/firebaseInit.js";

export default {
  name: "Index",
  components: {
    SidenavList,
  },
  props: {
    customClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      logo,
      currentUser: auth.currentUser || {},
    };
  },
  computed: {
    ...mapState(["isRTL"]),
  },
};
</script>

<style scoped>
.current-user-header {
  text-align: center;
  width: 100%;
  position: relative;
}
</style>
