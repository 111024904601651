<template>
  <div>
    <div id="note-wrapper">
      <label class="note-label" for="">New Note </label>
      <textarea v-model="note.text" name="" id="note-section"></textarea>
      <div class="end">
        <soft-button color="info" @click="addNoteToOrder">Add Note</soft-button>
      </div>
    </div>

    <div id="user-dropdown">
      <label class="note-label" for="">Assign Users: </label>
      <v-select
        :deselectFromDropdown="true"
        v-model="note.assignedUsers"
        :options="$store.state.authModule.adminUsers"
        multiple
        label="firstName"
        :reduce="
          (user) =>
            Object.assign({
              id: user.id,
              firstName: user.firstName,
              lastName: user.lastName,
              email: user.email,
            })
        "
        :closeOnSelect="false"
      >
        <template v-slot:option="option">
          <input
            type="checkbox"
            :value="
              Object.assign({
                id: option.id,
                firstName: option.firstName,
                lastName: option.lastName,
              })
            "
            v-model="note.assignedUsers"
          />
          {{ `${option.firstName} ${option.lastName}` }}
        </template>
      </v-select>
    </div>
  </div>
  <div v-if="notes.length" id="note-list">
    <div v-for="n in notes" :key="n.id" id="notes">
      <div id="date">
        {{ format_date_time(n.createdAt) }}
      </div>
      <div id="author">
        {{ n.createdBy.name }}
      </div>
      <div id="note">
        {{ n.text }}
      </div>
      <div v-if="n.assignedUsers.length" id="assigned-users">
        <strong>Assigned To:</strong>
        <div v-for="user in n.assignedUsers" :key="user.id" id="user">
          {{ user.firstName }},
        </div>
      </div>
      <div id="delete">
        <soft-button
          :disabled="n.createdBy.id != $store.state.authModule.admin.id"
          @click="deleteNote(n.id)"
          color="danger"
          >Delete</soft-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import {
  collection,
  doc,
  db,
  orderBy,
  query,
  deleteDoc,
  addDoc,
  onSnapshot,
  httpsCallable,
  functions,
  updateDoc,
} from "@/config/firebaseInit.js";

import SoftButton from "@/components/SoftButton.vue";
import SoftAvatar from "@/components/SoftAvatar.vue";
import SoftBadge from "@/components/SoftBadge.vue";
import SoftInput from "@/components/SoftInput.vue";
import SoftCheckbox from "@/components/SoftCheckbox.vue";

export default {
  components: {
    SoftButton,
    SoftAvatar,
    SoftBadge,
    SoftInput,
    SoftCheckbox,
  },
  props: {
    collection: {
      type: String,
      default: "",
    },
    dataId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      notes: [],
      note: {
        createdAt: null,
        createdBy: null,
        text: null,
        assignedUsers: [],
      },
    };
  },
  created() {
    this.fetchNotes();
  },
  methods: {
    async deleteNote(id) {
      await deleteDoc(doc(db, this.collection, this.dataId, "notes", id));
    },
    async addNoteToOrder() {
      if (this.note.text) {
        const admin = this.$store.state.authModule.admin;
        this.note.createdBy = Object.assign({
          id: admin.id,
          name: `${admin.firstName} ${admin.lastName}`,
        });
        this.note.createdAt = Date.now();
        const note = JSON.parse(Object.assign(JSON.stringify(this.note)));
        await addDoc(
          collection(db, this.collection, this.dataId, "notes"),
          note
        );

        await updateDoc(doc(db, this.collection, this.dataId), {
          hasActiveNotes: true,
        });

        this.note.text = null;
        this.note.createdAt = null;
        this.note.assignedUsers = [];
        this.note.createdBy = null;
        // await this.sendNoteNotificationToAssignedUsers(note);
        this.$swal({
          icon: "success",
          title: "Note Created",
          text: `Note has been created and assigned users has been notified`,
          type: "success",
        });
      }
    },
    async sendNoteNotificationToAssignedUsers(note) {
      const contents = note.assignedUsers.map((u) =>
        Object.assign({
          template_id: 8,
          csv: "",
          html: "",
          filename: "",
          subject: "",
          to: [u.email],
          bcc: ["kelvin@diamondservicesusa.com"],
          cc: ["tlusa18@gmail.com", "sam@ttcusadiamond.com"],
          params: {
            userName: u.firstName,
            notificationType: "Note",
            message: `Order#:  ${this.dataId}, ${note.text}`,
          },
        })
      );
      const sendEmails = httpsCallable(functions, "sendEmails");
      try {
        return await sendEmails({ contents });
      } catch (error) {
        console.debug(error);
      }
    },
    async fetchNotes() {
      const noteRef = collection(db, this.collection, this.dataId, "notes");
      const notesQuery = query(noteRef, orderBy("createdAt", "desc"));

      const unsub = await onSnapshot(notesQuery, (querySnapshot) => {
        this.notes = querySnapshot.docs.map((doc) =>
          Object.assign({ id: doc.id }, doc.data())
        );
      });
    },
  },
};
</script>

<style scoped>
/* IDS */

#image-info {
  width: 40%;
  text-align: center;
  font-style: italic;
}
#delete {
  width: 100%;
  display: flex;
  align-content: flex-end;
  justify-content: end;
  margin-top: 5px;
}
#user {
  margin-left: 10px;
  color: rgb(28, 170, 186);
  font-weight: bold;
}
#assigned-users {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 25px;
  overflow: auto;
}
#note-list {
  max-height: 300px;
  overflow: auto;
  margin-top: 20px;
  border: 2px solid rgba(27, 105, 111, 0.4);
}
#notes {
  margin-bottom: 25px;
  margin-top: 25px;
}
#note {
  width: 100%;
  background: rgba(73, 199, 213, 0.1);
  border: 2px solid rgba(27, 105, 111, 0.4);
  margin-bottom: 0;
  max-height: 100px;
  overflow: auto;
}
#author {
  font-size: 12px;
  color: rgb(28, 170, 186);
  font-weight: bold;
}
#date {
  font-size: 10px;
  font-weight: bold;
}
#user-dropdown {
  width: 300px;
  margin-top: -40px;
}
#note-section {
  min-height: 200px;
  max-height: 200px;
  padding: 10px 13px !important;
  font-size: 16px !important;
  border: 2px solid rgba(27, 105, 111, 0.4);
  margin-bottom: 0;
  width: 100%;
  resize: none;
  background: rgba(73, 199, 213, 0.1);
}
#note-wrapper {
  width: 100%;
}

/* Classes */
.note-label {
  display: flex;
}
</style>