<template>
  <div v-if="order.customer" class="container-fluid">
    <div class="nav-wrapper position-relative end-0">
      <ul class="nav nav-pills nav-fill p-1" role="tablist">
        <li class="nav-item">
          <p
            @click="selectedTab = 'details'"
            class="nav-link mb-0 px-0 py-1"
            :class="{ active: selectedTab == 'details' }"
          >
            Order Details
          </p>
        </li>
        <!-- <li class="nav-item">
          <p
            @click="selectedTab = 'customer'"
            class="nav-link mb-0 px-0 py-1"
            :class="{ active: selectedTab == 'customer' }"
          >
            Shipping Details
          </p>
        </li> -->
        <li class="nav-item">
          <p
            @click="selectedTab = 'statuses'"
            class="nav-link mb-0 px-0 py-1"
            :class="{ active: selectedTab == 'statuses' }"
          >
            Status/Shipping
          </p>
        </li>
      </ul>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card mb-4">
          <div v-if="selectedTab == 'details'" class="card-body p-3 pt-0">
            <hr class="horizontal dark mt-0 mb-4" />
            <div class="d-flex justify-content-between align-items-center">
              <div class="info-container">
                <p class="text-sm mb-0">
                  Order no.
                  <b>{{ order.orderNumber }}</b> from Store #
                  <b>{{ order.storeId }}</b>
                </p>
                <p class="text-sm mb-0">
                  Created On.
                  <b>{{ format_date_time(order.createdAt) }}</b>
                </p>
                <p class="text-sm">
                  Reference #:
                  <b> {{ order.client.refNum }}</b>
                </p>
                <div class="tabs">
                  <div class="style tab">
                    <div class="text-sm mb-0">
                      <span style="text-decoration: underline">Style</span>
                      <br />
                      Name: <b>{{ order.style }}</b> <br />
                    </div>

                    <p class="text-sm mb-0">
                      Shape: <b>{{ order.shape }}</b> -
                      <b>{{ shapeType[order.shapeType] }}</b>
                    </p>
                    <p class="text-sm mb-0">
                      Ring Size:
                      <b>{{ order.ringSize }}</b>
                    </p>
                  </div>
                  <div v-if="order.engraving" class="engraving tab">
                    <p class="text-sm mb-0">
                      <span style="text-decoration: underline">Engraving</span>
                      <br />
                      Message: <b>{{ order.engraving.text }}</b>
                      <br />
                      Font: <b>{{ order.engraving.font }}</b>
                    </p>
                  </div>

                  <div class="metal tab">
                    <div class="text-sm mb-0">
                      <span style="text-decoration: underline">Metal</span>
                      <br />
                      Color: <b>{{ metals[order.metal] }}</b> <br />
                    </div>
                    <br />
                  </div>
                  <div
                    v-if="order.birthStones.length"
                    class="birthstone tab"
                  >
                    <div
                      v-for="stone in order.birthStones"
                      :key="stone.id"
                      class="text-sm mb-0"
                    >
                      Birthstones: <br />
                      <b> {{ stone.name }}</b>
                      <img
                        @click="enlargeImage(stone.image)"
                        width="70"
                        style="cursor: zoom-in"
                        :src="stone.image"
                        alt=""
                      />
                    </div>
                  </div>
                </div>

                <div class="images">
                  <div
                    v-for="(image, index) in order.productImages"
                    :key="index"
                    @click="enlargeImage(image)"
                  >
                    <img
                      v-if="image.includes('.png')"
                      class="image"
                      :src="image"
                      alt=""
                      srcset=""
                    />
                  </div>
                </div>
                <image-modal
                  :styleFormat="{ width: '1000px', marginLeft: '-10.75rem' }"
                  :url="selectedImage"
                />
              </div>

              <!-- <soft-button
                  color="secondary"
                  variant="gradient"
                  class="ms-auto mb-0"
                  >Invoice</soft-button
                > -->
            </div>
          </div>
          <div v-if="selectedTab == 'statuses'" class="card-body p-3 pt-0">
            <hr class="horizontal dark mt-0 mb-4" />
            <div
              v-if="!order.status.includes('Consumer')"
              class="d-flex align-items-center"
            >
              <div class="form">
                <div class="form-group">
                  <label for="">Order Status</label>
                  <select
                    id="choices-category-edit"
                    v-model="order.status"
                    class="form-control"
                    name="choices-category"
                    @change="$emit('handle-input', $event, 'status')"
                  >
                    <option disabled value="Order Created">
                      Order Created
                    </option>
                    <option value="Factory Received Order">
                      Factory Received Order
                    </option>
                    <option value="In Process">In Process</option>
                    <option value="Completed">Completed</option>
                    <option value="Shipped To DIS">Shipped To DIS</option>
                  </select>
                  <div
                    v-if="order.status.includes('In Process')"
                    class="checkbox-wrapper"
                  >
                    <SoftCheckbox
                      :value="order.factoryHasDiamonds"
                      @input="
                        $emit('handle-input', $event, 'factoryHasDiamonds')
                      "
                    >
                      Factory Has Diamond
                    </SoftCheckbox>
                  </div>
                </div>

                <div
                  v-if="
                    !order.status.includes('Shipped') &&
                    order.status.includes('In Process')
                  "
                  class="form-group"
                >
                  <label for=""> Order Completion Days</label>
                  <SoftInput
                    type="Number"
                    placeholder="Set amount of day to complete order"
                    @change="$emit('handle-input', $event, 'daysToManufacture')"
                    :value="order.daysToManufacture"
                    :disabled="order.status == 'Completed'"
                  />
                </div>

                <div
                  class="shipping-info"
                  v-if="order.status.includes('Shipped')"
                >
                  <div class="form-group">
                    <label for=""
                      >Shipping Carrier <span class="required">*</span></label
                    >
                    <SoftInput
                      @change="
                        $emit(
                          'handle-input',
                          $event,
                          'shippingDetails',
                          'carrier'
                        )
                      "
                      :value="order.shippingDetails.carrier"
                      type="text"
                      placeholder="eg: UPS, Fedex"
                    />
                  </div>
                  <div class="form-group">
                    <label for=""
                      >Set Tracking Number
                      <span class="required">*</span></label
                    >
                    <SoftInput
                      @change="
                        $emit(
                          'handle-input',
                          $event,
                          'shippingDetails',
                          'trackingNumber'
                        )
                      "
                      :value="order.shippingDetails.trackingNumber"
                      type="text"
                      placeholder="Set tracking #"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="center" v-else>
              {{ order.status }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import SoftAvatar from "@/components/SoftAvatar.vue";
import SoftBadge from "@/components/SoftBadge.vue";
import SoftInput from "@/components/SoftInput.vue";
import SoftCheckbox from "@/components/SoftCheckbox.vue";
import ImageModal from "@/components/ImageModal.vue";

export default {
  name: "ShippingDetails",
  components: {
    SoftButton,
    SoftAvatar,
    SoftBadge,
    SoftInput,
    SoftCheckbox,
    ImageModal,
  },
  props: {
    order: {
      type: Object,
      default() {
        return Object;
      },
    },
  },
  data() {
    return {
      selectedTab: "details",
      metals: {
        wg: "White Gold",
        yg: "Yellow Gold",
        rg: "Rose Gold",
        plt: "Platinum",
      },
      shapeType: {
        earth: "Natural Stone",
        lab: "Lab Made",
      },
      selectedImage: null,
    };
  },
  methods: {
    enlargeImage(img) {
      this.selectedImage = img;
      const modalElement = document.getElementById("image-modal");
      const bootstrapModal = new bootstrap.Modal(modalElement);
      bootstrapModal.show();
    },
  },
};
</script>
<style scoped>
.shipping-info {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.info-container {
  width: 100%;
}
.tabs {
  display: flex;
  width: 88%;
  flex-direction: row;
  flex-wrap: unset;
  justify-content: space-around;
}
.form {
  display: flex;
}
.form-group {
  width: 250px;
  margin-right: 50px;
}
.metal-color-box {
  width: 30px;
  /* background-color: red; */
  height: 10px;
}

.nav-item {
  cursor: pointer;
}

.nav-item:last-child {
  cursor: pointer;
  border-right: none;
}

.active {
  background-color: rgba(23, 129, 69, 0.955) !important;
  color: white !important;
}

.col-lg-3 {
  width: 100% !important;
}

.image {
  width: 150px;
  cursor: zoom-in;
}

.images {
  display: flex;
}
</style>
