import {
  db,
  getDoc,
  doc,
  getDocs,
  collection,
  query,
  where,
  or,
  orderBy,
} from "@/config/firebaseInit.js";

const getDefaultState = () => {
  return {
    admin: {},
    store: {},
    client: {},
    isStore: false,
    isClient: false,
    isEmployee: false,
    isSuperAdmin: false,
    isSalesRep: false,
    isFactory: false,
    showMessenger: false,
    adminUsers: [],
  };
};
const authModule = {
  namespaced: true,
  state: {
    admin: {},
    store: {},
    client: {},
    isStore: false,
    isClient: false,
    isEmployee: false,
    isSuperAdmin: false,
    isSalesRep: false,
    isFactory: false,
    showMessenger: false,
    adminUsers: [],
  },
  mutations: {
    saveAdmin(state, data) {
      state.admin = data;
    },
    saveStore(state, data) {
      state.store = data;
    },
    saveClient(state, data) {
      state.client = data;
    },
    saveAdminUsers(state, data) {
      state.adminUsers = data;
    },
    initMessenger(state, data) {
      state.showMessenger = data;
    },
    resetState(state) {
      state = Object.assign(state, getDefaultState());
      window.localStorage.removeItem("admin_data");
      window.localStorage.removeItem("store_data");
      window.localStorage.removeItem("admin_users");
      window.localStorage.removeItem("client_data");
      window.localStorage.removeItem("show_messenger");
    },
    saveAccess(state, data) {
      if (data.store) {
        state.isStore = true;
      } else if (data.client) {
        state.isClient = true;
      } else if (data.factory) {
        state.isFactory = true;
      } else if (data.super) {
        state.isSuperAdmin = true;
      } else if (data.employee) {
        state.isEmployee = true;
      } else if (data.salesRep) {
        state.isSalesRep = true;
      }
    },
  },
  actions: {
    signOut(context) {
      context.commit("resetState");
    },
    async getAdminData(context, id) {
      let adminData = window.localStorage.getItem("admin_data");
      if (adminData) {
        adminData = JSON.parse(adminData);
        context.commit("saveAdmin", adminData);
      } else if (id) {
        adminData = await getDoc(doc(db, "users", id));
        adminData = Object.assign({ id: adminData.id }, adminData.data());
        context.commit("saveAdmin", adminData);
        window.localStorage.setItem("admin_data", JSON.stringify(adminData));
      } else {
        return;
      }
      if (adminData.admin_access.store) {
        context.dispatch("getStoreData", adminData.storeId);
      } else if (adminData.admin_access.client) {
        context.dispatch("getClientData", adminData.id);
      }
      context.commit("saveAccess", adminData.admin_access);
    },
    async getStoreData(context, id) {
      const cachedData = window.localStorage.getItem("store_data");
      if (cachedData) {
        context.commit("saveStore", JSON.parse(cachedData));
      } else if (id) {
        const storeRef = doc(db, "stores", id);
        let storeData = await getDoc(storeRef);
        storeData = Object.assign({ id: id }, storeData.data());
        context.commit("saveStore", storeData);
        window.localStorage.setItem("store_data", JSON.stringify(storeData));
      }
    },
    async getClientData(context, id) {
      const cachedData = window.localStorage.getItem("client_data");
      if (cachedData) {
        context.commit("saveClient", JSON.parse(cachedData));
      } else if (id) {
        let data = await getDoc(doc(db, "clients", id));
        data = Object.assign({ id: data.id }, data.data());
        context.commit("saveClient", data);
        window.localStorage.setItem("client_data", JSON.stringify(data));
      }
    },
    async getAdminUsers(context, id) {
      const cachedData = window.localStorage.getItem("admin_users");
      if (cachedData) {
        context.commit("saveAdminUsers", JSON.parse(cachedData));
      } else {
        if (id) {
          const usersRef = collection(db, "users");
          const usersQuery = query(
            usersRef,
            or(
              where("admin_access.super", "==", true),
              where("admin_access.employee", "==", true),
              where("admin_access.factory", "==", true)
            ),
            orderBy("firstName", "asc")
          );

          let users = await getDocs(usersQuery);
          users = users.docs
            .filter((u) => u.id != id)
            .map((u) => Object.assign(u.data(), { id: u.id }));
          context.commit("saveAdminUsers", users);
          window.localStorage.setItem("admin_users", JSON.stringify(users));
        }
      }
    },
    showMessengerAPP(context) {
      const cachedData = window.localStorage.getItem("show_messenger");
      if (cachedData) {
        context.commit("initMessenger", cachedData);
      } else {
        window.localStorage.setItem("show_messenger", true);
        context.commit("initMessenger", true);
      }
    },
  },
  modules: {},
};

export default authModule;
