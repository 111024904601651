<template>
  <navbar btn-background="bg-gradient-success" />
  <div
    class="pt-5 m-3 page-header align-items-start min-vh-50 pb-11 border-radius-lg"
    :style="{
      backgroundImage:
        'url(' + require('@/assets/img/login-background.jpg') + ')',
    }"
  >
    <div class="container">
      <div class="row justify-content-center">
        <img
          class="logo"
          src="@/assets/img/Luna-Collection.png"
          alt=""
          srcset=""
        />

        <div class="mx-auto text-center col-lg-5">
          <!-- <p class="text-white text-lead">Sales Management</p> -->
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
      <div class="mx-auto col-xl-4 col-lg-5 col-md-7">
        <div class="card z-index-0">
          <div class="pt-4 text-center card-header">
            <h5>Sign in</h5>
          </div>

          <div class="card-body">
            <form role="form" class="text-start" @submit.prevent>
              <div class="mb-3">
                <div class="form-group">
                  <div class="input-group">
                    <input
                      id="email"
                      type="text"
                      class="form-control multisteps-form__input"
                      name="text"
                      v-model="administrator.email"
                      placeholder="Email"
                      required
                    />
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <div class="form-group">
                  <div class="input-group">
                    <input
                      id="password"
                      :type="inputType"
                      class="form-control multisteps-form__input"
                      name="password"
                      v-model="administrator.password"
                      placeholder="******"
                      isRequired
                    />
                    <div
                      @click="handleInputType"
                      class="input-group-text click-event"
                    >
                      <span class="">
                        <i
                          v-if="inputType.includes('password')"
                          class="fas fa-eye"
                        ></i>
                        <i v-else class="fas fa-eye-slash"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="feedback">
                {{ feedback }}
              </div>
              <soft-switch id="rememberMe" name="rememberMe">
                Remember me
              </soft-switch>
              <div class="text-center">
                <soft-button
                  class="my-4 mb-2"
                  variant="gradient"
                  color="info"
                  full-width
                  :disabled="loading"
                  @click-btn="handleLogin"
                >
                  <span v-if="!loading">Sign in</span>
                  <i v-else class="fas fa-spinner fa-spin fa-lg"></i>
                </soft-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <footer class="py-5 footer">
      <div class="container">
        <div class="row">
          <div class="mx-auto mt-1 text-center col-8">
            <p class="mb-0 text-secondary">
              Copyright © {{ new Date().getFullYear() }} Luna Collection.
            </p>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import SoftInput from "@/components/SoftInput.vue";
import SoftSwitch from "@/components/SoftSwitch.vue";
import SoftButton from "@/components/SoftButton.vue";
import { auth, signInWithEmailAndPassword } from "@/config/firebaseInit.js";

import { mapMutations } from "vuex";
export default {
  name: "Login",
  components: {
    Navbar,
    AppFooter,
    SoftInput,
    SoftSwitch,
    SoftButton,
  },
  data() {
    return {
      administrator: {
        email: "",
        password: "",
      },
      inputType: "password",
      feedback: null,
      loading: false,
    };
  },
  watch: {
    feedback(v) {
      if (v) {
        setTimeout(() => {
          this.feedback = null;
        }, 4000);
      }
    },
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },

  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    handleLogin() {
      if (this.administrator.email && this.administrator.password) {
        this.feedback = null;
        this.loading = true;
        signInWithEmailAndPassword(
          auth,
          this.administrator.email,
          this.administrator.password
        )
          .then(async (data) => {
            let uid = data.user.uid;
            this.$store.dispatch("authModule/getAdminData", uid);
            this.$store.dispatch("authModule/showMessengerAPP");
            this.$store.dispatch("authModule/getAdminUsers", uid).then(() => {
              if (this.$store.state.authModule.isFactory) {
                this.$router.push("/factory/orders");
              } else {
                this.$router.push("/");
              }
              this.loading = false;
            });
          })
          .catch((err) => {
            this.loading = false;
            console.error(err);
            this.feedback = err.message;
          });
      } else {
        this.feedback = "Please enter both email and password.";
      }
    },
    handleInputType() {
      if (this.inputType.includes("password")) {
        this.inputType = "text";
      } else {
        this.inputType = "password";
      }
    },
  },
};
</script>
<style scoped>
.logo {
  width: 20rem;
  height: 50%;
  top: 0;
  position: absolute;
  filter: invert(1);
}

.feedback {
  color: red;
}
</style>
