<template>
  <a
    :data-bs-toggle="collapse ? 'collapse' : ''"
    :href="collapse ? `#${collapseRef}` : collapseRef"
    :aria-controls="collapseRef"
    aria-expanded="false"
    class="nav-link"
    v-bind="$attrs"
    type="button"
    @click="isExpanded = !isExpanded"
  >
    <div
      class="text-center bg-white shadow icon icon-shape icon-sm border-radius-md d-flex align-items-center justify-content-center"
      :class="'me-2'"
    >
      <slot name="icon"></slot>
    </div>
    <span class="nav-link-text" :class="'ms-1'">{{ navText }}</span>
  </a>
  <div :id="collapseRef" class="collapse">
    <slot name="list"></slot>
  </div>
</template>
<script>
export default {
  name: "SidenavCollapse",
  props: {
    collapseRef: {
      type: String,
      required: true,
    },
    navText: {
      type: String,
      required: true,
    },
    collapse: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isExpanded: false,
    };
  },
  watch: {
    isExpanded(val) {
      let myCollapse = document.getElementById(this.collapseRef);
      if (!val) {
        setTimeout(() => {
          myCollapse.classList.remove("show");
          let bsCollapse = new bootstrap.Collapse(myCollapse, {
            toggle: false,
          });
        }, 500);
      } else {
        let bsCollapse = new bootstrap.Collapse(myCollapse, {
          toggle: true,
        });
      }
    },
  },
};
</script>
