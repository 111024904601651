import { createRouter, createWebHistory } from "vue-router";
import Default from "../views/dashboards/Default.vue";
import { auth } from "../config/firebaseInit";

import CRM from "../views/dashboards/CRM.vue";
import Overview from "../views/pages/profile/Overview.vue";
import Teams from "../views/pages/profile/Teams.vue";
import Projects from "../views/pages/profile/Projects.vue";
import General from "../views/pages/projects/General.vue";
import Timeline from "../views/pages/projects/Timeline.vue";
import NewProject from "../views/pages/projects/NewProject.vue";
import Pricing from "../views/pages/Pricing.vue";

import Charts from "../views/pages/Charts.vue";
import SweetAlerts from "../views/pages/SweetAlerts.vue";
import Notifications from "../views/pages/Notifications.vue";
import Kanban from "../views/applications/Kanban.vue";
import Wizard from "../views/applications/wizard/Wizard.vue";
import DataTables from "../views/applications/DataTables.vue";
import Calendar from "../views/applications/Calendar.vue";
import Analytics from "../views/applications/analytics/Analytics.vue";
import EcommerceOverview from "../views/ecommerce/overview/Overview.vue";
import NewProduct from "../views/ecommerce/products/NewProduct.vue";
import EditProduct from "../views/ecommerce/products/EditProduct.vue";
import ProductPage from "../views/ecommerce/products/ProductPage.vue";
import ProductsList from "../views/ecommerce/products/ProductsList.vue";
import OrderDetails from "../views/ecommerce/Orders/OrderDetails";
import ClientList from "../views/clients/ClientList";
import NewClient from "../views/clients/NewClient";
import Report from "../views/report/Report.vue"

//Collections
import Collections from "@/views/collections/Collections.vue";
import NewCollection from "@/views/collections/NewCollection.vue";
import CollectionDetails from "@/views/collections/CollectionDetails.vue";
//Stores
import StoreList from "../views/stores/StoreList";
import NewStore from "../views/stores/NewStore";

//Sales 
import SalesRepList from "../views/sales_rep/SalesRepList";
import NewSalesRep from "../views/sales_rep/NewSalesRep";
import ContractPortal from "../views/clients/ContractPortal.vue";
import OrderList from "../views/ecommerce/Orders/OrderList";

//Factory
import FactoryOrderList from "../views/factory/OrderList";
import ShippingList from "../views/factory/ShippingList";

import Referral from "../views/ecommerce/Referral";
import UserList from "../views/pages/Users/UserList.vue";
import NewUser from "../views/pages/Users/NewUser.vue";
import Settings from "../views/pages/Account/Settings.vue";
import Billing from "../views/pages/Account/Billing.vue";
import Invoice from "../views/pages/Account/Invoice.vue";
import Security from "../views/pages/Account/Security.vue";
import Widgets from "../views/pages/Widgets.vue";
import Login from "../views/auth/signin/Login.vue";
// import Cover from "../views/auth/signin/Cover.vue";
import Illustration from "../views/auth/signin/Illustration.vue";
import ResetBasic from "../views/auth/reset/Basic.vue";
import ResetCover from "../views/auth/reset/Cover.vue";
import ResetIllustration from "../views/auth/reset/Illustration.vue";
import VerificationBasic from "../views/auth/verification/Basic.vue";
import VerificationCover from "../views/auth/verification/Cover.vue";
import VerificationIllustration from "../views/auth/verification/Illustration.vue";
import SignupBasic from "../views/auth/signup/Basic.vue";
import SignupCover from "../views/auth/signup/Cover.vue";
import SignupIllustration from "../views/auth/signup/Illustration.vue";
import Error404 from "../views/auth/error/Error404.vue";
import Error500 from "../views/auth/error/Error500.vue";


const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/Orders",
    meta: {
      requiresAuth: true,
    },
  },

  // {
  //   path: "/luna-agreement/:repID/:clientID",
  //   name: "Luna Agreement",
  //   component: ContractPortal,
  // },

  // {
  //   path: "/dashboards/crm",
  //   name: "CRM",
  //   component: CRM,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },

  // {
  //   path: "/pages/profile/teams",
  //   name: "Teams",
  //   component: Teams,
  // },

  // {
  //   path: "/pages/projects/general",
  //   name: "General",
  //   component: General,
  // },
  // {
  //   path: "/pages/projects/timeline",
  //   name: "Timeline",
  //   component: Timeline,
  // },

  // {
  //   path: "/pages/charts",
  //   name: "Charts",
  //   component: Charts,
  // },
  // {
  //   path: "/pages/widgets",
  //   name: "Widgets",
  //   component: Widgets,
  // },

  {
    path: "/pages/notifications",
    name: "Notifications",
    component: Notifications,
  },

  // {
  //   path: "/applications/calendar",
  //   name: "Calendar",
  //   component: Calendar,
  // },
  {
    path: "/applications/analytics",
    name: "Analytics",
    component: Analytics,
  },
  {
    path: "/collections",
    name: "Collections",
    component: Collections,
  },
  // {
  //   path: "/collections/new-collection",
  //   name: "New Collection",
  //   component: NewCollection,
  // },
  {
    path: "/collections/:id",
    name: "CollectionDetails",
    component: NewCollection,
  },

  {
    path: "/overview",
    name: "Overview",
    component: EcommerceOverview,
  },
  // {
  //   path: "/inventory/new-product",
  //   name: "New Product",
  //   component: NewProduct,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/inventory/edit/:id",
  //   name: "Edit Product",
  //   component: EditProduct,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/inventory/product-page",
  //   name: "Product Page",
  //   component: ProductPage,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/inventory",
  //   name: "Products List",
  //   component: ProductsList,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/Orders/order-details",
  //   name: "Order Details",
  //   component: OrderDetails,
  // },
  {
    path: "/stores",
    name: "Store List",
    component: StoreList,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/stores/new-store",
    name: "Add Store",
    component: NewStore,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sales-reps",
    name: "Sales Rep",
    component: SalesRepList,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sales-reps/new",
    name: "New Sales Rep",
    component: NewSalesRep,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/Orders",
    name: "Order List",
    component: OrderList,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/clients",
    name: "Clients",
    component: ClientList,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/clients/new-client",
    name: "Add Clients",
    component: NewClient,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/factory/orders",
    name: "Factory Order List",
    component: FactoryOrderList,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/factory/shipping",
    name: "Shipping",
    component: ShippingList,
    meta: {
      requiresAuth: true,
    },
  },


  {
    path: "/report",
    name: "Report",
    component: Report,
  },

  {
    path: "/users",
    name: "Users",
    component: UserList,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/users/new-user",
    name: "New User",
    component: NewUser,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/account",
    name: "Settings",
    component: Settings,
    meta: {
      requiresAuth: true,
    },
  },
  // {
  //   path: "/pages/account/billing",
  //   name: "Billing",
  //   component: Billing,
  // },
  // {
  //   path: "/pages/account/invoice",
  //   name: "Invoice",
  //   component: Invoice,
  // },
  // {
  //   path: "/luna-agreement/:repId/:clientID",
  //   name: "Security",
  //   component: Security,
  // },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/authentication/error/error500",
    name: "Error500",
    component: Error500,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "Error404",
    component: Error404,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach(async (to, from, next) => {
  let isAuthenticated = auth.currentUser;
  let requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (to.name !== "Login" && requiresAuth && !isAuthenticated)
    return next({ name: "Login" });
  else if (to.name == "Login" && isAuthenticated) return next({ name: "/" });
  else return next();
});

export default router;
