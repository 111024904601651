<template>
  <div
    class="card multisteps-form__panel p-3 border-radius-xl bg-white"
    data-animation="FadeIn"
  >
    <h5 class="font-weight-bolder mb-0">Profile</h5>
    <div class="multisteps-form__content mt-3">
      <div class="row">
        <div class="col-12">
          <div class="uploader">
            <Single
              @upload="$emit('uploaded-media', $event)"
              @remove="$emit('remove-media', $event)"
              label="Client's Profile Picture"
              :media="metadata"
              :path="`client_logo/${clientName}`"
              media_type="images/*"
              :useCustomPath="true"
            />
          </div>
        </div>
      </div>
      <div class="button-row d-flex mt-4">
        <soft-button
          type="button"
          color="light"
          variant="gradient"
          class="js-btn-prev"
          @click="$emit('next')"
          >Continue</soft-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import Single from "@/components/Upload/Single.vue";
import SoftButton from "@/components/SoftButton.vue";

export default {
  name: "Profile",
  components: {
    Single,
    SoftButton,
  },
  props: {
    clientName: {
      type: String,
      default: "",
    },
    metadata: {
      type: Object,
      default: () => Object,
    },
  },
  emits: ["uploaded-media", "remove-media", "next"],
};
</script>
