<template>
  <div v-if="rep.id" class="container-fluid">
    <div class="row">
      <div class="nav-wrapper position-relative end-0">
        <ul class="nav nav-pills nav-fill p-1" role="tablist">
          <li class="nav-item">
            <p
              @click="selectedTab = 'details'"
              class="nav-link mb-0 px-0 py-1"
              :class="{ active: selectedTab == 'details' }"
            >
              Details
            </p>
          </li>
          <li class="nav-item">
            <p
              @click="selectedTab = 'clients'"
              class="nav-link mb-0 px-0 py-1"
              :class="{ active: selectedTab == 'clients' }"
            >
              Clients
            </p>
          </li>

          <li class="nav-item">
            <p
              @click="selectedTab = 'orders'"
              class="nav-link mb-0 px-0 py-1"
              :class="{ active: selectedTab == 'orders' }"
            >
              Orders
            </p>
          </li>
          <li class="nav-item">
            <p
              @click="selectedTab = 'store'"
              class="nav-link mb-0 px-0 py-1"
              :class="{ active: selectedTab == 'store' }"
            >
              Stores
            </p>
          </li>
        </ul>
      </div>
      <div class="col-lg-12 tab-content">
        <div v-if="selectedTab == 'details'" class="card mb-4">
          <display-info
            @save-changes="$emit('save-changes')"
            @update-info="$emit('update-info', $event, 'info')"
            :rep="rep"
          ></display-info>
        </div>
        <div v-if="selectedTab == 'address'" class="card mb-4">
          <display-address
            @save-changes="$emit('save-changes')"
            @update-info="$emit('update-info', $event, 'address')"
            :rep="rep"
          ></display-address>
        </div>

        <div v-if="selectedTab == 'orders'" class="card">
          <hr class="horizontal dark mt-4 mb-4" />
          <order-table :rep="rep" />
        </div>

        <div v-if="selectedTab == 'clients'" class="card">
          <client-table :rep="rep" />
        </div>

        <div v-if="selectedTab == 'store'" class="card">
          <store-table :rep="rep" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import SoftAvatar from "@/components/SoftAvatar.vue";
import SoftBadge from "@/components/SoftBadge.vue";
import StoreTable from "./components/StoreTable.vue";
import ClientTable from "./components/ClientTable.vue";
import OrderTable from "./components/OrderTable.vue";
import DisplayInfo from "./components/DisplayInfo.vue";
import DisplayAddress from "./components/DisplayAddress.vue";

export default {
  name: "repDetails",
  components: {
    SoftButton,
    SoftAvatar,
    SoftBadge,
    StoreTable,
    ClientTable,
    OrderTable,
    DisplayInfo,
    DisplayAddress,
  },
  props: {
    rep: {
      type: Object,
      default() {
        return Object;
      },
    },
  },
  emits: ["save-changes", "update-info"],
  data() {
    return {
      selectedTab: "details",
      stores: [],
      orders: [],
      collections: [],
    };
  },
};
</script>
<style scoped>
.nav-item {
  cursor: pointer;
}

.nav-item:last-child {
  cursor: pointer;
  border-right: none;
}

.active {
  background-color: rgba(23, 129, 69, 0.955) !important;
  color: white !important;
}
</style>
