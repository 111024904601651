<template>
  <div
    class="modal fade img-modal"
    id="image-modal"
    tabindex="1"
    aria-labelledby="ModalLabel"
    aria-hidden="true"
    ref="ImageModal"
  >
    <div class="modal-dialog">
      <div class="modal-content" :style="styleFormat">
        <div class="modal-header">
          <h5 class="modal-title" id="ModalLabel">
            <h5 name="title" style="color: red">Click Off Screen To Close</h5>
          </h5>
          <!-- <button
            type="button"
            class="btn-close"
            aria-label="Close"
            @click="closeModal"
          >
            X
          </button> -->
        </div>
        <div class="modal-body">
          <img
            v-if="type.includes('image')"
            class="enlarged-image"
            :src="url"
            alt=""
            srcset=""
          />
          <video
            v-if="type.includes('video')"
            class="enlarged-image"
            :src="url"
            alt=""
            srcset=""
            controls
            autoplay
          ></video>
        </div>
        <div class="modal-footer">
          <!-- <button type="button" class="btn btn-secondary" @click="closeModal">
            Close
          </button> -->
          <slot class="modal-footer" name="footer"> </slot>
          <!-- <button
            v-if="showSaveBtn"
            @click="$emit('click')"
            type="button"
            class="btn btn-primary"
          >
            Save changes
          </button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "image-modal",
  props: {
    url: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
    styleFormat: {
      type: Object,
      default: () => Object.assign({}),
    },
  },
 
  methods: {
    closeModal() {
      //document.querySelector("#image-modal").classList.remove("show");
      //   document.querySelector("body").classList.remove("modal-open");
      // const modalElement = document.getElementById("image-modal");
      // modalElement.classList.remove("show");
      // const mdbackdrop = document.querySelector(".modal-backdrop");
      // if (mdbackdrop) {
      //   mdbackdrop.classList.remove( "show");
      // }
      // const bootstrapModal = new bootstrap.Modal(modalElement);
      // bootstrapModal.remove();
    },
  },
};
</script>

<style lang="scss" scoped>
.back .btn-close {
  display: block;
  color: red;
}
.enlarged-image {
  width: 100%;
  position: relative;
}
/*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   * by Vue.js.
   *  
   * You can easily play with the modal transition by editing
   * these styles.
   */
// .close {
//   position: absolute;
//   right: 10px;
//   color: red;
//   cursor: pointer;
//   font-size: 20px;
// }
// .modal-enter {
//   opacity: 0;
// }

// .modal-leave-active {
//   opacity: 0;
// }

// .modal-enter .modal-container,
// .modal-leave-active .modal-container {
//   -webkit-transform: scale(1.1);
//   transform: scale(1.1);
// }
</style>
