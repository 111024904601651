<template>
  <footer class="py-3 footer">
    <div class="container-fluid">
      <div class="row align-items-center justify-content-lg-between">
        <div class="mb-4 col-lg-6 mb-lg-0">
          <div
            class="text-sm text-center copyright text-muted text-lg-start"
          >
            <a
              href="https://www.diamondservicesusa.com"
              class="font-weight-bold"
              target="_blank"
              >Luna Collection  ©</a
            >
            All rights reserved {{ new Date().getFullYear() }}
          </div>
        </div>
       
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>
