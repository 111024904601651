import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import Utilities from "./mixin/index.js";

import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import "./assets/css/main.css";

import VueTilt from "vue-tilt.js";
import VueSweetalert2 from "vue-sweetalert2";
import SoftUIDashboard from "./soft-ui-dashboard";
import { auth, onAuthStateChanged } from "./config/firebaseInit";
import vClickOutside from "click-outside-vue3";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "./registerServiceWorker";

//QR-Code
import QrcodeVue from "qrcode.vue";

//Barcode
import VueBarcode from "@chenfengyuan/vue-barcode";

//VueSignature
import VueSignaturePad from "vue-signature-pad";

let appInstance;
onAuthStateChanged(auth, () => {
  if (!appInstance) {
    appInstance = createApp(App);
    appInstance.mixin(Utilities.mixin());
    appInstance.use(store);
    appInstance.use(VueSignaturePad);
    appInstance.use(router);
    appInstance.use(VueTilt);
    appInstance.use(VueSweetalert2);
    appInstance.use(SoftUIDashboard);
    appInstance.use(vClickOutside);
    appInstance.component("v-select", vSelect);
    appInstance.component(VueBarcode.name, VueBarcode);
    appInstance.component("qr-code-wrapper", QrcodeVue);
    appInstance.mount("#app");
  }
});
