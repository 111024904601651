<template>
  <div class="container-fluid py-4">
    <div class="d-sm-flex justify-content-between">
      <!-- <div>
        <soft-button color="success" variant="gradient">New client</soft-button>
      </div> -->
      <div class="d-flex">
        <soft-button
          class="btn-icon ms-2 export"
          size
          color="success"
          variant="outline"
          @click="$router.push('/sales-reps/new')"
        >
          <span class="btn-inner--icon">
            <i class="ni ni-single-02"></i>
          </span>
          <span class="btn-inner--text"> New Sales Rep</span>
        </soft-button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card mt-4 p-3 pt-0">
          <div class="table-responsive">
            <div class="search-bar">
              <soft-input
                @input="searchUser"
                placeholder="Search By Name or Email"
              >
              </soft-input>
            </div>
            <table class="table align-items-center mb-0">
              <thead>
                <tr>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    Company
                  </th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    Name
                  </th>

                  <th
                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    Rep ID
                  </th>
                  <th
                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    Email
                  </th>

                  <th
                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    Phone #
                  </th>
                  <th
                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    Created On
                  </th>
                  <!-- <th
                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    Id
                  </th> -->
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="user in tableData"
                  :key="user.id"
                  class="table-row"
                  data-bs-toggle="modal"
                  data-bs-target="#Modal"
                  @click="viewSalesRepDetails(user)"
                >
                  <td>
                    <div class="d-flex px-2 py-1">
                      <div class="d-flex flex-column justify-content-center">
                        <h6 class="mb-0 text-sm">
                          {{ user.company}}
                        </h6>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex px-2 py-1">
                      <!-- <soft-avatar
                  :img="img1"
                  class="me-3"
                  alt="avatar image"
                  border-radius="lg"
                /> -->
                      <div class="d-flex flex-column justify-content-center">
                        <h6 class="mb-0 text-sm">
                          {{ `${user.firstName} ${user.lastName}` }}
                        </h6>
                      </div>
                    </div>
                  </td>
                  <td class="align-middle text-center text-sm">
                    <p class="text-secondary mb-0 text-sm">{{ user.repId }}</p>
                  </td>
                  <td class="align-middle text-center text-sm">
                    <p class="text-secondary mb-0 text-sm">{{ user.email }}</p>
                  </td>
                  <td class="align-middle text-center">
                    <p class="text-secondary mb-0 text-sm">{{ user.phone }}</p>
                  </td>
                  <td class="align-middle text-center">
                    <span class="text-secondary text-sm">{{
                      format_date_time(user.createdAt)
                    }}</span>
                  </td>
                  <!-- <td class="align-middle text-center">
                    <span class="text-secondary text-sm">{{ user.id }}</span>
                  </td> -->
                </tr>
              </tbody>
            </table>

            <div class="pagination-wrapper">
              <div class="page-dropdown">
                <label for="orders">Per page: &nbsp; </label>
                <select v-model="pagination.perPage" name="orders">
                  <option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </option>
                </select>
              </div>

              <pagination
                :value="pagination.currentPage"
                class="pagination-no-border pagination-success"
                :per-page="pagination.perPage"
                :total="total"
                :from="from"
                :to="to"
                @input="handlePagination"
              >
              </pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal
      :styleFormat="{ width: '1000px', marginLeft: '-10.75rem' }"
      @close="selectedRep = {}"
    >
      <template v-slot:title>
        <h2>{{ selectedRep.firstName }} Details</h2>
      </template>
      <template v-slot:body>
        <div class="order-details-wrapper">
          <SalesRepDetails
            @handle-input="handleInput"
            :rep="selectedRep"
            @save-changes="saveChanges"
          />
        </div>
      </template>
      <template v-slot:footer>
        <!-- <soft-button
          color="secondary"
          variant="outline"
          class="mb-0 ms-auto"
          type="button"
          name="button"
          @click-btn="deactivateUser"
          >Deactivate</soft-button
        > -->
        <soft-button
          color="danger"
          variant="gradient"
          class="mb-3 ms-2"
          type="button"
          name="button"
          @click-btn="deleteUser"
          >Delete Account</soft-button
        >
      </template>
    </modal>
  </div>
</template>

<script>
import SoftAvatar from "@/components/SoftAvatar.vue";
import SoftButton from "@/components/SoftButton.vue";
import SoftCheckbox from "@/components/SoftCheckbox.vue";
import SoftInput from "@/components/SoftInput.vue";
import Modal from "@/components/Modal.vue";
import Pagination from "@/components/Pagination.vue";
import SalesRepDetails from "./SalesRepDetails.vue";

import {
  collection,
  db,
  getDocs,
  deleteDoc,
  doc,
  setDoc,
  query,
  where,
} from "@/config/firebaseInit.js";
import bootstrapMin from "bootstrap/dist/js/bootstrap.min";

export default {
  name: "Table",
  components: {
    SoftAvatar,
    SoftButton,
    SoftCheckbox,
    SoftInput,
    Modal,
    Pagination,
    SalesRepDetails,
  },
  data() {
    return {
      salesReps: [],
      tableData: [],
      selectedRep: {},
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [50, 250, 500, 1000],
        total: 0,
      },
      currentSort: "name",
      currentSortOrder: "asc",
    };
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data.
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData.slice(this.from, this.to);
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.tableData.length;
    },
  },

  created() {
    this.getAllSalesRep();
  },
  methods: {
    deactivateUser() {},
    deleteUser() {
      const selectedRep = this.selectedRep;
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Continue",
      }).then((result) => {
        if (result.value) {
          // this.showModal = false;

          deleteDoc(doc(db, "sales_rep", selectedRep.id)).then(() => {
            this.$swal(
              "Deleted!",
              "The data has been deleted.",
              "success"
            ).then(() => {
              const modalEl = document.getElementById("Modal");
              const modal = bootstrapMin.Modal.getInstance(modalEl);
              modal.hide();
              this.selectedRep = {};
            });
          });
        }
      });
    },
    async saveChanges() {
      const selectedRep = this.selectedRep;
      await setDoc(doc(db, "sales_rep", selectedRep.id), selectedRep).then(
        () => {
          this.$swal({
            icon: "success",
            title: "Successful Update",
            text: `Sales Person ${selectedRep.firstName} has been updated`,
            type: "success",
          });
        }
      );
    },
    handleInput() {},
    viewSalesRepDetails(user) {
      this.selectedRep = user;
      this.showModal = true;
    },
    searchUser(e) {
      let inputVal = e.target.value;
      if (!inputVal) {
        this.tableData = this.salesReps;
        return;
      }
      inputVal = inputVal.toLowerCase();
      this.tableData = this.salesReps.filter(
        (u) =>
          u.firstName.toLowerCase().includes(inputVal) ||
          u.email.toLowerCase().includes(inputVal)
      );
    },
    async getAllSalesRep() {
      let queryRef = collection(db, "sales_rep");
      if (this.$store.state.authModule.isClient) {
        queryRef = query(
          collection(db, "sales_rep"),
          where("client.id", "==", this.$store.state.authModule.client.id)
        );
      } else if (this.$store.state.authModule.isStore) {
        queryRef = query(
          collection(db, "sales_rep"),
          where(
            "client.id",
            "==",
            this.$store.state.authModule.admin.client.id
          ),
          where("storeId", "==", this.$store.state.authModule.store.id)
        );
      }

      const salesReps = await getDocs(queryRef);
      this.salesReps = salesReps.docs.map((user) =>
        Object.assign({ id: user.id }, user.data())
      );
      this.tableData = this.salesReps;
    },
  },
};
</script>
