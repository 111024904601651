<template>
  <div>
    <label>{{ label }}</label>
    <button v-if="files.length" @click="$refs.upload.click()">
      Upload More
    </button>
    <div class="upload_holder">
      <div class="upload_container">
        <transition name="fade">
          <div class="upload_click" @drop="handleDrop" @dragover.prevent>
            <div v-if="files.length" class="image_success">
              <draggable
                class="dragArea list-group w-full"
                v-model="files"
                @change="$emit('handle-draggable', $event)"
              >
                <div
                  class="image_wrapper bg-gray-300 m-1 p-4 rounded-md text-center"
                  v-for="(file, position) in files"
                  :key="position"
                >
                  <div>Order # {{ position + 1 }}</div>
                  <p
                    @click="$emit('remove-media', file)"
                    class="text_btn pointer"
                  >
                    X
                  </p>
                  <div class="image leaflet-grab">
                    <img
                      v-if="file.type.includes('image')"
                      class="image-media"
                      :src="file.url"
                      :alt="file.name"
                    />
                    <video
                      class="image-media file-media"
                      v-if="file.type.includes('video')"
                      :src="file.url"
                      :alt="file.name"
                      autoplay
                      loop
                    ></video>
                    <p>{{ file.name }}</p>
                    <v-select
                      placeholder="Choose a color"
                      v-model="file.metalColor"
                      :options="colorOptions"
                      append-to-body
                      :calculate-position="withPopper"
                      class="form-control"
                      label="name"
                      @option:selected="
                        $emit('handle-color-select', {
                          option: $event,
                          index: position,
                        })
                      "
                    ></v-select>
                  </div>
                </div>
              </draggable>
            </div>

            <div
              class="upload_icon"
              @click="$refs.upload.click()"
              v-if="!files.length && !load"
            >
              <Upload />
              <p>
                You currently have no media uploaded. Click here to upload
                media.
              </p>
            </div>

            <div v-if="load" class="loading">
              <i
                style="font-size: 80px"
                class="fas fa-spinner fa-spin fa-lg"
              ></i>
              <br />
              <p>loading...</p>
            </div>
          </div>
        </transition>
      </div>

      <input
        ref="upload"
        multiple
        type="file"
        :accept="'media/*'"
        class="hide_upload"
        @change="uploadImages"
      />
    </div>
  </div>
</template>

<script>
import {
  storage,
  ref,
  uploadBytes,
  getDownloadURL,
} from "@/config/firebaseInit";
import Upload from "@/components/Icon/Upload.vue";
import { VueDraggableNext } from "vue-draggable-next";

import { createPopper } from "@popperjs/core";

export default {
  components: {
    Upload,
    draggable: VueDraggableNext,
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    files: {
      type: Array,
      default: [],
    },
    colorOptions: {
      type: Array,
      default: [],
    },
    path: {
      type: String,
      default: "images/",
    },
    useCustomPath: {
      type: Boolean,
      default: false,
    },
  },

  emits: [
    "uploaded-media",
    "remove-media",
    "handle-color-select",
    "handle-draggable",
  ],
  data() {
    return {
      drag: false,
      load: false,
      mediaURLs: [],
      placement: "top",
    };
  },
  methods: {
    withPopper(dropdownList, component, { width }) {
      /**
       * We need to explicitly define the dropdown width since
       * it is usually inherited from the parent with CSS.
       */
      dropdownList.style.width = width;
      dropdownList.style.zIndex = 99999999;
      /**
       * Here we position the dropdownList relative to the $refs.toggle Element.
       *
       * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
       * the dropdownList overlap by 1 pixel.
       *
       * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
       * wrapper so that we can set some styles for when the dropdown is placed
       * above.
       */
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: this.placement,
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -1],
            },
          },
          {
            name: "toggleClass",
            enabled: true,
            phase: "write",
            fn({ state }) {
              console.log(state);
              component.$el.classList.toggle(
                "drop-up",
                state.placement === "top"
              );
            },
          },
        ],
      });
      /**
       * To prevent memory leaks Popper needs to be destroyed.
       * If you return function, it will be called just before dropdown is removed from DOM.
       */
      return () => popper.destroy();
    },
    // handleDefaultedImage(image) {
    //   this.$emit("uploaded-media", this.media);
    // },
    handleDrop(event) {
      event.preventDefault();
      this.uploadImages({ target: { files: event.dataTransfer.files } });
    },
    log(event) {
      this.files.forEach((media, index) => {
        if (!index) media.setAsDefault = true;
        else media.setAsDefault = false;
      });
      this.$emit("uploaded-media", this.media);
    },
    uploadImages(e) {
      let vm = this;
      this.upload = null;
      vm.load = null;
      setTimeout(function () {
        vm.load = true;
      }, 500);

      let files = Object.values(e.target.files);

      files.forEach((file, index) => {
        let reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = (e) => {
          const refStr = `${this.path}/${file.name + Date.now()}`;

          const fileRef = ref(storage, refStr);
          uploadBytes(fileRef, file).then(() => {
            getDownloadURL(ref(storage, fileRef)).then((downloadURL) => {
              let media = {
                ref: refStr,
                url: downloadURL,
                name: file.name,
                type: file.type.split("/")[0],
                setAsDefault: false,
              };
              this.mediaURLs.push(media);

              if (index === files.length - 1) {
                this.$emit("uploaded-media", this.mediaURLs);
                setTimeout(() => {
                  this.mediaURLs = [];
                  vm.load = false;
                }, 1000);
              }
            });
          });
        };
      });
    },
  },
};
</script>

<style lang="css" scoped>

.v-select.drop-up.vs--open .vs__dropdown-toggle {
  border-radius: 0 0 4px 4px;
  border-top-color: transparent;
  border-bottom-color: rgba(60, 60, 60, 0.26);
}

[data-popper-placement="top"] {
  border-radius: 4px 4px 0 0;
  border-top-style: solid;
  border-bottom-style: none;
  box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15);
  background-color: red;
}

.upload_icon {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dragArea {
  width: 100%;
  display: flow-root;
  max-height: 500px;
  position: relative;
  overflow-y: auto;
}
.upload_holder {
  width: 100%;
  padding: 30px;
  background-color: #f7f7f7;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image_wrapper {
  position: relative;
  width: 300px;
  height: 300px;
  display: inline-block;
}
.image-media {
  width: 150px;
}
/* .image-media:hover {
  cursor: zoom-in;
} */
.text_btn {
  color: white;
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  background: red;
  padding: 5px 10px;
}
.hide_upload {
  position: absolute;
  z-index: -2;
}
.loading {
  position: absolute;
}
.upload_click,
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.upload_click .md-icon {
  font-size: 53px !important;
  opacity: 0.5;
}

.upload_click p {
  line-height: 1.3;
  max-width: 300px;
  text-align: center;
  opacity: 0.5;
  /* margin-top: 30px; */
  margin-bottom: 0;
}

.rotate {
  font-size: 40px !important;
  -webkit-animation: loading 1s infinite linear;
}
.success {
  width: 25%;
}
.success p {
  text-align: center;
  margin-bottom: 0;
}

.success img {
  width: 100%;
  max-height: 100%;
}

@-webkit-keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
